import { FC } from "react";

import { BriefcaseIcon } from "../../../core/Icon";
import TextInput from "../../../core/TextInput";
import BaseDetailsField from "../shared/BaseDetailsField";
import TypeLabel from "../shared/TypeLabel";
import { ContactDataFieldProps } from "../types";

const JobsField: FC<ContactDataFieldProps> = ({ contactData, dispatch, isEditing }) => {
  // Only visible when editing
  if (!isEditing) {
    return null;
  }

  const companyName = contactData.companyName || "";
  const jobTitle = contactData.jobTitle || "";
  const departmentName = contactData.departmentName || "";

  return (
    <BaseDetailsField
      label="Jobs"
      isEditing={isEditing}
      icon={<BriefcaseIcon size="lg" className="icon-color-purple" />}
    >
      <dd key="company" className="flex flex-row w-full">
        <TypeLabel>Company</TypeLabel>
        <div className="flex flex-1 mr-20">
          <TextInput
            name="companyName"
            placeholder="Name"
            type="text"
            value={companyName}
            onChange={(event) => {
              dispatch({ type: "companyName", payload: event.target.value });
            }}
            className="flex-1 mr-2"
          />
          <TextInput
            name="departmentName"
            placeholder="Department"
            type="text"
            value={departmentName}
            onChange={(event) => {
              dispatch({ type: "departmentName", payload: event.target.value });
            }}
            className="flex-1"
          />
        </div>
      </dd>
      <dd key="jobTitle" className="flex flex-row w-full">
        <TypeLabel>Job Title</TypeLabel>
        <TextInput
          name="jobTitle"
          placeholder="Job title"
          type="text"
          value={jobTitle}
          onChange={(event) => {
            dispatch({ type: "jobTitle", payload: event.target.value });
          }}
          className="flex-1 mr-20"
        />
      </dd>
    </BaseDetailsField>
  );
};

export default JobsField;
