import classNames from "clsx";
import { FC, PropsWithChildren } from "react";

type BaseDetailsFieldProps = {
  label: string;
  icon: JSX.Element;
  isEditing?: boolean;
  className?: string;
};

const BaseDetailsField: FC<PropsWithChildren<BaseDetailsFieldProps>> = ({
  label,
  icon,
  isEditing,
  className,
  children,
}) => {
  return (
    <div
      className={classNames(
        "flex flex-row w-full",
        isEditing ? "pt-8 pb-2 space-x-0" : "py-4 space-x-4"
      )}
    >
      <dt className={classNames("w-6 h-6", isEditing && "pt-1.5")}>
        <span className="sr-only">{label}</span>
        {icon}
      </dt>
      <div className={classNames("flex-1 space-y-3 max-w-xl", className)}>{children}</div>
    </div>
  );
};

export default BaseDetailsField;
