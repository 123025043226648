import { Dialog } from "@headlessui/react";
import { CloudIcon } from "@heroicons/react/outline";
import Button from "@web/components/core/Button";
import { QuestionCircleSolidIcon } from "@web/components/core/Icon";
import Modal from "@web/components/core/Modal";
import { FormTextInput } from "@web/components/core/TextInput";
import IcloudAppSpecificPassHelp from "@web/components/tools/syncManagement/IcloudAppSpecificPassHelp";
import { useCreateIntegrationFromIcloudPasswordMutation } from "@web/integrations/user/api";
import { Form, Formik } from "formik";
import { Dispatch, FC, SetStateAction, useRef, useState } from "react";
import * as yup from "yup";

const SyncICloudModal: FC<{
  isModalOpen: boolean;
  setIsClosed: Dispatch<SetStateAction<boolean>>;
}> = ({ isModalOpen, setIsClosed }) => {
  const emailInputRef = useRef<HTMLInputElement | null>(null);

  const [createIntegrationFromIcloudPassword] = useCreateIntegrationFromIcloudPasswordMutation();

  const [showHelp, setShowHelp] = useState(false);
  return (
    <Modal
      initialFocusRef={emailInputRef}
      isModalOpen={isModalOpen}
      setIsClosed={setIsClosed}
      className="align-middle sm:max-w-lg sm:w-full p-6 sm:p-8"
    >
      <div className="flex flex-col items-center m-auto max-w-xs">
        <CloudIcon className="mb-2.5 h-10 w-10 text-indigo-400" aria-hidden="true" />
        <Dialog.Title
          as="h3"
          className="text-lg font-medium leading-6 text-zinc-900 dark:text-zinc-100 mb-3"
        >
          Sync with iCloud
        </Dialog.Title>
        <p className="mb-5">
          Use your iCloud email and app-specific password to sync your account now
        </p>
        <Formik
          initialValues={{ email: "", password: "" }}
          validationSchema={yup.object()}
          onSubmit={async (values, actions) => {
            const { setSubmitting } = actions;
            const { email, password } = values;
            await createIntegrationFromIcloudPassword({ email, appSpecificPassword: password });
            setSubmitting(false);
            setIsClosed(false);
          }}
        >
          {({ isSubmitting }) => (
            <Form className="text-left w-72 max-w-full">
              <FormTextInput
                className="mb-2.5"
                name="email"
                label="Email"
                forwardedRef={emailInputRef}
              />
              <FormTextInput
                labelPostfix={
                  <button>
                    <QuestionCircleSolidIcon
                      className="cursor-pointer z-50"
                      onClick={(e) => {
                        e.preventDefault();
                        setShowHelp(true);
                      }}
                    />
                  </button>
                }
                className="mb-5"
                name="password"
                label="App-specific password"
                placeholder="xxxx-xxxx-xxxx-xxxx"
              />
              <Button full disabled={isSubmitting} className="mx-auto" type="submit">
                <div className="mx-auto">Sync my iCloud</div>
              </Button>
            </Form>
          )}
        </Formik>
      </div>
      <IcloudAppSpecificPassHelp open={showHelp} setOpen={setShowHelp} />
    </Modal>
  );
};
export default SyncICloudModal;
