import { ContactRow } from "@shared/models/Contact";
import { getValueByPointer, Operation } from "fast-json-patch";
import { FC } from "react";

import { getIconTypeForContactField } from "../../../../helpers/contact";
import { OperationGroup } from "../../../../helpers/operation";
import {
  MinusCircleSolidIcon,
  PlusCircleSolidIcon,
  ReplaceCircleSolidIcon,
} from "../../../core/Icon";
import OperationValueField from "./OperationValueField";

type OperationGroupRowProps = {
  group: OperationGroup;
  isPastOperation?: boolean;
  curContact?: ContactRow;
  prevContact?: ContactRow;
};

const OperationGroupRow: FC<OperationGroupRowProps> = ({
  group: { fieldName, basePath, operations },
  isPastOperation = true,
  curContact,
  prevContact,
}) => {
  // Resolve contact field icon
  const ContactFieldIcon = getIconTypeForContactField(fieldName);

  // Resolve operation icon and value
  let OperationIcon: JSX.Element | undefined;
  let operation: Operation["op"];
  let operationValue: any;

  const operationSet = new Set(operations.map((o) => o.op));
  if (operationSet.has("replace") || operationSet.has("move") || operationSet.has("copy")) {
    OperationIcon = (
      <ReplaceCircleSolidIcon
        size="xs"
        className="absolute bg-white rounded-full text-zinc-500 -bottom-px -right-1"
      />
    );
    operation = "replace";
    operationValue = getValueByPointer(curContact, `/${basePath}`);
  } else if (operationSet.has("remove")) {
    OperationIcon = (
      <MinusCircleSolidIcon
        size="xs"
        className="absolute text-red-500 bg-white rounded-full -bottom-px -right-1"
      />
    );
    operation = "remove";
    operationValue = getValueByPointer(prevContact, `/${basePath}`);
  } else if (operationSet.has("add")) {
    OperationIcon = (
      <PlusCircleSolidIcon
        size="xs"
        className="absolute text-green-500 bg-white rounded-full -bottom-px -right-1"
      />
    );
    operation = "add";
    operationValue = getValueByPointer(curContact, `/${basePath}`);

    // If we've added a null field value, don't render
    if (operationValue === null) {
      return null;
    }
  } else {
    return null;
  }

  return (
    <li className="flex flex-row py-1">
      {ContactFieldIcon && (
        <span className="relative w-5 h-5 mr-2">
          <ContactFieldIcon className="text-purple-500" />
          {OperationIcon}
        </span>
      )}
      <div className="flex-1 flex-shrink-0 pt-1">
        <OperationValueField
          operation={operation}
          isPastOperation={isPastOperation}
          fieldName={fieldName}
          fieldValue={operationValue}
        />
      </div>
    </li>
  );
};

export default OperationGroupRow;
