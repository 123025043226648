import useHotKeyWithPrompt from "@web/hooks/useHotKeyWithPrompt";
import classNames from "clsx";
import { ErrorMessage, Field, FieldProps } from "formik";
import {
  ChangeEventHandler,
  ClipboardEventHandler,
  FC,
  FocusEventHandler,
  HTMLInputTypeAttribute,
  KeyboardEventHandler,
  MutableRefObject,
  useCallback,
} from "react";
import { useKeys } from "rooks";

type TextInputProps = {
  name: string;
  id?: string;
  label?: string;
  placeholder?: string;
  value?: string;
  defaultValue?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onPaste?: ClipboardEventHandler<HTMLInputElement>;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  prefix?: string | JSX.Element;
  labelPostfix?: string | JSX.Element;
  type?: HTMLInputTypeAttribute;
  autoComplete?: string;
  singleColumn?: boolean;
  showError?: boolean;
  disabled?: boolean;
  className?: string;
  forwardedRef?: MutableRefObject<HTMLInputElement | null>;
  hotkey?: string[];
  hotkeyHandler?: (e: KeyboardEvent) => void | boolean;
};

const TextInput: FC<TextInputProps> = ({
  name,
  id,
  label,
  placeholder,
  value,
  defaultValue,
  onChange,
  onPaste,
  onKeyDown,
  onFocus,
  onBlur,
  prefix,
  type = "text",
  autoComplete,
  singleColumn = true,
  disabled = false,
  className,
  forwardedRef,
  hotkey,
  hotkeyHandler,
}) => {
  const HotKeyPrompt = useHotKeyWithPrompt(hotkey, hotkeyHandler);

  return (
    <div
      className={classNames(
        "relative space-y-1",
        singleColumn ? "" : "sm:grid sm:grid-cols-3 sm:gap-4",
        className
      )}
    >
      {/* Optional label */}
      {label && (
        <label
          htmlFor={name}
          aria-label={label}
          className={classNames(
            "block text-sm font-medium",
            singleColumn
              ? "text-zinc-500 dark:text-zinc-400"
              : "pt-0 sm:pt-3 text-zinc-800 dark:text-zinc-200"
          )}
        >
          {label}
        </label>
      )}

      {/* Main input element */}
      <div className={singleColumn ? "relative" : "relative sm:col-span-2 pt-0 mt-0"}>
        {prefix && (
          <div className="absolute left-0 flex items-center h-full pl-3 pointer-events-none">
            <span className="text-zinc-400 text-md dark:text-zinc-600">{prefix}</span>
          </div>
        )}
        <input
          id={id || name}
          type={type}
          defaultValue={defaultValue}
          autoComplete={autoComplete}
          placeholder={placeholder}
          disabled={disabled}
          ref={forwardedRef}
          value={value}
          onChange={onChange}
          onPaste={onPaste}
          onKeyDown={onKeyDown}
          onFocus={onFocus}
          onBlur={onBlur}
          className={classNames(
            `appearance-none block w-full py-2 border-primary rounded-md shadow-sm text-input-primary focus:ring-pink-500 focus:border-pink-500`,
            prefix ? "pl-10" : "pl-3"
          )}
        />
        {HotKeyPrompt}
      </div>
    </div>
  );
};

export const FormTextInput: FC<TextInputProps> = ({
  name,
  id,
  label,
  placeholder,
  prefix,
  labelPostfix,
  type = "text",
  autoComplete,
  singleColumn = true,
  disabled = false,
  showError = true,
  className,
  forwardedRef,
}) => {
  return (
    <Field name={name}>
      {({ field, form: { touched, errors } }: FieldProps) => (
        <div
          className={classNames(
            "relative space-y-1",
            singleColumn ? "" : "sm:grid sm:grid-cols-3 sm:gap-4",
            className
          )}
        >
          {/* Optional label */}
          {label && (
            <div className="relative">
              {labelPostfix && (
                <div className="absolute right-0 top-0 flex items-center h-full">
                  <span className="text-zinc-400 text-md dark:text-zinc-600">{labelPostfix}</span>
                </div>
              )}
              <label
                htmlFor={name}
                aria-label={label}
                className={classNames(
                  "block text-sm font-medium",
                  singleColumn
                    ? "text-zinc-500 dark:text-zinc-400"
                    : "pt-0 sm:pt-3 text-zinc-800 dark:text-zinc-200"
                )}
              >
                {label}
              </label>
            </div>
          )}

          {/* Main input element */}
          <div className={singleColumn ? "relative" : "relative sm:col-span-2 pt-0 mt-0"}>
            {prefix && (
              <div className="absolute left-0 flex items-center h-full pl-3 pointer-events-none">
                <span className="text-zinc-400 text-md dark:text-zinc-600">{prefix}</span>
              </div>
            )}

            <input
              {...field}
              id={id || name}
              type={type}
              autoComplete={autoComplete}
              placeholder={placeholder}
              disabled={disabled}
              ref={forwardedRef}
              className={classNames(
                `appearance-none block w-full py-2 border-primary rounded-md shadow-sm text-input-primary focus:ring-pink-500 focus:border-pink-500`,
                prefix ? "pl-10" : "pl-3",
                singleColumn ? "text-md" : "text-sm",
                touched && errors[name] && "border-red-600 dark:border-red-400"
              )}
            />

            {/* Error */}
            {showError && (
              <ErrorMessage
                name={name}
                component="div"
                className="absolute right-0 text-xs italic text-right text-red-500"
              />
            )}
          </div>
        </div>
      )}
    </Field>
  );
};

export default TextInput;
