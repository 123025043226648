import { Dialog } from "@headlessui/react";
import type { ContactRow } from "@shared/models/Contact";
import type { ContactSyncConflict } from "@shared/models/ContactSyncConflict";
import { getHumanReadableVendorName } from "@web/helpers/integration";
import classNames from "clsx";
import { FC, useRef, useState } from "react";

import Button, { ButtonVariant } from "../../../core/Button";
import { CircleIcon, DotCircleSolidIcon, QuestionCircleSolidIcon } from "../../../core/Icon";
import Modal from "../../../core/Modal";
import ContactPreview from "../../preview";

type ResolveSyncConflictAlertProps = {
  contact: ContactRow | null;
  conflict: ContactSyncConflict | null;
  isSyncConflictAlertOpen: boolean;
  setIsSyncConflictAlertOpen: (open: boolean) => void;
  onResolveSyncConflict: (resolvedContact: ContactRow) => void;
  onClickCancel: () => void;
};

const ResolveSyncConflictAlert: FC<ResolveSyncConflictAlertProps> = ({
  contact,
  conflict,
  isSyncConflictAlertOpen,
  setIsSyncConflictAlertOpen,
  onResolveSyncConflict,
  onClickCancel,
}) => {
  const source = getHumanReadableVendorName(conflict?.vendorTable);
  const cancelButtonRef = useRef<HTMLButtonElement | null>(null);
  const [shouldMergeAttemptedEntity, setShouldMergeAttemptedEntity] = useState<boolean | null>(
    null
  );

  return (
    <Modal
      initialFocusRef={cancelButtonRef}
      isModalOpen={isSyncConflictAlertOpen}
      setIsClosed={(open) => {
        if (!open) {
          setShouldMergeAttemptedEntity(null);
        }
        setIsSyncConflictAlertOpen(open);
      }}
      className="p-4 overflow-hidden text-left align-middle sm:max-w-4xl sm:w-full sm:p-6"
    >
      <div className="sm:flex sm:items-start">
        {/* Icon */}
        <div className="mt-2 icon-container" aria-hidden="true">
          <QuestionCircleSolidIcon className="text-zinc-400 dark:text-zinc-600" size="lg" />
        </div>

        <div className="mt-4 text-center sm:mt-2 sm:ml-4 sm:text-left">
          {/* Title */}
          <Dialog.Title
            as="h3"
            className="text-lg font-medium leading-6 text-zinc-900 dark:text-zinc-100"
          >
            Merge unsynced changes from {source}?
          </Dialog.Title>

          {/* Description */}
          <div className="mt-1.5">
            <p className="text-sm text-zinc-500">
              If you choose to keep the current version, contact data on {source} will be
              overwritten.
            </p>
          </div>

          {/* Content */}
          {conflict?.remoteEntity && (
            <div className="flex flex-col mt-4 sm:flex-row sm:space-x-1">
              {/* Keep TitleDock version */}
              <button
                type="button"
                className={classNames(
                  "flex flex-col items-start justify-start flex-1 p-3",
                  shouldMergeAttemptedEntity === false &&
                    "bg-purple-100 dark:bg-purple-900 rounded-md"
                )}
                onClick={() => setShouldMergeAttemptedEntity(false)}
              >
                <div className="flex flex-row items-center">
                  {shouldMergeAttemptedEntity === false ? (
                    <DotCircleSolidIcon className="text-purple-800 dark:text-purple-100" />
                  ) : (
                    <CircleIcon className="text-zinc-500" />
                  )}
                  <p className="ml-1.5 text-zinc-800 dark:text-zinc-50">Keep current version</p>
                </div>
                {contact && (
                  <div className="overflow-auto max-h-80">
                    <ContactPreview contact={contact} className="px-5 pt-2" />
                  </div>
                )}
              </button>

              {/* Merge from remote source */}
              <button
                type="button"
                className={classNames(
                  "flex flex-col items-start justify-start flex-1 p-3",
                  shouldMergeAttemptedEntity === true &&
                    "bg-purple-100 dark:bg-purple-900 rounded-md"
                )}
                onClick={() => setShouldMergeAttemptedEntity(true)}
              >
                <div className="flex flex-row items-center">
                  {shouldMergeAttemptedEntity === true ? (
                    <DotCircleSolidIcon className="text-purple-800 dark:text-purple-100" />
                  ) : (
                    <CircleIcon className="text-zinc-500" />
                  )}
                  <p className="ml-1.5 text-zinc-800 dark:text-zinc-50">
                    Merge changes from {source}
                  </p>
                </div>
                <div className="overflow-auto max-h-80">
                  <ContactPreview
                    contact={conflict.remoteEntity as ContactRow}
                    className="px-5 pt-2"
                  />
                </div>
              </button>
            </div>
          )}
        </div>
      </div>

      <div className="flex flex-row-reverse mt-8">
        <Button
          className="w-full mx-1 sm:mx-0 sm:w-auto sm:text-sm"
          disabled={shouldMergeAttemptedEntity === null}
          onClick={() => {
            if (contact && conflict?.remoteEntity) {
              const resolvedConflict = shouldMergeAttemptedEntity
                ? (conflict.remoteEntity as ContactRow)
                : contact;
              onResolveSyncConflict(resolvedConflict);
            }
          }}
        >
          Save
        </Button>

        {/* Cancel button */}
        <Button
          className="w-full mx-1 sm:mx-3 sm:w-auto sm:text-sm"
          variant={ButtonVariant.Secondary}
          onClick={() => {
            onClickCancel();
          }}
          innerRef={cancelButtonRef}
        >
          Cancel
        </Button>
      </div>
    </Modal>
  );
};

export default ResolveSyncConflictAlert;
