import { Menu } from "@headlessui/react";
import Link from "next/link";
import React, { FC, PropsWithChildren, RefObject } from "react";

import { CheckIcon } from "./Icon";

type MenuItemProps = {
  title: string;
  icon?: JSX.Element;
  href?: string;
  as?: string;
  external?: boolean;
  showCheckmark?: boolean;
  onClick?: () => void;
};

// eslint-disable-next-line react/display-name
const WrappedLink: FC<PropsWithChildren<MenuItemProps>> = React.forwardRef(
  (
    { title, icon, href, as, external = false, showCheckmark = false, onClick, children, ...rest },
    ref
  ) => {
    if (!href || external) {
      return (
        <a
          className="menu-item"
          href={href}
          ref={ref as RefObject<HTMLAnchorElement>}
          {...rest}
          onClick={onClick}
        >
          {icon}
          {children}
          {title}
          {showCheckmark && <CheckIcon className="ml-1.5 text-green-500" size="sm" />}
        </a>
      );
    }
    return (
      <Link href={href} as={as} passHref>
        <a
          className="menu-item"
          ref={ref as RefObject<HTMLAnchorElement>}
          {...rest}
          onClick={onClick}
        >
          {children}
          {title}
          {showCheckmark && <CheckIcon className="ml-1.5 text-green-500" size="sm" />}
        </a>
      </Link>
    );
  }
);

export const MenuItem: FC<PropsWithChildren<MenuItemProps>> = (props) => {
  return (
    <Menu.Item>
      <WrappedLink {...props} />
    </Menu.Item>
  );
};
