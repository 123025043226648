import { RemoteApiSnapshot } from "@shared/models/RemoteApiSnapshot";
import { getHumanReadableVendorName } from "@web/helpers/integration";
import { formatDistanceToNowStrict } from "date-fns";
import { FC } from "react";

import Alert from "../../core/Alert";
import { ButtonVariant } from "../../core/Button";

type RestoreSnapshotConfirmationAlertProps = {
  snapshot: RemoteApiSnapshot | null;
  isRestoreAlertOpen: boolean;
  setIsRestoreAlertOpen: (open: boolean) => void;
  onClickRestore: () => void;
  onClickCancel: () => void;
};

const RestoreSnapshotConfirmationAlert: FC<RestoreSnapshotConfirmationAlertProps> = ({
  snapshot,
  isRestoreAlertOpen,
  setIsRestoreAlertOpen,
  onClickRestore,
  onClickCancel,
}) => {
  let title = "Restore contacts data?";
  let description = "";
  if (snapshot) {
    const vendorName = getHumanReadableVendorName(snapshot?.vendor);
    const distance = formatDistanceToNowStrict(snapshot?.createdAt, {
      addSuffix: true,
    });
    title = `Restore ${vendorName} contacts data from ${distance}?`;
    description = `Performing this action will overwrite any changes made to ${vendorName} since ${distance}.`;
  }
  return (
    <Alert
      title={title}
      description={description}
      variant={ButtonVariant.Primary}
      isAlertOpen={isRestoreAlertOpen}
      onClose={setIsRestoreAlertOpen}
      actionButtonTitle="Confirm"
      actionHandler={onClickRestore}
      cancelHandler={onClickCancel}
    />
  );
};

export default RestoreSnapshotConfirmationAlert;
