import classNames from "clsx";
import { FC, Fragment, useCallback, useEffect, useState } from "react";

import usePrevious from "../../../../hooks/usePrevious";
import { PenIcon } from "../../../core/Icon";
import TextArea from "../../../core/TextArea";
import { showToast, ToastLevel } from "../../../core/Toast";
import HighlightText from "../../../HighlightText";
import BaseDetailsField from "../shared/BaseDetailsField";
import TypeLabel from "../shared/TypeLabel";
import { ContactDataFieldProps } from "../types";

export const NotesField: FC<ContactDataFieldProps> = ({
  contactData,
  dispatch,
  isEditing,
  directlyUpdateContactData,
}) => {
  const notes = contactData.notes || "";
  const [cachedNotes, setCachedNotes] = useState<string>(notes);
  const [isEditable, setIsEditable] = useState(false);
  // Reset cached notes on contact change
  const previousContact = usePrevious(contactData);
  useEffect(() => {
    if (contactData.id !== previousContact?.id) {
      setCachedNotes(contactData.notes || "");
    }
  }, [contactData]);

  const setEditable = useCallback(() => setIsEditable(true), []);
  const setNotEditable = useCallback(() => setIsEditable(false), []);

  return (
    <BaseDetailsField
      label="Notes"
      isEditing={isEditing}
      icon={<PenIcon size="lg" className="icon-color-purple" />}
      className="text-zinc-900"
    >
      <div className="flex flex-row">
        {isEditing && <TypeLabel>Notes</TypeLabel>}
        <dd className={classNames("flex-1", isEditing ? "mt-px mr-20" : "-mt-1 max-w-prose")}>
          {isEditable ? (
            <TextArea
              autoFocus
              name="notes"
              value={cachedNotes}
              placeholder="Add notes"
              onChange={(event) => {
                setCachedNotes(event.target.value);
              }}
              onBlur={() => {
                setNotEditable();
                if (cachedNotes !== notes) {
                  dispatch({ type: "notes", payload: cachedNotes });

                  directlyUpdateContactData?.({ notes: cachedNotes });
                  showToast({
                    title: `Changes to ${contactData.givenName} ${contactData.surname} has been saved.`,
                    level: ToastLevel.Success,
                  });
                }
              }}
            />
          ) : (
            <div
              className="px-3 py-2 rounded-md shadow-sm border-primary text-input-primary"
              onClick={setEditable}
            >
              {cachedNotes ? (
                cachedNotes.split("\n").map((v, i) => {
                  return (
                    <Fragment key={v + i}>
                      <HighlightText value={v} />
                      <br />
                    </Fragment>
                  );
                })
              ) : (
                <div className="p-px text-zinc-400 dark:text-zinc-600">Add notes</div>
              )}
            </div>
          )}
        </dd>
      </div>
    </BaseDetailsField>
  );
};

export default NotesField;
