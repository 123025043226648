import { getUniqueListBy, uniqueOnlyPredicate } from "@packages/common/array";
import { get_M_d_yyyy } from "@packages/common/dateTime";
import { Contact, ContactRow } from "@shared/models/Contact";
import { pickContactFields } from "@shared/models/helpers";
import { formatAddressToString } from "@web/helpers/address";
import { getFullName } from "@web/helpers/contact";
import { formatPhoneNumber } from "@web/helpers/phoneNumber";

export type MergedContact = Contact & {
  fullNames: string[];
  giveNames: string[];
  middleNames: string[];
  surnames: string[];
  nicknames: string[];
  birthdays: string[];
  suffixes: string[];
  prefixes: string[];
};

export function getMergedContact(
  contacts: ContactRow[],
  overrides?: Partial<ContactRow>
): MergedContact {
  const mergedContact: MergedContact = {
    updatedAt: undefined,
    fullNames: [],
    jobPositions: [],
    birthdays: [],
    giveNames: [],
    middleNames: [],
    surnames: [],
    nicknames: [],
    suffixes: [],
    prefixes: [],
    id: "",
    surname: "",
    userId: "",
  };

  for (const contact of contacts) {
    const {
      nickname,
      givenName,
      surname,
      middleName,
      prefix,
      suffix,
      companyName,
      jobTitle,
      departmentName,
      managerName,
      birthday,
      emails,
      phoneNumbers,
      physicalAddresses,
      imHandles,
      webPages,
      relatives,
      photos,
      dates,
      notes,
    } = pickContactFields(contact);

    // fields for user to confirm
    mergedContact.fullNames.push(getFullName(contact));
    mergedContact.nicknames.push(nickname || "");
    mergedContact.giveNames.push(givenName || "");
    mergedContact.surnames.push(surname || "");
    mergedContact.middleNames.push(middleName || "");
    mergedContact.suffixes.push(suffix || "");
    mergedContact.prefixes.push(prefix || "");
    mergedContact.jobPositions?.push({
      companyName,
      jobTitle,
      departmentName,
      managerName,
    });
    mergedContact.birthdays.push(birthday || "");
    mergedContact.birthdays = mergedContact.birthdays.filter(uniqueOnlyPredicate);

    if (emails)
      mergedContact.emails = getUniqueListBy(
        mergedContact.emails ? mergedContact.emails.concat(emails) : emails,
        "value"
      );

    if (phoneNumbers) {
      mergedContact.phoneNumbers = mergedContact.phoneNumbers
        ? mergedContact.phoneNumbers.concat(phoneNumbers)
        : phoneNumbers;

      mergedContact.phoneNumbers = getUniqueListBy(
        mergedContact.phoneNumbers.map((phone) => ({
          ...phone,
          value: formatPhoneNumber(phone.value),
        })),
        "value"
      );
    }

    if (imHandles)
      mergedContact.imHandles = getUniqueListBy(
        mergedContact.imHandles ? mergedContact.imHandles.concat(imHandles) : imHandles,
        "value"
      );

    if (webPages)
      mergedContact.webPages = getUniqueListBy(
        mergedContact.webPages ? mergedContact.webPages.concat(webPages) : webPages,
        "value"
      );

    if (physicalAddresses) {
      // dedupe via placekey
      mergedContact.physicalAddresses = getUniqueListBy(
        mergedContact.physicalAddresses
          ? mergedContact.physicalAddresses.concat(physicalAddresses)
          : physicalAddresses,
        "placeKey"
      );

      // dedupe via formatted string
      mergedContact.physicalAddresses = getUniqueListBy(
        mergedContact.physicalAddresses.map((address) => {
          return { ...address, _formatted: formatAddressToString(address) };
        }),
        "_formatted"
      ).map(({ _formatted, ...address }) => address);
    }

    if (relatives)
      mergedContact.relatives = getUniqueListBy(
        mergedContact.relatives ? mergedContact.relatives.concat(relatives) : relatives,
        "value"
      );

    if (dates)
      mergedContact.dates = getUniqueListBy(
        mergedContact.dates ? mergedContact.dates.concat(dates) : dates,
        "value"
      );

    if (photos)
      mergedContact.photos = getUniqueListBy(
        mergedContact.photos ? mergedContact.photos.concat(photos) : photos,
        "value"
      );

    if (notes)
      mergedContact.notes = mergedContact.notes
        ? (mergedContact.notes += `\n\n----- Merged ${get_M_d_yyyy()}----\n${notes}`)
        : notes;
  }

  return { ...mergedContact, ...overrides };
}
