import { FC } from "react";

type AlphabetListProps = {
  alphabetKeys: string[];
  onSelectAlphabetKey: (alphabet: string, index: number) => void;
};

const AlphabetList: FC<AlphabetListProps> = ({ alphabetKeys, onSelectAlphabetKey }) => {
  return (
    <ul role="list">
      {alphabetKeys.map((alphabet, index) => (
        <li key={alphabet}>
          <div className="transform hover:pb-1 hover:my-1 hover:scale-150 ">
            <span
              className="relative flex items-center justify-center w-5 h-5 rounded-full cursor-pointer hover:bg-zinc-200 dark:hover:bg-zinc-800 text-secondary"
              onClick={() => onSelectAlphabetKey(alphabet, index)}
            >
              {alphabet}
            </span>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default AlphabetList;
