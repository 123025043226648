export function getMasked({
  str,
  trailingIntactCount = 4,
  maskingChar = "*",
}: {
  str: string;
  trailingIntactCount?: number;
  maskingChar?: string;
}) {
  const regex = new RegExp(
    ".(?=.{" + (trailingIntactCount <= str.length ? 0 : trailingIntactCount) + "})",
    "g"
  );
  return str.replace(regex, maskingChar);
}

export function camelToSnakeCase(str: string) {
  return str.replace(/[A-Z]/g, (letter, index) => {
    return index == 0 ? letter.toLowerCase() : "_" + letter.toLowerCase();
  });
}

export function escapeNewLine(str?: string) {
  return str?.replace(/\n/g, "\\n") || "";
}

export function removeAllWhitespace(str?: string) {
  return String(str || "").replace(/\s+/g, "");
}

const urlRegex =
  /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&\/\/=]*)/;
export function isUrl(val: string) {
  return urlRegex.test(val);
}

export function getNumsFromString(val: string) {
  return val.replace(/[^0-9]/g, "");
}
