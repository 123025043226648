import classNames from "clsx";
import { FC, MouseEventHandler } from "react";
import { usePopperTooltip } from "react-popper-tooltip";

import { TimesCircleSolidIcon } from "../../../core/Icon";

type RemoveDetailsItemButtonProps = {
  onClickButton?: MouseEventHandler<HTMLButtonElement>;
  tooltip?: string;
  className?: string;
};

const RemoveDetailsItemButton: FC<RemoveDetailsItemButtonProps> = ({
  onClickButton,
  tooltip,
  className,
}) => {
  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible } =
    usePopperTooltip();

  return (
    <>
      <button
        ref={setTriggerRef}
        tabIndex={-1}
        onClick={onClickButton}
        className={classNames("items-center justify-center w-8 h-9", className)}
      >
        <TimesCircleSolidIcon className="text-red-400 cursor-pointer hover:text-red-500" />
      </button>
      {visible && (
        <div ref={setTooltipRef} {...getTooltipProps({ className: "tooltip-container" })}>
          <div {...getArrowProps({ className: "tooltip-arrow" })} />
          {tooltip}
        </div>
      )}
    </>
  );
};

export default RemoveDetailsItemButton;
