export function roundToTwo(num: number) {
  return +(Math.round(Number(num + "e+2")) + "e-2");
}

export function numAvg(list: number[]) {
  return list.reduce((a, b) => a + b) / list.length;
}

export function randomNum(min: number, max: number) {
  return Math.random() * (max - min) + min;
}

export function getRandomInt(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
