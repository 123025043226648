import { Disclosure } from "@headlessui/react";
import { ChevronLeftIcon } from "@web/components/core/Icon";
import { BaseRoute } from "@web/helpers/routes";
import classNames from "clsx";
import Link from "next/link";
import { useRouter } from "next/router";
import { FC } from "react";

type SidebarItemProps = {
  route: BaseRoute;
  actionTitle?: string;
};

// eslint-disable-next-line react/display-name
const SidebarRouteItem: FC<SidebarItemProps> = ({ route, actionTitle }) => {
  const router = useRouter();
  const isCurrent = route.isCurrent?.(router.pathname);
  const isActive = route.isActive?.(router.pathname);
  const href = typeof route.href === "string" ? route.href : route.href();
  const as = route.as && route.as();
  return route.subRoutes ? (
    <Disclosure as="div" key={route.title} className="space-y-1" defaultOpen={isActive}>
      {({ open }) => (
        <>
          <Disclosure.Button
            className={classNames(
              isCurrent || (!open && isActive)
                ? "text-zinc-900 dark:text-zinc-50 bg-zinc-200 dark:bg-zinc-800"
                : "text-zinc-700 dark:text-zinc-400 hover:text-zinc-900 dark:hover:text-zinc-100 hover:bg-zinc-50 dark:hover:bg-zinc-800",
              "group w-full flex items-center pl-2 pr-1 py-2 text-left text-sm font-medium rounded-md focus:outline-none"
            )}
            onClick={async (e: React.MouseEvent) => {
              if (!isCurrent) {
                e.preventDefault();
              }
              await router.push(href, as);
            }}
          >
            {route.icon && (
              <div className="mr-3 pt-0.5 flex-shrink-0 h-6 w-6 text-center">
                <route.icon
                  className={
                    isCurrent || (!open && isActive)
                      ? "text-zinc-500 dark:text-zinc-400"
                      : "text-zinc-400 dark:text-zinc-600 group-hover:text-zinc-500"
                  }
                />
              </div>
            )}
            <span className="flex-1">{route.title}</span>
            <ChevronLeftIcon
              className={classNames(
                open ? "text-gray-400 -rotate-90 " : "text-gray-300",
                "ml-3 flex-shrink-0 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150"
              )}
            />
          </Disclosure.Button>

          <Disclosure.Panel className="space-y-1">
            {route.subRoutes?.map((sub) => {
              const isSubCurrent = sub.isCurrent?.(router.pathname);
              return (
                <Link href={sub.href} as={sub.href} passHref key={sub.href}>
                  <Disclosure.Button
                    key={sub.title}
                    className={classNames(
                      isSubCurrent
                        ? "text-zinc-900 dark:text-zinc-50 bg-zinc-200 dark:bg-zinc-800"
                        : "text-zinc-700 dark:text-zinc-400 hover:text-zinc-900 dark:hover:text-zinc-100 hover:bg-zinc-50 dark:hover:bg-zinc-800",
                      "group w-full flex items-center pl-11 pr-2 py-2 text-sm font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50"
                    )}
                  >
                    {sub.icon && (
                      <div className="mr-3 pt-0.5 flex-shrink-0 h-6 w-6 text-center">
                        <sub.icon
                          className={
                            isSubCurrent
                              ? "text-zinc-500 dark:text-zinc-400"
                              : "text-zinc-400 dark:text-zinc-600 group-hover:text-zinc-500"
                          }
                        />
                      </div>
                    )}
                    {sub.title}
                  </Disclosure.Button>
                </Link>
              );
            })}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  ) : (
    <Link href={href} as={as} passHref>
      <a
        className={classNames(
          isCurrent || isActive
            ? "text-zinc-900 dark:text-zinc-50 bg-zinc-200 dark:bg-zinc-800"
            : "text-zinc-700 dark:text-zinc-400 hover:text-zinc-900 dark:hover:text-zinc-100 hover:bg-zinc-50 dark:hover:bg-zinc-800",
          "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
        )}
        aria-current={isCurrent ? "page" : undefined}
      >
        {/* Route icon */}
        {route.icon && (
          <div className="mr-3 pt-0.5 flex-shrink-0 h-6 w-6 text-center">
            <route.icon
              className={
                isCurrent
                  ? "text-zinc-500 dark:text-zinc-400"
                  : "text-zinc-400 dark:text-zinc-600 group-hover:text-zinc-500"
              }
            />
          </div>
        )}

        {/* Route title */}
        <div className="flex-1">{route.title}</div>

        {/* Action title */}
        {actionTitle && (
          <div className="text-xs font-bold uppercase text-zinc-400 dark:text-zinc-600 group-hover:text-zinc-500">
            {actionTitle}
          </div>
        )}
      </a>
    </Link>
  );
};

export default SidebarRouteItem;
