import { Menu, Transition } from "@headlessui/react";
import { XCircleIcon } from "@heroicons/react/outline";
import { getCurEpocSec, getSec } from "@packages/common/dateTime";
import { objKeys } from "@packages/common/object";
import { ContactRow } from "@shared/models/Contact";
import { IsDefault } from "@shared/models/types";
import Alert from "@web/components/core/Alert";
import Button, { ButtonVariant } from "@web/components/core/Button";
import { UserInvite } from "@web/components/core/Icon";
import HighlightText from "@web/components/HighlightText";
import useCollaborateExpiration from "@web/components/tools/collaborate/useCollaborateExpiration";
import useCollaboratePermission from "@web/components/tools/collaborate/useCollaboratePermission";
import { getFullName } from "@web/helpers/contact";
import { useContacts } from "@web/hooks/useContacts";
import { useOnClickOutside } from "@web/hooks/useOutsideClick";
import { useContactSearch } from "@web/hooks/useSearch";
import { getIdIndex } from "@web/integrations/contact/helpers";
import { useInviteCollaboratorMutation } from "@web/integrations/user/api";
import classNames from "clsx";
import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Virtuoso, VirtuosoHandle } from "react-virtuoso";
import { useKey } from "rooks";
import { compareTwoStrings as strDiff } from "string-similarity";

function getEaScore(title: string) {
  if (!title) return 0;
  const targetTitles = ["assistant", "ea", "secretary"];
  const titleLower = title.toLowerCase();
  let score: number = 0;
  for (const i of targetTitles) {
    const newScore = strDiff(titleLower, i);
    if (newScore > score) {
      score = newScore;
    }
  }
  return score;
}

function getDefaultEmail(emails: ContactRow["emails"]) {
  const defaultEmail = emails?.find((email) => email.isDefault === IsDefault.YES);
  if (!defaultEmail) {
    const [firstEmail] = emails || [];
    return firstEmail.value;
  }
  return defaultEmail.value;
}

export default function useCollaboratorInvite() {
  const [isShowInviteOpen, setIsShowInviteOpen] = useState(false);
  const setShowInviteOpen = useCallback(() => {
    setIsShowInviteOpen(true);
  }, []);
  const setShowInviteClosed = useCallback(() => {
    setIsShowInviteOpen(false);
  }, []);

  const [inviteCollaborator] = useInviteCollaboratorMutation();

  const { PermissionSelector, permission } = useCollaboratePermission();
  const { ExpirationSelector, expiration } = useCollaborateExpiration();
  const { result, query, setQuery, queryOnChange } = useContactSearch("", {
    index: ["_fullName", "surname", "emails[]:value"],
  });

  const { contacts: allContacts } = useContacts();

  const contactIndex = useMemo(() => {
    if (!allContacts) return {};
    return getIdIndex(allContacts);
  }, [allContacts]);

  const contacts = useMemo(() => {
    if (!query) return [];
    const uniqueContactIds: { [id: string]: true } = {};
    for (const r of result) {
      for (const id of r.result) {
        uniqueContactIds[id] = true;
      }
    }
    return objKeys(uniqueContactIds)
      .map((contactId) => {
        if (allContacts) {
          const contact = allContacts[contactIndex[contactId]];
          if ((contact.emails || []).length === 0) {
            return undefined;
          }

          const _score = getEaScore(contact.jobTitle || "");
          const [emailLabel, moreEmailLabel] =
            contact.emails
              ?.map(({ value }, i) => {
                if (i === 0) {
                  return value;
                } else if (i === (contact.emails?.length || 0) - 1) {
                  return `+${(contact.emails?.length || 0) - 1}`;
                }
              })
              .filter(Boolean) || [];

          return {
            ...contact,
            _score,
            _fullName: getFullName(contact),
            _emailLabel: emailLabel,
            _moreEmailLabel: moreEmailLabel,
          };
        }
      })
      .filter(Boolean)
      .sort((a, b) => b!._score - a!._score);
  }, [result, query, contactIndex, allContacts]);

  useEffect(() => {
    if (contacts.length > 0) setShowDropdown(true);
    else setShowDropdown(false);
  }, [contacts]);

  const [showDropdown, setShowDropdown] = useState(contacts.length > 0);
  const [hoveredIndex, setHoveredIndex] = useState<number>(0);
  const [selectedIndex, setSelectedIndex] = useState<number | undefined>();
  const [selectedEmails, setSelectedEmails] = useState<
    { contact?: ContactRow; selected: string }[]
  >([]);

  const onShowDropdown = useCallback(() => {
    if (contacts.length > 0) {
      setShowDropdown(true);
    }
  }, [contacts]);

  const onHideDropdown = useCallback(() => {
    setShowDropdown(false);
  }, []);

  const selectHoveredContact = useCallback((contact: ContactRow) => {
    setSelectedEmails((prevState) => {
      const uniqueContactIds: { [id: string]: true } = {};
      for (const selected of prevState) {
        if (selected?.contact?.id) uniqueContactIds[selected.contact.id] = true;
      }
      if (contact && !uniqueContactIds[contact.id]) {
        return [...prevState, { contact, selected: getDefaultEmail(contact.emails) }];
      }
      return prevState;
    });
    setQuery("");
  }, []);

  useKey("ArrowUp", () => {
    setHoveredIndex((prevState) => {
      const nextIndex = Math.max(0, prevState - 1);
      autocompleteNodeRef.current?.scrollIntoView({ index: nextIndex });
      return nextIndex;
    });
  });

  useKey("ArrowDown", (e) => {
    setHoveredIndex((prevState) => {
      const nextIndex = Math.min(prevState + 1, contacts.length - 1);
      autocompleteNodeRef.current?.scrollIntoView({ index: nextIndex });
      return nextIndex;
    });
  });

  useKey("Enter", (e) => {
    e.stopImmediatePropagation();
    e.preventDefault();
    if (typeof hoveredIndex !== "undefined")
      selectHoveredContact(contacts[hoveredIndex] as ContactRow);
  });

  const autocompleteNodeRef = useRef<VirtuosoHandle>(null);
  const autocompleteWrapperRef = useRef(null);

  useOnClickOutside(autocompleteWrapperRef, onHideDropdown);

  const CollaboratorInvite = useMemo(() => {
    return (
      <div className="relative mt-4">
        <div className="border border-gray-300 rounded-lg shadow-sm focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500">
          <label htmlFor="searchContacts" className="sr-only">
            Enter email, or search contacts
          </label>
          <div className="relative">
            <input
              autoFocus
              type="text"
              id="searchContacts"
              className="block w-full border-0 border-b-2 bg-primary text-primary rounded-t-lg border-gray-200 pt-2.5 text-sm font-medium placeholder-gray-500 focus:ring-0"
              placeholder="Enter email, or search contacts..."
              value={query}
              onChange={queryOnChange}
              autoComplete="off"
              onFocus={onShowDropdown}
            />
            <Transition
              show={showDropdown}
              as="div"
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div
                ref={autocompleteWrapperRef}
                className="absolute z-30 max-h-60 bg-primary text-primary w-full overflow-auto rounded-b-md py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
              >
                <Virtuoso
                  style={{ height: "200px" }}
                  ref={autocompleteNodeRef}
                  data={contacts}
                  itemContent={(index, contact) => {
                    const isHovered = hoveredIndex === index;
                    return (
                      <div
                        key={contact!.id}
                        className={classNames(
                          "relative cursor-default select-none py-2 pl-3 pr-9",
                          isHovered
                            ? "text-white dark:text-gray-50 bg-indigo-500"
                            : "text-gray-900 dark:text-gray-400"
                        )}
                        id={contact!.id}
                        role="option"
                        onMouseEnter={() => setHoveredIndex(index)}
                        onClick={() => {
                          selectHoveredContact(contact as ContactRow);
                          onHideDropdown();
                        }}
                      >
                        <div className="flex">
                          <span
                            className={classNames("truncate mr-4", { "font-semibold": isHovered })}
                          >
                            <HighlightText
                              value={contact!._fullName}
                              highlight={isHovered ? "" : query}
                            />
                          </span>
                          <span
                            className={classNames(
                              "truncate",
                              isHovered ? "text-indigo-200" : "text-gray-500"
                            )}
                          >
                            {contact?._emailLabel && (
                              <HighlightText
                                value={contact._emailLabel}
                                highlight={isHovered ? "" : query}
                              />
                            )}
                            {contact?._moreEmailLabel && `, ${contact._moreEmailLabel}`}
                          </span>
                        </div>
                      </div>
                    );
                  }}
                />
              </div>
            </Transition>
            {selectedEmails.map((selectedEmail, index) => {
              return (
                <span className="m-2 inline-flex shadow-sm rounded-md" key={index}>
                  <Menu as="span" className="relative block">
                    <Menu.Button
                      type="button"
                      className="relative inline-flex items-center p-2 rounded-l-md border border-gray-300 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                    >
                      {selectedEmail.selected}
                    </Menu.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="origin-top-left bg-primary z-50 absolute left-0 -mr-1 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        {selectedEmail?.contact?.emails?.map((email) => (
                          <Menu.Item key={email.value}>
                            {({ active }) => (
                              <span
                                className={classNames(
                                  active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                                  "block px-4 py-2 text-sm"
                                )}
                                onClick={() =>
                                  setSelectedEmails((prevState) => {
                                    const newState = [...prevState];
                                    newState[index].selected = email.value;
                                    return newState;
                                  })
                                }
                              >
                                {email.value}
                              </span>
                            )}
                          </Menu.Item>
                        ))}
                      </Menu.Items>
                    </Transition>
                  </Menu>
                  <span className="-ml-px relative block">
                    <button
                      onClick={() => {
                        setSelectedEmails((prevState) => {
                          const newState = [...prevState];
                          newState.splice(index, 1);
                          return newState;
                        });
                      }}
                      className="relative inline-flex h-full items-center p-2 rounded-r-md border border-gray-300 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                    >
                      <span className="sr-only">More emails</span>
                      <XCircleIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </span>
                </span>
              );
            })}
          </div>

          {/* Spacer element to match the height of the toolbar */}
          <div aria-hidden="true">
            <div className="py-2">
              <div className="h-9" />
            </div>
            <div className="h-px" />
            <div className="py-2">
              <div className="py-px">
                <div className="h-9" />
              </div>
            </div>
          </div>
        </div>

        <div className="absolute bottom-0 inset-x-px">
          <div className="flex flex-nowrap justify-end py-2 px-2 space-x-2 sm:px-3">
            {PermissionSelector}
            {ExpirationSelector}
          </div>
        </div>
      </div>
    );
  }, [
    query,
    showDropdown,
    selectedEmails,
    contacts,
    PermissionSelector,
    ExpirationSelector,
    hoveredIndex,
    selectedIndex,
  ]);

  const emails = useMemo(() => {
    if (selectedEmails.length > 0) {
      let emails: string[] = [];
      for (const i of selectedEmails) {
        emails = emails.concat(i.selected);
      }
      return emails;
    }
    return undefined;
  }, [expiration, permission, selectedEmails]);

  const InviteModal = useMemo(() => {
    return (
      <Alert
        title="Invite Collaborator"
        AlertIcon={<UserInvite size="lg" className="text-zinc-400 dark:text-zinc-600" />}
        variant={ButtonVariant.Primary}
        actionButtonTitle="Send Invite"
        actionButtonProps={{ disabled: !emails }}
        actionHandler={async () => {
          let expiresAt: number | undefined = undefined;
          if (expiration?.days || expiration?.hours) {
            expiresAt = getCurEpocSec();
            if (expiration.days) {
              expiresAt += getSec(`${expiration.days} days`);
            }
            if (expiration.hours) {
              expiresAt += getSec(`${expiration.hours} h`);
            }
          }

          await Promise.all(
            (emails || []).map((email) => {
              return inviteCollaborator({
                recipientEmail: email,
                userDelegation: {
                  delegatedRoles: permission?.value === "read" ? ["read"] : undefined,
                  expiresAt,
                },
              });
            })
          );
          setShowInviteClosed();
        }}
        isAlertOpen={isShowInviteOpen}
        onClose={setShowInviteClosed}
        width="xl"
      >
        <div className="my-2 text-sm text-zinc-500 dark:text-zinc-400">
          Collaborators may be read-only or read-write, with an optional expiration.
        </div>
        {CollaboratorInvite}
      </Alert>
    );
  }, [
    CollaboratorInvite,
    emails,
    expiration?.days,
    expiration?.hours,
    inviteCollaborator,
    isShowInviteOpen,
    permission?.value,
    setShowInviteClosed,
  ]);

  const InviteButton = useMemo(() => {
    return (
      <Button
        variant={ButtonVariant.Primary}
        onClick={setShowInviteOpen}
        hotkey={["Alt", "KeyI"]}
        icon={<UserInvite size="lg" />}
      >
        Invite
      </Button>
    );
  }, []);

  return {
    InviteButton,
    InviteModal,
    isShowInviteOpen,
    setShowInviteOpen,
    setShowInviteClosed,
    CollaboratorInvite,
    emails,
    permission,
    expiration,
  };
}
