import { PhysicalAddress } from "@shared/models/types";
import * as parseAddress from "parse-address";

export function parseAddressString(addressString: string, type?: string): PhysicalAddress {
  const parsed = parseAddress.parseLocation(addressString);
  return {
    type,
    street:
      [parsed.number, parsed.prefix, parsed.street, parsed.type].filter(Boolean).join(" ") || "",
    line2: [parsed.sec_unit_type, parsed.sec_unit_num].filter(Boolean).join(" ") || "",
    city: parsed.city,
    state: parsed.state,
    postalCode: parsed.zip,
  };
}

export function formatAddressToString(address: PhysicalAddress) {
  const components: (string | undefined)[] = [
    address.street,
    ...(address.line2 || "").split("\\n"),
    [address.city, address.state].filter(Boolean).join(", "),
    address.country,
  ];
  return components.filter(Boolean).join(", ");
}

export function getGoogleMapsLink(address: PhysicalAddress): string {
  const spaced = [address.street, address.line2].join("%20");
  const commaDelimited = [address.city, address.postalCode, address.state, address.country].join(
    "%2C"
  );

  return `https://maps.google.com/?q=${spaced}%2C${commaDelimited}`;
}
