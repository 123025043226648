import { FC } from "react";

import BreadcrumbNavigation from "../core/BreadcrumbNavigation";
import { BarsIcon } from "../core/Icon";

type SidebarMobileNavBarProps = {
  setIsSidebarOpen: (open: boolean) => void;
};

const SidebarMobileNavBar: FC<SidebarMobileNavBarProps> = ({ setIsSidebarOpen }) => {
  return (
    <div className="lg:hidden">
      <div className="flex items-center justify-start bg-primary border-primary-b px-4 py-1.5">
        <button
          type="button"
          className="inline-flex items-center justify-center w-12 h-12 -mr-3 rounded-md text-zinc-500 hover:text-zinc-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-pink-600"
          onClick={() => setIsSidebarOpen(true)}
        >
          <span className="sr-only">Open sidebar</span>
          <BarsIcon className="w-6 h-6" aria-hidden="true" />
        </button>
        <BreadcrumbNavigation className="flex w-full ml-4" />
      </div>
    </div>
  );
};

export default SidebarMobileNavBar;
