export const PrimaryColor =
  "text-white dark:text-white bg-purple-700 dark:bg-purple-800 border border-transparent";
export const PrimaryHoverColor = "hover:bg-purple-600 dark:hover:bg-purple-700";

export const SecondaryColor =
  "text-zinc-700 dark:text-zinc-300 bg-white dark:bg-zinc-800 border border-zinc-300 dark:border-zinc-600";
export const SecondaryHoverColor = "hover:bg-zinc-50 dark:hover:bg-zinc-700";

export const WarningColor =
  "text-yellow-700 dark:text-yellow-200 bg-yellow-100 dark:bg-yellow-900 border border-yellow-300 dark:border-yellow-600";
export const WarningHoverColor = "hover:bg-yellow-200 dark:hover:bg-yellow-800";

export const SuccessColor =
  "text-green-700 dark:text-green-200 bg-green-100 dark:bg-green-900 border border-green-300 dark:border-green-600";
export const SuccessHoverColor = "hover:bg-green-200 dark:hover:bg-green-800";

export const DangerColor =
  "text-red-700 dark:text-red-200 bg-red-100 dark:bg-red-900 border border-red-300 dark:border-red-600";
export const DangerHoverColor = "hover:bg-red-200 dark:hover:bg-red-800";
