import { FC, Fragment, useContext } from "react";

import { ContactSearchQueryContext } from "../hooks/useSearch";

const HighlightText: FC<{
  value: string | undefined | null;
  highlight?: string;
  matchPhrase?: boolean;
}> = ({ value, highlight, matchPhrase = true }) => {
  const contextSearchQuery = useContext(ContactSearchQueryContext);
  const searchQuery = highlight || contextSearchQuery;

  if (!value?.trim()) return null;

  if (!searchQuery.trim()) {
    return <>{value}</>;
  }

  const token = matchPhrase
    ? searchQuery
    : searchQuery
        .split(/\s+/)
        .filter((i) => i?.length)
        .join("|");

  let regex: RegExp | undefined;
  try {
    regex = new RegExp(`(${token})`, "gi");
  } catch (error) {
    console.warn("Regex error: ", error);
    return <>{value}</>;
  }

  const parts = value.split(regex);
  return (
    <>
      {parts.filter(String).map((part, i) => {
        return regex?.test(part) ? (
          <mark key={i}>{part}</mark>
        ) : (
          <Fragment key={i}>{part}</Fragment>
        );
      })}
    </>
  );
};

export default HighlightText;
