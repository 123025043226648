import { ContactRow } from "@shared/models/Contact";
import { isEmpty } from "@web/helpers/array";
import {
  ContactVersionOperationGroups,
  createOperationGroups,
  OperationGroup,
} from "@web/helpers/operation";
import { formatDistanceToNowStrict } from "date-fns";
import { compare } from "fast-json-patch";
import { FC } from "react";

import Alert from "../../../core/Alert";
import { ButtonVariant } from "../../../core/Button";
import OperationGroupRow from "./OperationGroupRow";

type RestoreContactConfirmationAlertProps = {
  currentContact: ContactRow;
  versionOperationGroups: ContactVersionOperationGroups | null;
  isRestoreAlertOpen: boolean;
  setIsRestoreAlertOpen: (open: boolean) => void;
  onClickRestore: () => void;
  onClickCancel: () => void;
};

const RestoreContactConfirmationAlert: FC<RestoreContactConfirmationAlertProps> = ({
  currentContact,
  versionOperationGroups,
  isRestoreAlertOpen,
  setIsRestoreAlertOpen,
  onClickRestore,
  onClickCancel,
}) => {
  const contactVersion = versionOperationGroups?.version;

  let title = "Restore contact data?";
  if (contactVersion?.createdAt) {
    const distance = formatDistanceToNowStrict(contactVersion?.createdAt, {
      addSuffix: true,
    });
    title = `Restore contact data from ${distance}?`;
  }

  let operationGroups: OperationGroup[] = [];
  if (versionOperationGroups && contactVersion?.curContact) {
    const result = compare(currentContact, contactVersion.curContact, true);
    if (result) {
      operationGroups = createOperationGroups(result);
    }
  }

  return (
    <Alert
      title={title}
      description={`Performing this action will make following changes to current contact data:`}
      variant={ButtonVariant.Primary}
      isAlertOpen={isRestoreAlertOpen}
      onClose={setIsRestoreAlertOpen}
      actionButtonTitle="Confirm"
      actionHandler={onClickRestore}
      cancelHandler={onClickCancel}
    >
      {/* List of operation groups */}
      <ul className="py-2">
        {!isEmpty(operationGroups) &&
          operationGroups.map((operationGroup, index) => {
            return (
              <OperationGroupRow
                key={index}
                group={operationGroup}
                isPastOperation={false}
                curContact={contactVersion?.curContact}
                prevContact={currentContact}
              />
            );
          })}
      </ul>
    </Alert>
  );
};

export default RestoreContactConfirmationAlert;
