import { LocationMarkerIcon } from "@heroicons/react/solid";
import { defaultToolRoute } from "@web/components/tools/tabs";
import { ElementType } from "react";

import {
  BellSolidIcon,
  BookmarkIcon,
  CalendarIcon,
  CogSolidIcon,
  IconType,
  MapMarkedAltSolidIcon,
  TagSolidIcon,
  ToolsSolidIcon,
  UsersSolidIcon,
} from "../components/core/Icon";

type RouteResolver = (slug?: string) => string;

export interface BaseRoute {
  title: string;
  href: string | RouteResolver;
  as?: RouteResolver;
  icon?: ElementType;
  isCurrent?: (pathname: string) => boolean;
  isActive?: (pathname: string) => boolean;
  subRoutes?: {
    title: string;
    href: string;
    icon?: ElementType;
    isCurrent: (pathname: string) => boolean;
  }[];
}

export const ContactsRoute: BaseRoute = {
  title: "Contacts",
  href: (slug) => (slug ? "/contacts/[slug]" : "/contacts"),
  as: (slug) => (slug ? `/contacts/${slug}` : "/contacts"),
  icon: UsersSolidIcon,
  isCurrent: (pathname) =>
    pathname === "/" || pathname === "/contacts" || pathname === "/contacts/[[...slug]]",
  isActive: (pathname) => pathname === "/" || pathname.startsWith("/contacts"),
  subRoutes: [
    {
      title: "Dates",
      href: "/contacts/dates",
      icon: CalendarIcon,
      isCurrent: (pathname) => pathname === "/contacts/dates",
    },
    {
      title: "Locations",
      href: "/contacts/locations",
      icon: MapMarkedAltSolidIcon,
      isCurrent: (pathname) => pathname === "/contacts/locations",
    },
  ],
};

export const GroupsRoute: BaseRoute = {
  title: "Groups",
  href: (slug) => (slug ? "/groups/[slug]" : "/groups/user"),
  as: (slug) => (slug ? `/groups/${slug}` : "/groups/user"),
  icon: TagSolidIcon,
  isCurrent: (pathname) => pathname === "/groups",
  isActive: (pathname) => pathname.startsWith("/groups"),
};

export const SettingsRoute: BaseRoute = {
  title: "Settings",
  href: (slug) => `/settings/${slug ? "[slug]" : "account"}`,
  as: (slug) => `/settings/${slug || "account"}`,
  icon: CogSolidIcon,
  isCurrent: (pathname) => pathname === "/settings",
  isActive: (pathname) => pathname.startsWith("/settings"),
};

export const UpdatesRoute: BaseRoute = {
  title: "Updates",
  href: "/updates",
  icon: BellSolidIcon,
  isCurrent: (pathname) => pathname === "/updates",
  isActive: (pathname) => pathname.startsWith("/updates"),
};

export const ToolsRoute: BaseRoute = {
  title: "Tools",
  href: (slug) => `/tools/${slug ? "[slug]" : defaultToolRoute}`,
  as: (slug) => `/tools/${slug || defaultToolRoute}`,
  icon: ToolsSolidIcon,
  isCurrent: (pathname) => pathname === "/tools",
  isActive: (pathname) => pathname.startsWith("/tools"),
};

export const allRoutes: BaseRoute[] = [ContactsRoute, GroupsRoute, SettingsRoute, UpdatesRoute];

export function getBaseRoute(pathname: string): BaseRoute {
  return allRoutes.find((route) => route.isCurrent?.(pathname)) || ContactsRoute;
}
