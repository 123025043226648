import { removeUndefinedFromList } from "./array";
import { NoUndefinedField } from "./types";

export function objKeys<T>(obj: T) {
  return Object.keys(obj) as Array<keyof typeof obj>;
}

export function removeUndefinedFromObj<T>(obj: T) {
  for (const key in obj) {
    if (typeof obj[key] === "undefined") {
      delete obj[key];
    } else if (typeof obj[key] === "object") {
      removeUndefinedFromObj(obj[key]);
    } else if (Array.isArray(obj[key])) {
      removeUndefinedFromList(obj[key]);
    }
  }
  return obj as unknown as NoUndefinedField<T>;
}

export function emptyFalseyStrings<T>(obj: T) {
  const result = { ...obj };
  for (const key in result) {
    if (!result[key] || String(result[key]) === "null") {
      // @ts-ignore
      result[key] = "";
    }
    if (typeof result[key] === "object") {
      emptyFalseyStrings(result[key]);
    }
  }

  return result;
}
