import { objKeys } from "@packages/common/object";
import { ContactRow } from "@shared/models/Contact";
import LoadingSpinner from "@web/components/loading/LoadingSpinner";
import { isEmpty } from "@web/helpers/array";
import { pluralize } from "@web/helpers/string";
import { SortableKeys } from "@web/hooks/useContacts";
import { useIsFocusIn } from "@web/hooks/useIsFocusIn";
import { getIdIndex } from "@web/integrations/contact/helpers";
import { selectMultiSelectedContacts } from "integrations/contact/selectors";
import contactSlice from "integrations/contact/slice";
import { useAppDispatch } from "integrations/redux/store";
import { uniqBy } from "lodash";
import { useRouter } from "next/router";
import { FC, RefObject, useCallback, useEffect, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { GroupedVirtuoso, Virtuoso, VirtuosoHandle } from "react-virtuoso";
import { useKey } from "rooks";

import AlphabetList from "./AlphabetList";
import ContactListItem from "./ContactListItem";

type ContactListProps = {
  isLoaded?: boolean;
  contacts: { [letter: string]: ContactRow[] } | ContactRow[];
  sortKey: SortableKeys;
  selectedContact?: ContactRow | null;
  showContactsCount?: boolean;
  onClickContactItem?: (contact: ContactRow, event: React.MouseEvent<HTMLElement>) => void;
  onKeySelectContactItem?: (contact: ContactRow) => void;
};

const ContactList: FC<ContactListProps> = ({
  isLoaded,
  contacts,
  sortKey,
  selectedContact,
  showContactsCount = false,
  onClickContactItem,
  onKeySelectContactItem,
}) => {
  const didUserClick = useRef(false);

  const router = useRouter();
  const dispatch = useAppDispatch();
  const multiSelectedContacts = useSelector(selectMultiSelectedContacts);

  const virtuosoRef = useRef<VirtuosoHandle>(null);

  const onSetMultiSelectedContacts = useCallback((contacts: ContactRow[]) => {
    dispatch(contactSlice.actions.setMultiSelectedContacts({ contacts }));
  }, []);

  const onClearMultiSelections = useCallback(() => {
    dispatch(contactSlice.actions.clearMultiSelectedContacts());
  }, []);

  const onClickItem = useCallback(
    (event: React.MouseEvent<HTMLElement>, contact: ContactRow) => {
      didUserClick.current = true;
      if (onClickContactItem) {
        onClickContactItem(contact, event);
        return;
      }
      if (event.shiftKey || event.metaKey) {
        // Stop event propagation (so that we don't route to contact details page)
        event.preventDefault();
        event.stopPropagation();
        let results: ContactRow[] = [];

        // On the first multi selection, add currently selected contact if necessary
        if (selectedContact && multiSelectedContacts.length === 0) {
          results = [selectedContact];
        }

        // Update multi-selection array
        if (multiSelectedContacts.map((o) => o.id).includes(contact.id)) {
          results = uniqBy(
            [...results, ...multiSelectedContacts.filter((o) => o.id !== contact.id)],
            "id"
          );
        } else {
          results = uniqBy([...results, ...multiSelectedContacts, contact], "id");
        }

        // Call onSetMultiSelectedContacts with selectedContact included in the results
        onSetMultiSelectedContacts(results);
      } else {
        // Reset multi-selection and propagate event
        onClearMultiSelections();
      }
    },
    [selectedContact, multiSelectedContacts]
  );

  // Process contacts dict / list
  const { alphabetKeys, alphabetKeysCounts, flattenedContacts } = useMemo(() => {
    if (Array.isArray(contacts)) {
      return { alphabetKeys: [], alphabetKeysCounts: [], flattenedContacts: contacts };
    }
    const alphabetKeys = objKeys(contacts) as string[];

    // Perform secondary sort for name fields
    let flattenedContacts: ContactRow[] = [];
    if (sortKey === "givenName" || sortKey === "surname") {
      const secondaryKey: keyof ContactRow = sortKey === "givenName" ? "surname" : "givenName";
      alphabetKeys.forEach((alphabet) => {
        const sortedContacts = contacts[alphabet].sort((a, b) => {
          const result = (a[sortKey]?.toLowerCase() || "").localeCompare(
            b[sortKey]?.toLowerCase() || ""
          );
          return result !== 0
            ? result
            : (a[secondaryKey]?.toLowerCase() || "").localeCompare(
                b[secondaryKey]?.toLowerCase() || ""
              );
        });
        flattenedContacts.push(...sortedContacts);
      });
    } else {
      flattenedContacts = Object.values(contacts).flat();
    }

    return {
      alphabetKeys,
      alphabetKeysCounts: alphabetKeys.map((alphabet) => {
        return contacts[alphabet]?.length || 0;
      }),
      flattenedContacts,
    };
  }, [contacts, sortKey]);

  // Main list component
  const ContactListComponent = useMemo(() => {
    if (isLoaded && flattenedContacts.length === 0) {
      return (
        <div className="relative block w-full p-12 text-center">
          <span className="mt-2 block text-sm font-medium text-primary">No contacts</span>
        </div>
      );
    }

    if (isLoaded === false) {
      return <LoadingSpinner />;
    }

    if (Array.isArray(contacts)) {
      return (
        <>
          <div className="divider" />
          <Virtuoso
            ref={virtuosoRef}
            data={contacts}
            itemContent={(i, contact) => {
              return (
                <ContactListItem
                  contact={contact}
                  sortKey={sortKey}
                  isSelected={
                    isEmpty(multiSelectedContacts)
                      ? contact.id === selectedContact?.id
                      : multiSelectedContacts.includes(contact)
                  }
                  onClickItem={(event) => onClickItem(event, contact)}
                />
              );
            }}
          />
        </>
      );
    }

    return (
      <GroupedVirtuoso
        ref={virtuosoRef}
        groupCounts={alphabetKeysCounts}
        groupContent={(index) => {
          return (
            <div className="px-6 py-1 text-sm font-medium text-gray-500 border-t border-b border-zinc-200 dark:border-zinc-600 bg-zinc-50 dark:bg-zinc-900">
              <h3>{alphabetKeys[index]}</h3>
            </div>
          );
        }}
        itemContent={(index) => {
          const contact = flattenedContacts[index];
          return (
            <ContactListItem
              key={contact.id}
              contact={contact}
              sortKey={sortKey}
              isSelected={
                isEmpty(multiSelectedContacts)
                  ? contact.id === selectedContact?.id
                  : multiSelectedContacts.includes(contact)
              }
              onClickItem={(event) => onClickItem(event, contact)}
            />
          );
        }}
        components={{
          Footer: () => {
            return showContactsCount && flattenedContacts.length > 0 ? (
              <div className="w-full py-6 text-center border-t border-zinc-200 dark:border-zinc-600 text-label">
                {pluralize(flattenedContacts.length, "Contact", "Contacts", true)}
              </div>
            ) : null;
          },
        }}
      />
    );
  }, [contacts, alphabetKeys, sortKey, selectedContact, multiSelectedContacts]);

  const onSelectAlphabetKey = useCallback(
    (_alphabet: string, index: number) => {
      if (virtuosoRef.current) {
        const contactIndex = [...alphabetKeysCounts].splice(0, index).reduce((prev, cur) => {
          return prev + cur;
        }, 0);
        virtuosoRef.current.scrollToIndex({
          index: contactIndex,
          align: "start",
          behavior: "auto",
        });
      }
    },
    [contacts, alphabetKeys]
  );

  const contactIndex = useMemo(() => {
    return getIdIndex(flattenedContacts);
  }, [flattenedContacts]);

  useEffect(() => {
    // always make sure selected contact is in view
    if (contactIndex && selectedContact?.id) {
      const index = contactIndex[selectedContact.id];
      if (didUserClick.current) {
        virtuosoRef.current?.scrollIntoView({
          index,
        });
      } else {
        virtuosoRef.current?.scrollToIndex({
          index,
        });
      }
    }
  }, [contactIndex, didUserClick, selectedContact?.id]);

  // Hot up key navigation
  const onUpNavigation = useCallback(() => {
    if (!selectedContact || isEmpty(flattenedContacts) || !isEmpty(multiSelectedContacts)) {
      return;
    }
    const index = contactIndex[selectedContact.id];
    const prevContactIndex = Math.max(0, index - 1);
    const previousContact = flattenedContacts[prevContactIndex];
    virtuosoRef.current?.scrollIntoView({
      index: prevContactIndex - 2,
      done: () => {
        if (previousContact) {
          if (onKeySelectContactItem) {
            onKeySelectContactItem(previousContact);
          } else if (!onClickContactItem)
            router.push("/contacts/[[...slug]]", `/contacts/${previousContact.id}`, {
              shallow: true,
            });
        }
      },
    });
  }, [
    contactIndex,
    flattenedContacts,
    multiSelectedContacts,
    onClickContactItem,
    router,
    selectedContact,
  ]);

  // Hot down key navigation
  const onDownNavigation = useCallback(() => {
    if (!selectedContact || isEmpty(flattenedContacts) || !isEmpty(multiSelectedContacts)) {
      return;
    }
    const index = contactIndex[selectedContact.id];
    const nextContact = flattenedContacts[index + 1];
    virtuosoRef.current?.scrollIntoView({
      index: index + 1,
      done: () => {
        if (nextContact) {
          if (onKeySelectContactItem) {
            onKeySelectContactItem(nextContact);
          } else if (!onClickContactItem) {
            router.push("/contacts/[[...slug]]", `/contacts/${nextContact.id}`, { shallow: true });
          }
        }
      },
    });
  }, [
    contactIndex,
    flattenedContacts,
    multiSelectedContacts,
    onClickContactItem,
    router,
    selectedContact,
  ]);

  const wrapperRef = useRef<HTMLElement>(null);
  const { isBodyFocused, isRefFocused } = useIsFocusIn(wrapperRef);

  useKey("ArrowUp", onUpNavigation, {
    when: isBodyFocused || isRefFocused || document.activeElement?.id === "search",
  });
  useKey("ArrowDown", onDownNavigation, {
    when: isBodyFocused || isRefFocused || document.activeElement?.id === "search",
  });

  return (
    <div
      className="relative flex-1 min-h-0 overflow-x-hidden overflow-y-auto h-full"
      ref={wrapperRef as RefObject<HTMLDivElement>}
    >
      {/* Alphabet list overlay */}
      <div className="absolute right-0 z-50 top-14">
        <AlphabetList alphabetKeys={alphabetKeys} onSelectAlphabetKey={onSelectAlphabetKey} />
      </div>

      {/* Main contact list */}
      <nav id="contacts" className="h-full" aria-label="Contacts">
        {ContactListComponent}
      </nav>
    </div>
  );
};

export default ContactList;
