import { Menu, Transition } from "@headlessui/react";
import classNames from "clsx";
import { generateGroupTagColors } from "helpers/group";
import useDarkMode from "hooks/useDarkMode";
import {
  ChangeEventHandler,
  FC,
  KeyboardEventHandler,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { usePopper } from "react-popper";

import Tag, { TagItem } from "./Tag";

type TagsInputProps = {
  tagItems: TagItem[];
  suggestions?: TagItem[];
  onClickTag?: (tagItem: TagItem) => void;
  onCreateTag: (name: string) => void;
  onAddTag: (tagId: string) => void;
  onRemoveTag: (tagId: string) => void;
  onInputStringChange?: (inputString: string) => void;
  forwardedRef?: MutableRefObject<HTMLInputElement | null>;
  className?: string;
};

const TagsInput: FC<TagsInputProps> = ({
  tagItems,
  suggestions,
  onClickTag,
  onCreateTag,
  onAddTag,
  onRemoveTag,
  onInputStringChange,
  forwardedRef,
  className,
}) => {
  const popperDivRef = useRef(null);
  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const [inputString, setInputString] = useState<string>("");
  const [showSuggestions, setShowSuggestions] = useState(false);

  const [isDarkMode] = useDarkMode();

  const filteredSuggestions =
    suggestions?.filter((suggestion) =>
      suggestion.name.toLowerCase().includes(inputString.toLowerCase())
    ) || [];

  const { styles, attributes, update } = usePopper(referenceElement, popperElement, {
    placement: "bottom-start",
    strategy: "fixed",
    modifiers: [{ name: "offset", options: { offset: [0, 4] } }],
  });

  useEffect(() => {
    onInputStringChange?.(inputString);
  }, [inputString]);

  const onInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setInputString(e.target.value);

    if (inputString.length > 0) {
      setShowSuggestions(true);
    }
  };

  const onInputKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
    // On enter
    if (e.key === "Enter") {
      // Prevent form submission if tag input is nested in <form>
      e.preventDefault();

      // If input is blank, do nothing
      if (inputString === "") {
        return;
      }

      // Add input to tag list
      onCreateTag(inputString);

      // Clear input
      setInputString("");

      // Update suggestions dropdown location
      update?.();
    }
  };

  return (
    <ul role="list" className="leading-8">
      {tagItems.map((tagItem, index) => {
        const { backgroundColor, foregroundColor } = generateGroupTagColors(
          tagItem.name,
          isDarkMode
        );
        return (
          <Tag
            key={index}
            tagItem={tagItem}
            onClickTag={onClickTag}
            enableRemoveButton={!!tagItem.id}
            onRemoveTag={() => onRemoveTag(tagItem.id)}
            backgroundColor={backgroundColor}
            foregroundColor={foregroundColor}
          />
        );
      })}
      <Menu as="div" className="relative inline-block">
        <OutsideClickHandler onOutsideClick={() => setShowSuggestions(false)}>
          <div key="input-wrapper" ref={(ref) => setReferenceElement(ref)}>
            <input
              key="input"
              value={inputString}
              placeholder={"Add a new group"}
              onChange={onInputChange}
              onKeyDown={onInputKeyDown}
              onFocus={() => setShowSuggestions(true)}
              ref={forwardedRef}
              className={classNames(
                "p-2 text-sm border-none appearance-none placeholder-zinc-400 dark:text-white dark:bg-black dark:placeholder-zinc-600 focus:ring-pink-500 focus:border-pink-500",
                className
              )}
            />
          </div>

          <div ref={popperDivRef} style={{ ...styles.popper, zIndex: 100 }} {...attributes.popper}>
            <Transition
              show={showSuggestions}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0"
              enterTo="transform opacity-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100"
              leaveTo="transform opacity-0"
              beforeEnter={() => setPopperElement(popperDivRef.current)}
              afterLeave={() => setPopperElement(null)}
            >
              <Menu.Items
                static
                className="z-50 overflow-auto rounded-md shadow-lg bg-primary divide-primary-y max-h-56 ring-primary focus:outline-none"
              >
                {filteredSuggestions.map((suggestion, index) => (
                  <Menu.Item key={index}>
                    <div
                      className="menu-item"
                      onClick={() => {
                        onAddTag(suggestion.id);
                        setInputString("");
                        setShowSuggestions(false);
                      }}
                    >
                      {suggestion.name}
                    </div>
                  </Menu.Item>
                ))}
              </Menu.Items>
            </Transition>
          </div>
        </OutsideClickHandler>
      </Menu>
    </ul>
  );
};

export default TagsInput;
