import { Menu, Transition } from "@headlessui/react";
import { selectAuthenticatedSession } from "@web/integrations/auth/selectors";
import { contactApi } from "@web/integrations/contact/api";
import { useAppDispatch } from "@web/integrations/redux/store";
import { useGetUserQuery } from "@web/integrations/user/api";
import { selectDelegatedUser } from "@web/integrations/user/selectors";
import { UserState } from "@web/integrations/user/types";
import { showToast, ToastLevel } from "components/core/Toast";
import contactSlice from "integrations/contact/slice";
import { isEmpty } from "lodash";
import { useRouter } from "next/router";
import { useCallback, useMemo, useRef, useState } from "react";
import { usePopper } from "react-popper";
import { useSelector } from "react-redux";

import userSlice from "../../integrations/user/slice";
import Avatar from "../core/Avatar";
import { MenuItem } from "../core/MenuItem";
import DarkModeButton from "../darkMode/DarkModeButton";
import SidebarUserDelegationsItem from "./SidebarUserDelegationsItem";

const SidebarProfileItem = () => {
  const dispatch = useAppDispatch();
  const router = useRouter();
  const { data: userProfileData } = useGetUserQuery();

  const session = useSelector(selectAuthenticatedSession);
  const delegatedUser = useSelector(selectDelegatedUser);

  const popperDivRef = useRef(null);
  const [referenceElement, setReferenceElement] = useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "top-start",
    strategy: "fixed",
    modifiers: [{ name: "offset", options: { offset: [10, 4] } }],
  });

  const [profileEmail, profileText, profilePhotoUrl] = useMemo(() => {
    let profileEmail;
    let profileText;
    let profilePhotoUrl;

    if (delegatedUser) {
      profileEmail = delegatedUser.profile?.email;
      profileText = delegatedUser.profile?.email;
      profilePhotoUrl = delegatedUser.profile?.photoUrl;
      if (delegatedUser.profile?.displayName) {
        profileText = delegatedUser.profile.displayName;
      }
      if (delegatedUser.profile?.givenName) {
        profileText = `${delegatedUser.profile?.givenName}${
          delegatedUser.profile?.surname ? ` ${delegatedUser.profile?.surname}` : ""
        }`;
      }
    } else {
      profileEmail = session?.email;
      profileText = session?.email;
      profilePhotoUrl = session?.photoUrl;
      if (session?.displayName) {
        profileText = session.displayName;
      }
    }

    return [profileEmail, profileText, profilePhotoUrl];
  }, [delegatedUser, session]);

  const onSelectDelegatedUser = useCallback(
    (delegatedUser: UserState["delegatedUser"]) => {
      dispatch(userSlice.actions.setCurrentDelegatedUser({ delegatedUser }));
      dispatch(contactSlice.actions.clearMultiSelectedContacts());
      dispatch(contactSlice.actions.clearSelectedGroups());
      dispatch(contactApi.util.resetApiState());
      router.replace("/contacts");

      showToast({
        title: `Switched acount to ${delegatedUser?.profile?.email || session?.email}`,
        level: ToastLevel.Success,
      });
    },
    [session]
  );

  const onSignOut = useCallback(async () => {
    router.push("/signout");
  }, []);

  return (
    <Menu as="div" className="relative w-full">
      {({ open }) => (
        <>
          <Menu.Button
            ref={setReferenceElement}
            tabIndex={-1}
            className="flex items-center w-full p-4 text-left border-primary-t hover:bg-zinc-200 dark:hover:bg-zinc-800 group"
          >
            <Avatar imageUrl={profilePhotoUrl || undefined} className="w-8 h-8" />
            <div className="flex-1 ml-3">
              <p className="mt-px text-sm font-medium text-zinc-700 dark:text-zinc-300 group-hover:text-zinc-800 dark:group-hover:text-zinc-200">
                {profileText}
              </p>
              <p className="text-xs font-medium text-zinc-400">View Account</p>
            </div>
          </Menu.Button>

          {/* Drop-down menu */}
          <div ref={popperDivRef} style={{ ...styles.popper, zIndex: 100 }} {...attributes.popper}>
            <Transition
              show={open}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
              beforeEnter={() => setPopperElement(popperDivRef.current)}
              afterLeave={() => setPopperElement(null)}
            >
              <Menu.Items
                static
                className="z-50 mt-2 origin-top-right rounded-md shadow-lg divide-primary-y bg-primary ring-primary focus:outline-none"
              >
                {session && (
                  <div className="px-4 py-3 text-sm text-zinc-700 dark:text-zinc-400">
                    <p className="leading-5">Signed in as</p>
                    <p className="font-medium leading-5 truncate text-zinc-900 dark:text-zinc-100">
                      {profileEmail}
                    </p>
                  </div>
                )}
                {!isEmpty(userProfileData?.userDelegations) && (
                  <div className="py-1" role="none">
                    <SidebarUserDelegationsItem
                      userDelegations={userProfileData?.userDelegations}
                      onSelectDelegatedUser={onSelectDelegatedUser}
                    />
                  </div>
                )}
                <div className="flex items-center justify-between px-4 py-3" role="none">
                  <p className="text-sm text-zinc-700 dark:text-zinc-400">Dark Mode</p>
                  <DarkModeButton />
                </div>
                <div className="py-1" role="none">
                  <MenuItem title="Settings" href="/settings/[slug]" as="/settings/account" />
                  <MenuItem title="Get Support" href="/support" />
                </div>
                <div className="py-1" role="none">
                  <MenuItem title="Sign Out" onClick={onSignOut} />
                </div>
              </Menu.Items>
            </Transition>
          </div>
        </>
      )}
    </Menu>
  );
};

export default SidebarProfileItem;
