import { Dialog } from "@headlessui/react";
import classNames from "clsx";
import React, { ButtonHTMLAttributes, FC, PropsWithChildren, useRef } from "react";
import { useKeys } from "rooks";

import Button, { ButtonVariant } from "./Button";
import { ExclamationCircleSolidIcon, QuestionCircleSolidIcon } from "./Icon";
import Modal from "./Modal";

export type AlertProps = {
  title: string;
  description?: string;
  variant: ButtonVariant;
  AlertIcon?: JSX.Element;
  isAlertOpen: boolean;
  onClose: (open: boolean) => void;
  actionButtonTitle?: string;
  actionHandler?: () => void;
  actionButtonProps?: ButtonHTMLAttributes<HTMLButtonElement>;
  cancelButtonTitle?: string;
  cancelHandler?: () => void;
  cancelButtonProps?: ButtonHTMLAttributes<HTMLButtonElement>;
  width?: "sm" | "md" | "lg" | "xl" | "3/5";
};

const Alert: FC<PropsWithChildren<AlertProps>> = ({
  title,
  description,
  variant,
  isAlertOpen,
  onClose,
  actionButtonTitle,
  actionHandler,
  actionButtonProps,
  cancelButtonTitle,
  cancelHandler,
  cancelButtonProps,
  children,
  AlertIcon,
  width = "lg",
}) => {
  const cancelButtonRef = useRef<HTMLButtonElement | null>(null);
  const actionButtonRef = useRef<HTMLButtonElement | null>(null);

  useKeys(["ArrowLeft", "ArrowRight"], () => {
    if (document.activeElement === cancelButtonRef.current) {
      actionButtonRef.current?.focus();
    } else {
      cancelButtonRef.current?.focus();
    }
  });

  const Icon = () => {
    let icon = AlertIcon;
    if (!icon) {
      switch (variant) {
        case ButtonVariant.Danger:
          icon = (
            <ExclamationCircleSolidIcon className="text-red-400 dark:text-red-600" size="lg" />
          );
          break;
        default:
          icon = <QuestionCircleSolidIcon className="text-zinc-400 dark:text-zinc-600" size="lg" />;
          break;
      }
    }
    return (
      <div className="mt-2 icon-container" aria-hidden="true">
        {icon}
      </div>
    );
  };

  return (
    <Modal
      initialFocusRef={cancelButtonRef}
      isModalOpen={isAlertOpen}
      setIsClosed={onClose}
      className={classNames("p-4 text-left align-middle sm:w-full sm:p-6", {
        "sm:max-w-xl": width === "xl",
        "sm:max-w-md": width === "md",
        "sm:max-w-lg": width === "lg",
        "sm:max-w-sm": width === "sm",
        "sm:w-3/5": width === "3/5",
      })}
    >
      <div className="sm:flex sm:items-start">
        {/* Icon */}
        <Icon />

        <div className="mt-4 text-center sm:mt-2 sm:ml-4 sm:text-left flex-grow">
          {/* Title */}
          <Dialog.Title
            as="h3"
            className="text-lg font-medium leading-6 text-zinc-900 dark:text-zinc-100"
          >
            {title}
          </Dialog.Title>

          {/* Description */}
          {description && (
            <div className="mt-1.5">
              <p className="text-sm text-zinc-500">{description}</p>
            </div>
          )}

          {/* Content */}
          {children && <div className="mt-1.5 w-full">{children} </div>}
        </div>
      </div>

      <div className="flex flex-row-reverse mt-8">
        {/* Action button (if specified) */}
        {actionButtonTitle && (
          <Button
            className="w-full mx-1 sm:mx-0 sm:w-auto sm:text-sm"
            variant={variant}
            onClick={() => {
              if (actionHandler) {
                actionHandler();
              }
              onClose(false);
            }}
            innerRef={actionButtonRef}
            {...actionButtonProps}
          >
            {actionButtonTitle || "Ok"}
          </Button>
        )}

        {/* Cancel button */}
        <Button
          className={classNames("w-full sm:w-auto sm:text-sm", {
            "mx-1 sm:mx-2": !!actionButtonTitle,
          })}
          variant={ButtonVariant.Secondary}
          onClick={() => {
            if (cancelHandler) {
              cancelHandler();
            }
            onClose(false);
          }}
          innerRef={cancelButtonRef}
          {...cancelButtonProps}
        >
          {cancelButtonTitle || "Cancel"}
        </Button>
      </div>
    </Modal>
  );
};

export default Alert;
