import { chunkList } from "@packages/common/array";
import { getCurEpochMs } from "@packages/common/dateTime";
import { ContactRow } from "@shared/models/Contact";
import { ContactGroup, ContactGroupRowForDisplay } from "@shared/models/ContactGroup";
import { IsDeleted } from "@shared/models/types";
import { selectSelectedGroups } from "integrations/contact/selectors";
import React, { ChangeEventHandler, useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useDebounce, useDebouncedCallback } from "use-debounce";

import ContactList from "../components/contacts/list";
import { getFullName } from "../helpers/contact";
import { selectAuthExplictlySignedOut } from "../integrations/auth/selectors";
import { useGetContactGroupsQuery } from "../integrations/contact/api";
import {
  Document,
  DocumentSearchOptions,
  SimpleDocumentSearchResultSetUnit,
} from "../types/flexsearch";
import {
  SortableKeys,
  useContacts,
  useLocalCachedSnapshot,
  useSortedContacts,
  useSortedContactsGroupedByLetter,
} from "./useContacts";

export type ContactWithGroups = ContactRow & { contactGroups: ContactGroup[] };

export type SearchableContact = ContactRow & { _fullName: string };

export type ContactSearchOptions = string[] | Partial<DocumentSearchOptions<boolean>>;

export let contactIndex: Document<SearchableContact> | undefined;

export let contactIndexStatus: {
  isReady: boolean;
  isIndexing: boolean;
  timestamp: number | undefined;
} = {
  isReady: false,
  isIndexing: false,
  timestamp: 0,
};

export function resetSearchIndex() {
  contactIndex = undefined;
  contactIndexStatus = {
    isReady: false,
    isIndexing: false,
    timestamp: 0,
  };
}

export async function updateSearchContacts(contacts: ContactRow[]) {
  contactIndexStatus = {
    ...contactIndexStatus,
    isIndexing: true,
    timestamp: getCurEpochMs(),
  };

  const chunks = chunkList(contacts, 500);
  for (const chunk of chunks) {
    await Promise.all(
      chunk.map((contact) => {
        if (contact.isDeleted === IsDeleted.YES) {
          return removeSearchContacts([contact.id]);
        }
        return contactIndex?.updateAsync(contact.id, {
          ...contact,
          _fullName: getFullName(contact),
        });
      })
    );
  }

  contactIndexStatus = {
    ...contactIndexStatus,
    isIndexing: false,
    timestamp: getCurEpochMs(),
    isReady: true,
  };
}

export async function removeSearchContacts(ids: string[]) {
  contactIndexStatus = {
    ...contactIndexStatus,
    isIndexing: true,
    timestamp: getCurEpochMs(),
  };
  await Promise.allSettled(ids.map((id) => contactIndex?.removeAsync(id)));
  contactIndexStatus = {
    ...contactIndexStatus,
    isIndexing: false,
    timestamp: getCurEpochMs(),
  };
}

export function useContactSearch(initQuery: string = "", options?: DocumentSearchOptions<boolean>) {
  const explicitlySignedOut = useSelector(selectAuthExplictlySignedOut);

  const createIndex = useCallback(() => {
    return new window.FlexSearch.Document<SearchableContact>({
      cache: 1000,
      async: true,
      worker: false,
      tokenize: "forward",
      document: {
        id: "id",
        index: options?.index || [
          { field: "nickname" },
          { field: "_fullName", tokenize: "full" },
          { field: "companyName" },
          { field: "departmentName" },
          { field: "notes", tokenize: "strict" },
          { field: "emails[]:value" },
          { field: "imHandles[]:value", tokenize: "strict" },
          { field: "phoneNumbers[]:value" },
          { field: "physicalAddresses[]:street" },
          { field: "physicalAddresses[]:line2" },
          { field: "physicalAddresses[]:city", tokenize: "strict" },
          { field: "physicalAddresses[]:postalCode", tokenize: "strict" },
          { field: "physicalAddresses[]:state", tokenize: "strict" },
          { field: "webPages[]:value" },
          { field: "webPages[]:service", tokenize: "strict" },
          // { field: "managerName" },
          // { field: "relatives[]:value" },
          // { field: "birthday" },
          // { field: "dates[]:value" },
        ],
      },
    });
  }, [options?.index]);

  const [query, setQuery] = useState(initQuery);
  const [result, setResult] = useState<SimpleDocumentSearchResultSetUnit[]>([]);

  useEffect(() => {
    if (explicitlySignedOut) {
      setQuery("");
      setResult([]);
      resetSearchIndex();
    }
  }, [explicitlySignedOut]);

  const { contactSnapshot, contactsUpdated, contactsPurged } = useLocalCachedSnapshot({});

  const doSearch = useDebouncedCallback(
    () => {
      console.log("searching...");
      if (contactIndex && query) {
        contactIndex.searchAsync(query).then(setResult);
      }
    },
    150,
    { leading: true }
  );

  useEffect(() => {
    console.log("updating search index...");
    if (contactIndex) {
      for (const updated of contactsUpdated || []) {
        contactIndex.update(updated.id, { ...updated, _fullName: getFullName(updated) });
      }
    }
    doSearch();
  }, [contactsUpdated, doSearch]);

  useEffect(() => {
    console.log("updating search index...");
    if (contactIndex) {
      for (const purged of contactsPurged || []) {
        contactIndex.remove(purged.contactId);
      }
      doSearch();
    }
  }, [contactsPurged, doSearch]);

  const queryOnChange: ChangeEventHandler<HTMLInputElement> = useCallback(({ target }) => {
    setQuery(target.value);
  }, []);

  const reindex = useDebouncedCallback(
    (contactList: ContactRow[]) => {
      contactIndex = undefined;
      contactIndex = createIndex();
      console.log(`create search index for ${contactList.length} contacts...`);
      updateSearchContacts(contactList).then(doSearch);
    },
    1000,
    { leading: true }
  );

  useEffect(() => {
    reindex(contactSnapshot?.contacts || []);
  }, [contactSnapshot]);

  useEffect(() => {
    doSearch();
  }, [query]);

  return {
    result,
    query,
    setQuery,
    queryOnChange,
    indexStatus: contactIndexStatus,
  };
}

export function useContactSearchBySortKey(initQuery: string = "", sortKey: SortableKeys) {
  const { contacts: allContacts, isLoaded, contactIndex } = useContacts();

  const { result, query, setQuery, queryOnChange } = useContactSearch(initQuery, {});

  const { data: groups } = useGetContactGroupsQuery();
  const selectedGroups = useSelector(selectSelectedGroups);
  const latestGroups = selectedGroups
    .map((selectedGroup) => groups?.find((group) => group.id === selectedGroup.id))
    .filter(Boolean) as ContactGroupRowForDisplay[];

  const contacts = useMemo(() => {
    let contacts = allContacts;
    if (latestGroups.length > 0 && contacts) {
      contacts = allContacts?.filter((contact) => {
        for (const group of latestGroups) {
          if (group.contactIds?.includes(contact.id) === true) {
            return true;
          }
        }
        return false;
      });
    }
    return contacts;
  }, [allContacts, latestGroups]);

  const { sortedContacts, sortPredicate } = useSortedContacts(contacts, sortKey);
  const { sortedContactsByLetter, shouldSortByLetter } = useSortedContactsGroupedByLetter(
    sortedContacts,
    sortKey
  );

  const sortedResult = useMemo(() => {
    if (!query) return sortedContactsByLetter;
    const contactIdList: { [id: string]: true } = {};
    for (const field of result) {
      for (const contactId of field.result) {
        contactIdList[contactId] = true;
      }
    }

    const resultContacts = contacts?.filter((contact) => contactIdList[contact.id]) || [];
    const sortedResultContacts = resultContacts.sort(sortPredicate);

    const contactsByLetter: { [letter: string]: ContactRow[] } = {};
    if (shouldSortByLetter) {
      for (const contact of sortedResultContacts) {
        const keyVal = contact[sortKey as "givenName" | "surname" | "nickname"] || "";
        const letter = keyVal.charAt(0).toUpperCase();
        if (!contactsByLetter[letter]) contactsByLetter[letter] = [];
        contactsByLetter[letter].push(contact);
      }
      for (const letter in contactsByLetter) {
        const group = contactsByLetter[letter];
        contactsByLetter[letter] = group.sort(sortPredicate);
      }
    }
    return {
      sortedContacts: shouldSortByLetter ? contactsByLetter : sortedResultContacts,
      count: contactIdList.length,
    };
  }, [query, result, shouldSortByLetter, sortKey, contacts]);

  return {
    isContactLoaded: isLoaded,
    sortedResult,
    sortedAllContacts: sortedContactsByLetter,
    query,
    setQuery,
    queryOnChange,
    contactIndex,
  };
}

export const ContactSearchQueryContext = React.createContext("");

export function useContactList(
  initQuery = "",
  sortKey: SortableKeys,
  selectedContact?: ContactRow | null
) {
  const {
    sortedResult: { sortedContacts: searchResult },
    sortedAllContacts: { sortedContacts: allContactsByLetter },
    query,
    setQuery,
    queryOnChange,
    isContactLoaded,
  } = useContactSearchBySortKey(initQuery, sortKey);

  const SearchedContactList = useMemo(() => {
    console.log("refresh search contact list...");
    if (searchResult)
      return (
        <ContactSearchQueryContext.Provider value={query}>
          <ContactList
            isLoaded={isContactLoaded}
            contacts={query ? searchResult : allContactsByLetter || []}
            sortKey={sortKey}
            selectedContact={selectedContact}
            showContactsCount
          />
        </ContactSearchQueryContext.Provider>
      );
  }, [isContactLoaded, query, searchResult, selectedContact, sortKey]);

  return {
    ContactList: SearchedContactList,
    allContactsByLetter,
    searchResult,
    query,
    setQuery,
    queryOnChange,
  };
}

let groupIndex: Document<ContactGroupRowForDisplay>;
let groupIndexStatus = { isIndexing: false, isReady: false };
export function useContactGroupSearch(initQuery: string = "") {
  useEffect(() => {
    groupIndex = new window.FlexSearch.Document<ContactGroupRowForDisplay>({
      cache: 100,
      worker: true,
      tokenize: "forward",
      document: {
        id: "id",
        index: [{ field: "name" }],
      },
    });
  }, []);

  const [query, setQuery] = useState(initQuery);
  const [result, setResult] = useState<SimpleDocumentSearchResultSetUnit[]>([]);

  const queryOnChange: ChangeEventHandler<HTMLInputElement> = useCallback(({ target }) => {
    setQuery(target.value);
  }, []);

  const [debouncedQuery] = useDebounce(query, 50);

  const { data: groups } = useGetContactGroupsQuery();

  useEffect(() => {
    if (groups) {
      groupIndexStatus = { ...groupIndexStatus, isIndexing: true };
      const promises = groups.map((group) => {
        return groupIndex.addAsync(group.id, group);
      });
      Promise.all(promises)
        .then(() => (groupIndexStatus = { isReady: true, isIndexing: false }))
        .catch(() => (groupIndexStatus = { isReady: false, isIndexing: false }));
    }
  }, [groups]);

  useEffect(() => {
    if (!debouncedQuery) {
      setResult([]);
      return;
    }
    if (groupIndexStatus.isReady && debouncedQuery) {
      groupIndex.searchAsync(debouncedQuery).then(setResult);
      return;
    }
  }, [debouncedQuery, groupIndexStatus.isReady]);

  return {
    result,
    query,
    queryOnChange,
    indexStatus: groupIndexStatus,
  };
}
