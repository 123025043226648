import { useGetUserQuery, useUpdateUserTimeZoneMutation } from "@web/integrations/user/api";
import { useCallback, useEffect } from "react";

export const useUpdateUserTimeZone = () => {
  const [updateUserTimeZone] = useUpdateUserTimeZoneMutation();
  const { data: me } = useGetUserQuery();

  const handler = useCallback(async () => {
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
    if (timeZone) {
      if (me?.user?.timeZone !== timeZone) await updateUserTimeZone(timeZone);
    }
  }, []);

  useEffect(() => {
    handler();

    window.addEventListener("focus", handler);

    return () => {
      window.removeEventListener("focus", handler);
    };
  }, []);
};
