import classNames from "clsx";
import { FC, PropsWithChildren } from "react";

export enum BannerVariant {
  Success,
  Warning,
  Danger,
}

type BannerProps = {
  variant: BannerVariant;
  onClickBanner?: () => void;
  className?: string;
};

const Banner: FC<PropsWithChildren<BannerProps>> = ({
  variant,
  onClickBanner,
  className,
  children,
}) => {
  let colorClassNames: string;
  switch (variant) {
    case BannerVariant.Success:
      colorClassNames =
        "text-green-700 dark:text-green-200 bg-green-100 dark:bg-green-900 hover:bg-green-200 dark:hover:bg-green-800 border";
      break;
    case BannerVariant.Warning:
      colorClassNames =
        "text-yellow-700 dark:text-yellow-200 bg-yellow-100 dark:bg-yellow-900 hover:bg-yellow-200 dark:hover:bg-yellow-800";
      break;
    case BannerVariant.Danger:
      colorClassNames =
        "text-red-700 dark:text-red-200 bg-red-100 dark:bg-red-900 hover:bg-red-200 dark:hover:bg-red-800";
      break;
  }

  return (
    <div
      className={classNames(
        "w-full py-2 text-sm font-semibold text-center",
        !!onClickBanner && "hover:cursor-pointer",
        colorClassNames,
        className
      )}
      onClick={onClickBanner}
    >
      {children}
    </div>
  );
};

export default Banner;
