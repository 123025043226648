import { ContactRow } from "@shared/models/Contact";
import { IsDoNotSync } from "@shared/models/types";
import { getDefaultPhoto } from "@web/helpers/contact";
import { SortableKeys } from "@web/hooks/useContacts";
import { ContactSearchQueryContext } from "@web/hooks/useSearch";
import classNames from "clsx";
import Link from "next/link";
import { FC, MouseEventHandler, useContext, useMemo } from "react";

import Avatar from "../../core/Avatar";
import { CloudXIcon } from "../../core/Icon";
import HighlightText from "../../HighlightText";

type ContactListItemProps = {
  contact: ContactRow;
  description?: string | JSX.Element;
  endIcon?: JSX.Element;
  sortKey: SortableKeys;
  isSelected: boolean;
  onClickItem?: MouseEventHandler<HTMLAnchorElement>;
};

const ContactListItem: FC<ContactListItemProps> = ({
  contact,
  description,
  endIcon,
  sortKey,
  isSelected,
  onClickItem,
}) => {
  const searchInput = useContext(ContactSearchQueryContext);

  const showCompanyNameOnly =
    !contact.givenName?.trim() && !contact.surname?.trim() && !!contact.companyName?.trim();

  const defaultPhoto = useMemo(() => {
    return getDefaultPhoto(contact.photos)?.value;
  }, [contact.photos]);

  const contactDescription = useMemo(() => {
    if (description) return description;

    if (sortKey === "givenName" || sortKey === "surname" || sortKey === "companyName")
      return (
        <>
          <p className="text-sm truncate text-label">
            <HighlightText value={contact.jobTitle} highlight={searchInput} />
            {contact.jobTitle && contact.departmentName && ` - `}
            {contact.departmentName && (
              <HighlightText value={contact.departmentName} highlight={searchInput} />
            )}
          </p>
          {!showCompanyNameOnly && (
            <p className="text-sm truncate text-label">
              <HighlightText value={contact.companyName} highlight={searchInput} />
            </p>
          )}
        </>
      );

    if (sortKey === "nickname")
      return (
        <p className="text-sm truncate text-label">
          <HighlightText value={contact.nickname} highlight={searchInput} />
        </p>
      );

    if (sortKey === "email")
      return (
        <p className="text-sm truncate text-label">
          <HighlightText value={contact.emails?.[0]?.value} highlight={searchInput} />
        </p>
      );
  }, [
    contact.companyName,
    contact.departmentName,
    contact.emails,
    contact.jobTitle,
    contact.nickname,
    description,
    searchInput,
    showCompanyNameOnly,
    sortKey,
  ]);

  return (
    <div
      className={classNames(
        "relative flex items-center px-6 py-5 space-x-3 focus-within:ring-2 focus-within:ring-inset focus-within:ring-purple-200 dark:focus-within:ring-purple-800",
        isSelected
          ? "bg-purple-100 dark:bg-zinc-800 hover:bg-purple-100 dark:hover:bg-zinc-800"
          : "hover:bg-zinc-50 dark:hover:bg-zinc-900"
      )}
    >
      <div className="flex-shrink-0">
        <Avatar
          className="text-lg h-12 w-12"
          firstName={contact.givenName}
          lastName={contact.surname}
          imageUrl={defaultPhoto}
        />
      </div>
      <div className="flex-1 min-w-0 truncate">
        <Link
          href={{ pathname: "/contacts/[[...slug]]", query: { slug: contact.id } }}
          as={`/contacts/${contact.id}`}
          shallow
          passHref
        >
          <a className="focus:outline-none" onClick={onClickItem}>
            {/* Extend touch target to entire panel */}
            <span className="absolute inset-0" aria-hidden="true" />

            {/* Primary name labels */}
            <p className="text-primary">
              <span className={classNames(sortKey === "givenName" && "font-bold")}>
                <HighlightText
                  value={contact.givenName}
                  highlight={searchInput}
                  matchPhrase={false}
                />
              </span>{" "}
              <span className={classNames(sortKey === "surname" && "font-bold")}>
                <HighlightText
                  value={contact.surname}
                  highlight={searchInput}
                  matchPhrase={false}
                />
              </span>
              {!contact.givenName?.trim() && !contact.surname?.trim() && (
                <span className="-mt-2 italic">
                  {showCompanyNameOnly ? (
                    <HighlightText value={contact.companyName} highlight={searchInput} />
                  ) : (
                    "No Name"
                  )}
                </span>
              )}
            </p>
            {contactDescription}
          </a>
        </Link>
      </div>
      <div className="text-gray-300">
        {endIcon && endIcon}
        {contact.isDoNotSync === IsDoNotSync.YES && <CloudXIcon size="lg" className="mr-3" />}
      </div>
    </div>
  );
};

export default ContactListItem;
