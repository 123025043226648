import classNames from "clsx";
import { FC, MouseEventHandler } from "react";
import { usePopperTooltip } from "react-popper-tooltip";

import { StarIcon, StarSolidIcon } from "../../../core/Icon";

type MarkDefaultItemButtonProps = {
  isDefault: boolean;
  onClickButton?: MouseEventHandler<HTMLButtonElement>;
  tooltip?: string;
  className?: string;
};

const MarkDefaultItemButton: FC<MarkDefaultItemButtonProps> = ({
  isDefault,
  onClickButton,
  tooltip,
  className,
}) => {
  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible } =
    usePopperTooltip();
  const Icon = isDefault ? StarSolidIcon : StarIcon;
  return (
    <>
      <button
        className={classNames("items-center justify-center w-8 h-9 -mt-px", className)}
        tabIndex={-1}
        onClick={onClickButton}
        ref={setTriggerRef}
      >
        <Icon className="cursor-pointer text-zinc-300 hover:text-zinc-400 dark:text-zinc-600 dark:hover:text-zinc-500" />
      </button>
      {visible && (
        <div ref={setTooltipRef} {...getTooltipProps({ className: "tooltip-container" })}>
          <div {...getArrowProps({ className: "tooltip-arrow" })} />
          {tooltip}
        </div>
      )}
    </>
  );
};

export default MarkDefaultItemButton;
