import { RemoteApiForDisplay } from "@shared/models/RemoteApi";
import { IsRemoteApiActive } from "@shared/models/types";
import { AppleIcon, GoogleIcon } from "@web/components/core/Icon";
import classNames from "clsx";
import { FC } from "react";

export type VendorIconProps = {
  integration?: RemoteApiForDisplay;
  vendor?: RemoteApiForDisplay["vendor"] | string;
  className?: string;
};

export const Monochrome: FC<VendorIconProps> = ({ integration, vendor, className }) => {
  const v = vendor || integration?.vendor;
  const cx = integration
    ? classNames(
        {
          "text-purple-700": integration.isActive === IsRemoteApiActive.YES,
          "text-gray-400": integration.isActive === IsRemoteApiActive.NO,
        },
        className
      )
    : undefined;

  if (v === "google") {
    return <GoogleIcon className={cx} size="2x" />;
  }
  if (v === "icloud") {
    return <AppleIcon className={cx} size="2x" />;
  }
  return null;
};

export const Color: FC<VendorIconProps> = ({ className, vendor, integration }) => {
  const v = vendor || integration?.vendor;

  if (v === "google") return <img className={className} src="/google-logo-icon.svg" alt="" />;
  if (v === "icloud") return <img className={className} src="/icloud-logo-icon.svg" alt="" />;
  if (v === "zapier") return <img className={className} src="/zapier-logo-icon.svg" alt="" />;
  return null;
};
