import classNames from "clsx";
import Link from "next/link";
import { useRouter } from "next/router";
import { FC, useMemo } from "react";

import { BaseRoute } from "../../helpers/routes";
import { useCurrentRoutes } from "../../hooks/useCurrentRoutes";
import { HomeSolidIcon } from "./Icon";

type BreadcrumbNavigationProps = {
  className?: string;
};

const BreadcrumbNavigation: FC<BreadcrumbNavigationProps> = ({ className }) => {
  const routes = useCurrentRoutes();
  const navigationItems = useMemo(() => {
    return (
      <ol role="list" className="flex items-center space-x-2">
        <li key="home">
          <div>
            <Link href="/" passHref>
              <a className="text-zinc-500 hover:text-zinc-700 dark:hover:text-zinc-300">
                <HomeSolidIcon className="flex-shrink-0 w-5 h-5" aria-hidden="true" />
                <span className="sr-only">Home</span>
              </a>
            </Link>
          </div>
        </li>
        {routes.map((route, index) => (
          <li key={index}>
            <BreadcrumbNavigationItem route={route} />
          </li>
        ))}
      </ol>
    );
  }, [routes]);

  return (
    <nav className={classNames("flex", className)} aria-label="Breadcrumb">
      {navigationItems}
    </nav>
  );
};

const BreadcrumbNavigationItem = ({ route }: { route: BaseRoute }) => {
  const router = useRouter();

  const isCurrent = route.isCurrent?.(router.pathname);
  const href = typeof route.href === "string" ? route.href : route.href();
  const as = route.as && route.as();

  return (
    <Link href={href} as={as} passHref>
      <div className="flex items-center">
        <svg
          className="flex-shrink-0 w-5 h-5 text-zinc-300 dark:text-zinc-700"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 20 20"
          aria-hidden="true"
        >
          <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
        </svg>
        <a
          className="ml-2 text-sm font-medium cursor-pointer text-zinc-500 hover:text-zinc-700 dark:hover:text-zinc-300"
          aria-current={isCurrent ? "page" : undefined}
        >
          {route.title}
        </a>
      </div>
    </Link>
  );
};

export default BreadcrumbNavigation;
