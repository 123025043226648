import type { RemoteApiForDisplay } from "@shared/models/RemoteApi";
import Alert from "@web/components/core/Alert";
import { ButtonVariant } from "@web/components/core/Button";
import { useDeleteIntegrationMutation } from "@web/integrations/user/api";
import { FC, useCallback } from "react";

const DeleteIntegrationModal: FC<{
  remoteApi: RemoteApiForDisplay | undefined;
  isOpen: boolean;
  onClose: () => void;
}> = ({ remoteApi, isOpen, onClose }) => {
  const [deleteIntegration] = useDeleteIntegrationMutation();
  const onDelete = useCallback(async () => {
    if (remoteApi) await deleteIntegration(remoteApi.id);
    onClose();
  }, [deleteIntegration, onClose, remoteApi]);

  return (
    <Alert
      title={`Delete ${remoteApi?.auth.username || ""}`}
      variant={ButtonVariant.Danger}
      isAlertOpen={isOpen}
      onClose={onClose}
      actionButtonTitle="Delete"
      actionHandler={onDelete}
      cancelHandler={onClose}
    >
      <span className="text-primary">
        Deleting this integration will stop it from syncing with TitleDock. Your contacts will
        remain.
      </span>
    </Alert>
  );
};

export default DeleteIntegrationModal;
