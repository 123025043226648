import { useContacts } from "@web/hooks/useContacts";
import { useRouter } from "next/router";
import { useMemo } from "react";

import { getFullName } from "../helpers/contact";
import { BaseRoute, ContactsRoute, getBaseRoute } from "../helpers/routes";
import { isValidUuid } from "../helpers/string";
import { parseQuery } from "../helpers/url";

export const useCurrentRoutes = () => {
  const router = useRouter();
  const { slug } = parseQuery(router.query);
  const baseRoute = getBaseRoute(router.pathname);

  // Grab contextual data
  const { contacts } = useContacts();
  return useMemo<BaseRoute[]>(() => {
    const result: BaseRoute[] = [baseRoute];

    if (baseRoute === ContactsRoute) {
      if (slug === "new") {
        result.push({
          title: "New",
          href: "/contacts/new",
        });
      } else if (contacts && contacts.length > 0) {
        const contact = isValidUuid(slug) && contacts.find((contact) => contact.id === slug);
        if (contact) {
          result.push({
            title: getFullName(contact),
            href: "/contacts/[slug]",
            as: () => `/contacts/${contact.id}`,
          });
        }
      }
    }

    return result;
  }, [baseRoute, contacts, slug]);
};
