import { getNumsFromString } from "@packages/common/string";
import type { ContactListType, ContactRow, ContactScalarType } from "@shared/models/Contact";
import { pickContactFields } from "@shared/models/helpers";
import type { PhysicalAddress } from "@shared/models/types";

import type { ArrElement } from "../types/utilTypes";

type ContactListKeys = Exclude<keyof ContactListType, "physicalAddresses" | "jobPositions">;

function getListValDiff(
  sourceContact: ContactRow,
  resultantContact: ContactRow,
  key: ContactListKeys
) {
  const resultantList = resultantContact[key];
  const diff: Partial<ContactRow> = {};
  const matched: Partial<ContactRow> = {};

  if (resultantList) {
    const sourceList = sourceContact[key];
    const sourceListIndex: { [val: string]: ArrElement<Exclude<typeof sourceList, undefined>> } =
      {};

    if (sourceList) {
      for (const i of sourceList) {
        if (i) {
          const val = key === "phoneNumbers" ? getNumsFromString(i.value) : i.value;
          sourceListIndex[val] = i;
        }
      }
      for (const i of resultantList) {
        const val = key === "phoneNumbers" ? getNumsFromString(i.value) : i.value;
        // if value in B does not exist in A
        if (!sourceListIndex[val]) {
          if (!diff[key]) {
            diff[key] = [i];
          } else {
            diff[key]?.push(i);
          }
        } else {
          if (!matched[key]) {
            matched[key] = [i];
          } else {
            matched[key]?.push(i);
          }
        }
      }
    }
  }

  return { diff, matched };
}

function getAddressString(address: PhysicalAddress) {
  return [
    address.street,
    address.line2,
    address.city,
    address.state,
    address.postalCode,
    address.country,
  ]
    .filter(Boolean)
    .join(",");
}

function getListAddressDiff(sourceContact: ContactRow, resultantContact: ContactRow) {
  const resultantAddresses = resultantContact.physicalAddresses;
  const diff: Partial<ContactRow> = {};
  const matched: Partial<ContactRow> = {};

  if (resultantAddresses) {
    const sourceAddresses = sourceContact.physicalAddresses;
    const sourceAddressesIndex: {
      [val: string]: ArrElement<Exclude<typeof sourceAddresses, undefined>>;
    } = {};

    if (sourceAddresses) {
      for (const i of sourceAddresses) {
        if (i.placeKey) {
          sourceAddressesIndex[i.placeKey] = i;
        }
        sourceAddressesIndex[getAddressString(i)] = i;
      }
      for (const i of resultantAddresses) {
        const val = i.placeKey || getAddressString(i);

        // if value in B does not exist in A
        if (!sourceAddressesIndex[val]) {
          if (!diff.physicalAddresses) {
            diff.physicalAddresses = [i];
          } else {
            diff.physicalAddresses?.push(i);
          }
        } else {
          if (!matched.physicalAddresses) {
            matched.physicalAddresses = [i];
          } else {
            matched.physicalAddresses?.push(i);
          }
        }
      }
    }
  }
  return { diff, matched };
}

function getScalarDiff(
  sourceContact: ContactRow,
  resultantContact: ContactRow,
  key: keyof ContactScalarType
) {
  const diff: Partial<ContactRow> = {};
  const matched: Partial<ContactRow> = {};

  const val = resultantContact[key];
  if (val) {
    if (sourceContact[key] === val) {
      matched[key] = val;
    } else {
      diff[key] = val;
    }
  }

  return { diff, matched };
}

export function getContactDiffForMerge(sourceContact: ContactRow, resultantContact: ContactRow) {
  let diff: Partial<ContactRow> = {};
  let matched: Partial<ContactRow> = {};
  for (const key in resultantContact) {
    switch (key as keyof ReturnType<typeof pickContactFields>) {
      case "emails":
      case "phoneNumbers":
      case "photos":
      case "imHandles":
      case "relatives":
      case "dates":
      case "webPages":
        const listValDiff = getListValDiff(sourceContact, resultantContact, key as ContactListKeys);
        diff = {
          ...diff,
          ...listValDiff.diff,
        };
        matched = {
          ...matched,
          ...listValDiff.matched,
        };
        break;

      case "physicalAddresses":
        const listAddressDiff = getListAddressDiff(sourceContact, resultantContact);
        diff = {
          ...diff,
          ...listAddressDiff.diff,
        };
        matched = {
          ...matched,
          ...listAddressDiff.matched,
        };
        break;

      case "givenName":
      case "middleName":
      case "surname":
      case "suffix":
      case "prefix":
      case "nickname":
      case "birthday":
      case "notes":
      case "companyName":
      case "jobTitle":
      case "managerName":
        const scalarDiff = getScalarDiff(
          sourceContact,
          resultantContact,
          key as keyof ContactScalarType
        );
        diff = {
          ...diff,
          ...scalarDiff.diff,
        };
        matched = {
          ...matched,
          ...scalarDiff.matched,
        };
    }
  }

  return { diff, matched };
}
