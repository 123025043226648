import { ContactRow } from "@shared/models/Contact";
import { usePreviewRows } from "@web/components/contacts/preview";
import ContactPreviewRow from "@web/components/contacts/preview/ContactPreviewRow";
import Avatar from "@web/components/core/Avatar";
import JobDescription from "@web/components/tools/deduplicate/JobDescription";
import { getFullName } from "@web/helpers/contact";
import { FC } from "react";

const MainContactToMerge: FC<{
  mainContact: ContactRow;
}> = ({ mainContact }) => {
  const previewRows = usePreviewRows(mainContact).filter((type) => {
    return type !== "fullName" && type !== "jobDescription";
  });

  return (
    <>
      <div className="flex justify-between items-center flex-wrap sm:flex-nowrap">
        <div className="flex items-center">
          <div className="flex-shrink-0">
            <Avatar firstName={mainContact.givenName} lastName={mainContact.surname} />
          </div>
          <div className="ml-4">
            <h3 className="text-lg leading-6 font-medium text-primary">
              {getFullName(mainContact)}
            </h3>
            <JobDescription contact={mainContact} />
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 pl-3 my-4 ">
        {previewRows.map((row, index) => (
          <ContactPreviewRow key={index} text={row.text} label={row.label} icon={row.icon} />
        ))}
      </div>
    </>
  );
};

export default MainContactToMerge;
