import classNames from "clsx";
import React, { FC, PropsWithChildren } from "react";

import Button from "./Button";

type HeaderProps = {
  id?: string;
  title?: string;
  details?: string | JSX.Element;
  actionButtonTitle?: string;
  actionButtonOnClickHandler?: () => void;
  className?: string;
};

const Header: FC<PropsWithChildren<HeaderProps>> = ({
  id,
  title,
  details,
  actionButtonTitle,
  actionButtonOnClickHandler,
  className,
  children,
}) => {
  return (
    <div
      className={classNames(
        "flex items-center justify-between flex-wrap sm:flex-nowrap",
        className
      )}
    >
      {/* Text header */}
      <div className="sm:flex-auto">
        <h3 id={id} className="text-lg font-medium leading-6 text-zinc-900 dark:text-zinc-100">
          {title}
        </h3>
        {details && <p className="mt-1 text-sm text-zinc-500 dark:text-zinc-400">{details}</p>}
      </div>

      <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
        {/* Action item */}
        {actionButtonTitle && (
          <Button onClick={actionButtonOnClickHandler} className="mt-4 sm:mt-0">
            {actionButtonTitle}
          </Button>
        )}
        {children}
      </div>
    </div>
  );
};

export default Header;
