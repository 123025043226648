import classNames from "clsx";
import { formatDistance } from "date-fns";
import { FC, useMemo } from "react";

import { ContactVersion } from "../../../../../services/src/shared/models/ContactVersion";
import { getHumanReadableLastUpdatedBy } from "../../../../helpers/contact";
import { ContactVersionOperationGroups } from "../../../../helpers/operation";
import { AppleIcon, HistorySolidIcon, IconType, UserSolidIcon } from "../../../core/Icon";
import MenuButton, { MenuButtonMenuItem } from "../../../core/MenuButton";
import OperationGroupRow from "./OperationGroupRow";

type ContactVersionRowProps = {
  versionOperationGroups: ContactVersionOperationGroups;
  showSpine: boolean;
  showUndoOption: boolean;
  showRestoreOption: boolean;
  onClickUndo: (version: ContactVersion) => void;
  onClickRestore: (version: ContactVersion) => void;
};

const ContactVersionRow: FC<ContactVersionRowProps> = ({
  versionOperationGroups: { version, groups },
  showSpine,
  showUndoOption,
  showRestoreOption,
  onClickUndo,
  onClickRestore,
}) => {
  const lastUpdatedBy = version.lastUpdatedBy;
  const createdAt = version.createdAt;

  // Resolve icon
  let backgroundColor: string;
  let Icon: IconType;
  if (lastUpdatedBy === "icloud") {
    backgroundColor = "bg-zinc-400";
    Icon = AppleIcon;
  } else if (lastUpdatedBy === "user") {
    backgroundColor = "bg-green-400";
    Icon = UserSolidIcon;
  } else {
    backgroundColor = "bg-zinc-400";
    Icon = UserSolidIcon;
  }

  // Resolve title + relative date
  let actionText: string;
  const updatedByText = getHumanReadableLastUpdatedBy(version, true);
  if (version.isNew) {
    actionText = "Added";
  } else if (version.isDeleted) {
    actionText = "Deleted";
  } else {
    actionText = "Updated";
  }

  // Create undo menu button items
  const undoButtonMenuItems: MenuButtonMenuItem[] = useMemo(() => {
    const menuItems = [];
    if (showUndoOption) {
      menuItems.push({
        id: "undo",
        title: "Undo this change",
        onClickHandler: () => onClickUndo(version),
      });
    }
    if (showRestoreOption) {
      menuItems.push({
        id: "restore",
        title: "Restore contact",
        onClickHandler: () => onClickRestore(version),
      });
    }
    return menuItems;
  }, [version, showUndoOption, showRestoreOption]);

  return (
    <li>
      <div className="relative pb-8">
        {/* Vertical spine */}
        {showSpine ? (
          <span
            className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-zinc-200 dark:bg-zinc-700"
            aria-hidden="true"
          />
        ) : null}

        <div className="relative flex space-x-3">
          {/* Icon */}
          <div>
            <span
              className={classNames(
                backgroundColor,
                "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white dark:ring-black"
              )}
            >
              <Icon className="text-white" aria-hidden="true" />
            </span>
          </div>

          {/* Main content */}
          <div className="flex justify-between flex-1 min-w-0 space-x-4">
            <div className="pt-1.5">
              {/* Change header */}
              <p className="text-sm text-primary">
                <strong>
                  {actionText} {updatedByText}
                </strong>{" "}
                {formatDistance(createdAt, new Date(), { addSuffix: true, includeSeconds: true })}
              </p>

              {/* List of operation groups */}
              <ul className="py-2">
                {groups.map((group, index) => {
                  return (
                    <OperationGroupRow
                      key={index}
                      group={group}
                      curContact={version.curContact}
                      prevContact={version.prevContact}
                    />
                  );
                })}
              </ul>
            </div>
            {(showUndoOption || showRestoreOption) && (
              <div className="pt-1 space-x-1 text-sm text-right text-label whitespace-nowrap">
                <MenuButton menuItems={undoButtonMenuItems} small>
                  <HistorySolidIcon />
                  <span className="hidden pt-px ml-1 sm:block">Undo</span>
                </MenuButton>
              </div>
            )}
          </div>
        </div>
      </div>
    </li>
  );
};

export default ContactVersionRow;
