import { Dialog, Transition } from "@headlessui/react";
import classNames from "clsx";
import React, { FC, Fragment, MutableRefObject, ReactNode } from "react";

type ModalProps = {
  isModalOpen: boolean;
  setIsClosed: (open: boolean) => void;
  initialFocusRef?: MutableRefObject<HTMLElement | null>;
  className?: string;
  children?: ReactNode;
};

const Modal: FC<ModalProps> = ({
  isModalOpen,
  setIsClosed,
  initialFocusRef,
  className,
  children,
}) => {
  return (
    <Transition.Root show={isModalOpen} as={Fragment}>
      <Dialog
        as="div"
        static
        initialFocus={initialFocusRef}
        open={isModalOpen}
        onClose={setIsClosed}
        className="fixed inset-0 z-10 overflow-y-auto"
      >
        <div className="justify-center block min-h-screen p-4 text-center sm:p-0">
          {/* Background overlay */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 transition-opacity bg-overlay" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>

          {/* Main content */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className={classNames(
                "inline-block align-middle bg-primary rounded-lg shadow-xl transform transition-all",
                className
              )}
            >
              {children}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Modal;
