import { stringToHslColor } from "@web/helpers/color";
import classNames from "clsx";
import { FC, useState } from "react";

import useDarkMode from "../../hooks/useDarkMode";

type AvatarProps = {
  displayName?: string;
  firstName?: string;
  lastName?: string;
  imageUrl?: string;
  className?: string;
};

const Avatar: FC<AvatarProps> = ({
  displayName,
  firstName,
  lastName,
  imageUrl,
  className = "text-xl h-14 w-14",
}) => {
  const [isDarkMode] = useDarkMode();
  const [disableImage, setDisableImage] = useState(false);

  if (imageUrl && !disableImage) {
    return (
      <img
        className={classNames("inline-block rounded-full hidden", className)}
        onLoad={(event) => ((event.target as HTMLElement).style.display = "inline-block")}
        onError={() => setDisableImage(true)}
        src={imageUrl}
        alt="profile avatar"
      />
    );
  }

  let initials: string;
  let fullName: string;
  if (displayName) {
    initials = displayName
      .split(" ")
      .map((i) => i?.charAt(0) || "")
      .join("");
    fullName = displayName;
  } else {
    initials = (firstName?.charAt(0) || "") + (lastName?.charAt(0) || "");
    fullName = [firstName, lastName].join(" ");
  }

  const backgroundHslColor = fullName
    ? stringToHslColor(fullName, isDarkMode ? 20 : 30, isDarkMode ? 30 : 70)
    : "rgb(209, 213, 219)";

  return initials ? (
    <span
      className={classNames(
        "inline-flex items-center justify-center rounded-full font-medium leading-none text-white uppercase",
        className
      )}
      style={{ backgroundColor: backgroundHslColor }}
    >
      {initials}
    </span>
  ) : (
    <span
      className={classNames(
        "inline-block rounded-full overflow-hidden bg-zinc-100 dark:bg-zinc-800",
        className
      )}
    >
      <svg className="w-full h-full text-zinc-300" fill="currentColor" viewBox="0 0 24 24">
        <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
      </svg>
    </span>
  );
};

export default Avatar;
