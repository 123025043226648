import classNames from "clsx";
import { FC } from "react";

export enum ContactPreviewRowVariant {
  Default,
  Additive,
  Subtractive,
}

export type ContactPreviewRowProps = {
  text?: string | JSX.Element;
  label?: string;
  icon?: JSX.Element;
  Icon?: React.ElementType;
  variant?: ContactPreviewRowVariant;
  className?: string;
  type?:
    | "fullName"
    | "jobDescription"
    | "email"
    | "imHandle"
    | "phoneNumber"
    | "physicalAddress"
    | "webPage"
    | "relative"
    | "birthday"
    | "date"
    | "notes";
};

const ContactPreviewRow: FC<ContactPreviewRowProps> = ({
  text,
  label,
  icon,
  Icon,
  variant = ContactPreviewRowVariant.Default,
  className,
}) => {
  let colorClassNames: string;
  // switch (variant) {
  //   case ContactPreviewRowVariant.Default:
  //     colorClassNames = "text-zinc-700 dark:text-zinc-300";
  //     break;
  //   case ContactPreviewRowVariant.Additive:
  //     colorClassNames = "text-green-700 dark:text-green-200 bg-green-100 dark:bg-green-900";
  //     break;
  //   case ContactPreviewRowVariant.Subtractive:
  //     colorClassNames = "text-red-700 dark:text-red-200 bg-red-100 dark:bg-red-900";
  //     break;
  // }

  switch (variant) {
    case ContactPreviewRowVariant.Default:
      colorClassNames = "text-zinc-700 dark:text-zinc-300";
      break;
    case ContactPreviewRowVariant.Additive:
      colorClassNames = "text-green-700 dark:text-green-200";
      break;
    case ContactPreviewRowVariant.Subtractive:
      colorClassNames = "text-red-700 dark:text-red-200";
      break;
  }

  return (
    <div className={classNames("flex flex-row w-full space-x-1", colorClassNames, className)}>
      <div className="w-5">
        {icon}
        {Icon && <Icon />}
      </div>
      <p className="w-full mt-1 text-sm text-left -pt-px">
        {text} <span className="opacity-50">{label}</span>
      </p>
    </div>
  );
};

export default ContactPreviewRow;
