import { prettyPrintArbitraryDateString } from "@web/helpers/date";
import { FC } from "react";

import { BirthdayCakeIcon } from "../../../core/Icon";
import SimpleDateInput from "../../../core/SimpleDateInput";
import BaseDetailsField from "../shared/BaseDetailsField";
import TypeLabel from "../shared/TypeLabel";
import { ContactDataFieldProps } from "../types";

const BirthdayField: FC<ContactDataFieldProps> = ({ contactData, dispatch, isEditing }) => {
  const birthday = contactData.birthday || "";
  if (!isEditing && !birthday) {
    return null;
  }

  return (
    <BaseDetailsField
      label="Birthday"
      isEditing={isEditing}
      icon={<BirthdayCakeIcon size="lg" className="icon-color-purple" />}
    >
      {isEditing ? (
        <dd key="givenName" className="flex flex-row min-w-0">
          <TypeLabel>Birthday</TypeLabel>
          <div className="flex-1">
            <SimpleDateInput
              name="birthday"
              className="mr-20"
              initialDateString={birthday}
              onChangeDateString={(dateString) => {
                dispatch({ type: "birthday", payload: dateString });
              }}
            />
          </div>
        </dd>
      ) : (
        <dd className="text-primary">{prettyPrintArbitraryDateString(birthday)}</dd>
      )}
    </BaseDetailsField>
  );
};

export default BirthdayField;
