import { ContactRow } from "@shared/models/Contact";
import { Dispatch } from "react";

import { ContextualMenuFieldValue } from "./shared/ContextualMenuField";

export type UpdateContactDataAction = {
  type: keyof ContactRow | "RESET";
  payload: any;
};

export type ContactDataFieldProps = {
  contactData: Partial<ContactRow>;
  dispatch: Dispatch<UpdateContactDataAction>;
  isEditing: boolean;
  directlyUpdateContactData?: (dataToUpdate: Partial<ContactRow>) => Promise<void>;
  focusedField?: ContextualMenuFieldValue<any> | null;
  onFocusField?: (field: ContextualMenuFieldValue<any>) => void;
};

export enum SocialProfileType {
  Apple = "apple",
  Facebook = "facebook",
  Flickr = "flickr",
  Generic = "",
  Instagram = "instagram",
  LinkedIn = "linkedin",
  Myspace = "myspace",
  TikTok = "tiktok",
  Twitter = "twitter",
  Wechat = "wechat",
  Weibo = "weibo",
  Youtube = "youtube",
}
