import { sanitizeContactData } from "@shared/helpers/contact";
import { ContactRow } from "@shared/models/Contact";
import { ContactGroupRowForDisplay } from "@shared/models/ContactGroup";
import { ContactSyncConflict } from "@shared/models/ContactSyncConflict";
import { ContactVersion } from "@shared/models/ContactVersion";
import { isEmpty } from "@web/helpers/array";
import { inverse } from "@web/helpers/operation";
import usePrevious from "@web/hooks/usePrevious";
import {
  useCreateContactGroupMutation,
  useDeleteContactMutation,
  useGetConflictQuery,
  useResolveConflictMutation,
  useUpdateContactGroupMutation,
  useUpdateContactMutation,
} from "@web/integrations/contact/api";
import classNames from "clsx";
import { applyPatch, deepClone, Operation } from "fast-json-patch";
import { useRouter } from "next/router";
import { FC, useEffect, useMemo, useReducer, useState } from "react";
import { useKey } from "rooks";

import Alert from "../../core/Alert";
import Banner, { BannerVariant } from "../../core/Banner";
import { ButtonVariant } from "../../core/Button";
import { showToast, ToastLevel } from "../../core/Toast";
import ArbitraryDatesField from "./fields/ArbitraryDatesField";
import BirthdayField from "./fields/BirthdayField";
import EmailsField from "./fields/EmailsField";
import GroupsField from "./fields/GroupsField";
import ImHandlesField from "./fields/ImHandlesField";
import JobsField from "./fields/JobsField";
import NamesField from "./fields/NamesField";
import NotesField from "./fields/NotesField";
import PhoneNumbersField from "./fields/PhoneNumbersField";
import PhysicalAddressesField from "./fields/PhysicalAddressesField";
import RelativesField from "./fields/RelativesField";
import SocialProfilesField from "./fields/SocialProfilesField";
import SyncToggleField from "./fields/SyncToggleField";
import WebPagesField from "./fields/WebPagesField";
import ContactDetailsHeader from "./header/ContactDetailsHeader";
import ContactHistoryDrawer from "./history/ContactHistoryDrawer";
import { ContextualMenuFieldValue } from "./shared/ContextualMenuField";
import ResolveSyncConflictAlert from "./shared/ResolveSyncConflictAlert";
import { UpdateContactDataAction } from "./types";

type ContactDetailsProps = {
  contact: ContactRow;
  contacts?: ContactRow[];
  groups?: ContactGroupRowForDisplay[];
  editOnly?: boolean;
  redirectToCDP?: boolean;
  onSave?: () => void;
  onCancel?: () => void;
};

const initializer = (initialState: ContactRow) => initialState;

const ContactDetails: FC<ContactDetailsProps> = ({
  contact,
  contacts,
  groups,
  editOnly = false,
  redirectToCDP = true,
  onSave,
  onCancel,
}) => {
  const router = useRouter();
  const { slug } = router.query;

  // Fetch conflicts for this contact id
  const { data: conflicts } = useGetConflictQuery(contact.id);
  const [resolveConflict] = useResolveConflictMutation();

  // State vars
  const [isEditing, setIsEditing] = useState(editOnly);
  const [isHistoryDrawerOpen, setIsHistoryDrawerOpen] = useState(false);
  const [isDeleteContactAlertOpen, setIsDeleteContactAlertOpen] = useState(false);
  const [focusedField, setFocusedField] = useState<ContextualMenuFieldValue<any> | null>(null);

  const [focusedSyncConflict, setFocusedSyncConflict] = useState<ContactSyncConflict | null>(null);
  const [isSyncConflictAlertOpen, setIsSyncConflictAlertOpen] = useState(false);

  const [updateContact, { isLoading: isUpdatingContact }] = useUpdateContactMutation();
  const [deleteContact] = useDeleteContactMutation();
  const [createContactGroup] = useCreateContactGroupMutation();
  const [updateContactGroup] = useUpdateContactGroupMutation();

  useKey(["Backspace", "Delete"], () => {
    if (
      !["input", "textarea"].includes(String(document.activeElement?.tagName || "").toLowerCase())
    ) {
      setIsDeleteContactAlertOpen(true);
    }
  });
  // Use reducer to hold updated contact data
  const contactDataReducer = (state: ContactRow, action: UpdateContactDataAction): ContactRow => {
    if (action.type === "RESET") {
      return initializer(action.payload);
    }
    return { ...state, [action.type]: action.payload };
  };

  const [contactEditBuffer, dispatch] = useReducer(
    contactDataReducer,
    {
      ...contact,
    },
    initializer
  );

  const contactData = isEditing ? contactEditBuffer : contact;

  // Reset state vars on contact change
  const previousContact = usePrevious<ContactRow>(contact);
  useEffect(() => {
    if (previousContact?.id && contact.id !== previousContact?.id) {
      dispatch({ type: "RESET", payload: contact });
      if (!editOnly) setIsEditing(false);
    }
  }, [contact, previousContact?.id]);

  const onFocusField = (field: ContextualMenuFieldValue<any>) => {
    setIsEditing(true);
    setFocusedField(field);
  };

  const onClickEdit = () => {
    setIsEditing(true);
    setFocusedField(null);
  };

  const onClickSave = async () => {
    // Sanitize + update held contact data
    const { validContact: sanitizedContact } = sanitizeContactData(contactData);

    dispatch({ type: "RESET", payload: sanitizedContact });
    if (!editOnly) setIsEditing(false);

    if (onSave) onSave();
    try {
      await updateContact(sanitizedContact);
      if (slug !== sanitizedContact.id && redirectToCDP) {
        router.push("/contacts/[[...slug]]", `/contacts/${sanitizedContact.id}`, { shallow: true });
      }
      showToast({
        title: `Changes to ${sanitizedContact.givenName} ${sanitizedContact.surname} has been saved.`,
        level: ToastLevel.Success,
      });
    } catch (error) {
      console.error("Encountered error updating contact data. Error: ", error);
      showToast({
        title: `Failed to update ${sanitizedContact.givenName} ${sanitizedContact.surname}`,
        level: ToastLevel.Warning,
      });
    }
  };

  const onClickCancel = () => {
    dispatch({ type: "RESET", payload: contact });
    if (!editOnly) setIsEditing(false);
    if (onCancel) onCancel();
  };

  const onClickDeleteContact = async () => {
    try {
      await deleteContact(contact.id);
      showToast({
        title: `Successfully deleted ${contact.givenName} ${contact.surname}.`,
        level: ToastLevel.Success,
      });

      // Route user to contact list page
      router.push("/contacts");
    } catch (error) {
      console.error("Encountered error deleting contact data. Error: ", error);
      showToast({
        title: `Failed to delete ${contact.givenName} ${contact.surname}`,
        level: ToastLevel.Warning,
      });
    } finally {
      setIsEditing(false);
    }
  };

  const undoVersion = async (version: ContactVersion | null) => {
    const diff = version?.contactDiff;
    if (diff) {
      // Deep clone current contact data
      const updatedContact: ContactRow = deepClone(contactData);

      // Calculate inverse operation pairs and apply them, collect failed operations
      const inversePatches = inverse(diff, version.prevContact);
      const failedPatches: Operation[][] = [];
      inversePatches.forEach((patch) => {
        try {
          applyPatch(updatedContact, patch, true, true);
        } catch (error) {
          failedPatches.push(patch);
          console.log(
            `Error applying inverse operation pair: ${JSON.stringify(patch)}. Error: `,
            error
          );
        }
      });

      // TODO: Ask user if we should force failed patches through + implement logic for forcing
      // patches.
      if (!isEmpty(failedPatches)) {
        console.log("Failed patches: ", JSON.stringify(failedPatches));
      }

      try {
        await updateContact(updatedContact);
      } catch (error) {
        console.error("Encountered error reverting changes. Error: ", error);
        showToast({ title: "Failed to revert changes." });
        return;
      }
      showToast({ title: "Successfully reverted changes!" });
      dispatch({ type: "RESET", payload: updatedContact });
      setIsHistoryDrawerOpen(false);
    }
  };

  const rollbackToVersion = async (version: ContactVersion | null) => {
    const updatedContact = version?.curContact;
    if (updatedContact) {
      try {
        await updateContact(updatedContact);
      } catch (error) {
        console.error("Encountered error rolling back contact data. Error: ", error);
        showToast({ title: "Failed to rollback contact data." });
        return;
      }
      showToast({ title: "Successfully rolled back!" });
      dispatch({ type: "RESET", payload: updatedContact });
      setIsHistoryDrawerOpen(false);
    }
  };

  const createGroup = async (group: Partial<ContactGroupRowForDisplay>) => {
    await createContactGroup(group);
  };

  const addContactToGroup = async (contactId: string, group: ContactGroupRowForDisplay) => {
    await updateContactGroup({
      ...group,
      contactIds: [...(group.contactIds || []), contactId],
    });
  };

  const removeContactFromGroup = async (contactId: string, group: ContactGroupRowForDisplay) => {
    await updateContactGroup({
      ...group,
      contactIds: group.contactIds?.filter((id) => id !== contactId) || [],
    });
  };

  /**
   * Helper function to directly update contact data, mostly used by fields from read mode. For
   * example, notes field or mark / unmark default.
   *
   * @param dataToUpdate Contact data to upate
   */
  const directlyUpdateContactData = async (dataToUpdate: Partial<ContactRow>) => {
    const updatedContactData: ContactRow = {
      ...contactData,
      ...dataToUpdate,
    };

    try {
      await updateContact(updatedContactData);
    } catch (error) {
      console.error("Encountered error directly updating contact data. Error: ", error);
    }
  };

  const onResolveSyncConflict = async (resolvedContact: ContactRow) => {
    setIsSyncConflictAlertOpen(false);
    await resolveConflict(resolvedContact);
  };

  // Contact data fields
  const Fields = useMemo(
    () => ({
      sync: <SyncToggleField contactData={contactData} dispatch={dispatch} isEditing={isEditing} />,
      names: (
        <NamesField
          key="names"
          contactData={contactData}
          dispatch={dispatch}
          isEditing={isEditing}
        />
      ),
      jobs: (
        <JobsField key="jobs" contactData={contactData} dispatch={dispatch} isEditing={isEditing} />
      ),
      emails: (
        <EmailsField
          key="emails"
          contactData={contactData}
          dispatch={dispatch}
          isEditing={isEditing}
          focusedField={focusedField}
          onFocusField={onFocusField}
          directlyUpdateContactData={directlyUpdateContactData}
        />
      ),
      phoneNumbers: (
        <PhoneNumbersField
          key="phoneNumbers"
          contactData={contactData}
          dispatch={dispatch}
          isEditing={isEditing}
          focusedField={focusedField}
          onFocusField={onFocusField}
          directlyUpdateContactData={directlyUpdateContactData}
        />
      ),
      physicalAddresses: (
        <PhysicalAddressesField
          key="physicalAddresses"
          contactData={contactData}
          dispatch={dispatch}
          isEditing={isEditing}
          focusedField={focusedField}
          onFocusField={onFocusField}
          directlyUpdateContactData={directlyUpdateContactData}
        />
      ),
      imHandles: (
        <ImHandlesField
          key="imHandles"
          contactData={contactData}
          dispatch={dispatch}
          isEditing={isEditing}
          focusedField={focusedField}
          onFocusField={onFocusField}
          directlyUpdateContactData={directlyUpdateContactData}
        />
      ),
      socialProfiles: (
        <SocialProfilesField
          key="socialProfiles"
          contactData={contactData}
          dispatch={dispatch}
          isEditing={isEditing}
          focusedField={focusedField}
          onFocusField={onFocusField}
          directlyUpdateContactData={directlyUpdateContactData}
        />
      ),
      webPages: (
        <WebPagesField
          key="webPages"
          contactData={contactData}
          dispatch={dispatch}
          isEditing={isEditing}
          focusedField={focusedField}
          onFocusField={onFocusField}
          directlyUpdateContactData={directlyUpdateContactData}
        />
      ),
      dates: (
        <ArbitraryDatesField
          key="dates"
          contactData={contactData}
          dispatch={dispatch}
          isEditing={isEditing}
          focusedField={focusedField}
          onFocusField={onFocusField}
        />
      ),
      relatives: (
        <RelativesField
          key="relatives"
          contactData={contactData}
          dispatch={dispatch}
          isEditing={isEditing}
          focusedField={focusedField}
          onFocusField={onFocusField}
          contacts={contacts}
        />
      ),
      notes: (
        <NotesField
          key="notes"
          contactData={contactData}
          dispatch={dispatch}
          isEditing={isEditing}
          directlyUpdateContactData={directlyUpdateContactData}
        />
      ),
      birthday: (
        <BirthdayField
          key="birthday"
          contactData={contactData}
          dispatch={dispatch}
          isEditing={isEditing}
        />
      ),
      groups: (
        <GroupsField
          key="groups"
          contactData={contactData}
          dispatch={dispatch}
          isEditing={isEditing}
          groups={groups || []}
          createGroup={createGroup}
          addContactToGroup={addContactToGroup}
          removeContactFromGroup={removeContactFromGroup}
        />
      ),
    }),
    [contactData, groups, isEditing, focusedField]
  );

  return (
    <>
      {/* Conflict banner */}
      {conflicts?.map((conflict, index) => {
        return (
          <Banner
            key={index}
            variant={BannerVariant.Warning}
            onClickBanner={() => {
              setFocusedSyncConflict(conflict);
              setIsSyncConflictAlertOpen(true);
            }}
            className="mt-0 lg:mt-6 xl:mt-0"
          >
            We&apos;ve found unsynced changes to this contact. Click to resolve.
          </Banner>
        );
      })}

      <div className="w-full">
        {/* Header with avatar, name, and profession */}
        <ContactDetailsHeader
          contactData={contactData}
          isEditing={isEditing}
          isUpdatingContact={isUpdatingContact}
          onClickEdit={onClickEdit}
          onClickSave={onClickSave}
          onClickCancel={onClickCancel}
          onClickShowHistory={() => setIsHistoryDrawerOpen(true)}
          onClickDeleteContact={() => setIsDeleteContactAlertOpen(true)}
        />

        {/* data fields */}
        <div
          className={classNames(
            "flex flex-col px-4 pb-8 sm:px-6 lg:px-8",
            isEditing ? "space-y-6 divide-primary-y -mt-6" : "mt-4 sm:flex-row max-w-5xl"
          )}
        >
          {isEditing ? (
            <>
              {/* Edit mode */}
              {Fields.names}
              {Fields.jobs}
              {Fields.emails}
              {Fields.phoneNumbers}
              {Fields.physicalAddresses}
              {Fields.socialProfiles}
              {Fields.webPages}
              {Fields.imHandles}
              {Fields.relatives}
              {Fields.birthday}
              {Fields.dates}
              {Fields.notes}
              {Fields.groups}
              {Fields.sync}
            </>
          ) : (
            <>
              {/* Left column */}
              <dl className="flex-1">
                {isEditing && (
                  <>
                    {Fields.names}
                    {Fields.jobs}
                  </>
                )}
                {Fields.emails}
                {Fields.phoneNumbers}
                {Fields.physicalAddresses}
                {Fields.socialProfiles}
                {Fields.webPages}
                {Fields.imHandles}
                {Fields.dates}
                {Fields.relatives}
              </dl>

              {/* Right column */}
              <dl className="flex-1">
                {Fields.notes}
                {Fields.birthday}
                {Fields.groups}
                {Fields.sync}
              </dl>
            </>
          )}
        </div>
      </div>

      {/* Contact data changelog view */}
      <ContactHistoryDrawer
        contact={contactData}
        isDrawerOpen={isHistoryDrawerOpen}
        setIsDrawerOpen={setIsHistoryDrawerOpen}
        undoVersion={undoVersion}
        rollbackToVersion={rollbackToVersion}
      />

      {/* Resolve sync conflict alert */}
      <ResolveSyncConflictAlert
        contact={contact}
        conflict={focusedSyncConflict}
        isSyncConflictAlertOpen={isSyncConflictAlertOpen}
        setIsSyncConflictAlertOpen={setIsSyncConflictAlertOpen}
        onResolveSyncConflict={onResolveSyncConflict}
        onClickCancel={() => setIsSyncConflictAlertOpen(false)}
      />

      {/* Delete confirmation alert */}
      <Alert
        title={`Delete ${contact.givenName} ${contact.surname}?`}
        variant={ButtonVariant.Danger}
        isAlertOpen={isDeleteContactAlertOpen}
        onClose={setIsDeleteContactAlertOpen}
        actionButtonTitle="Delete Contact"
        actionHandler={onClickDeleteContact}
        cancelHandler={() => setIsDeleteContactAlertOpen(false)}
      />
    </>
  );
};

export default ContactDetails;
