import { Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import ReactHotToast from "react-hot-toast";

import {
  CheckCircleSolidIcon,
  ExclamationCircleSolidIcon,
  InfoCircleSolidIcon,
  TimesCircleSolidIcon,
  TimesIcon,
} from "./Icon";

export enum ToastLevel {
  Info,
  Success,
  Warning,
  Danger,
}

export type ToastProps = {
  title: string;
  description?: string;
  level?: ToastLevel;
};

export const showToast = (props: ToastProps) => {
  const { title, description, level = ToastLevel.Success } = props;

  let toastIcon: JSX.Element;
  let toastIconColor: string;

  switch (level) {
    case ToastLevel.Info:
      toastIcon = <InfoCircleSolidIcon />;
      toastIconColor = "text-zinc-400";
      break;
    case ToastLevel.Success:
      toastIcon = <CheckCircleSolidIcon />;
      toastIconColor = "text-green-400";
      break;
    case ToastLevel.Warning:
      toastIcon = <ExclamationCircleSolidIcon />;
      toastIconColor = "text-yellow-400";
      break;
    case ToastLevel.Danger:
      toastIcon = <TimesCircleSolidIcon />;
      toastIconColor = "text-red-400";
      break;
  }

  ReactHotToast.custom(
    (t) => (
      <Transition
        show={t.visible}
        as={Fragment}
        enter="transition ease-out duration-500"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="w-full max-w-sm overflow-hidden rounded-lg shadow-lg pointer-events-auto bg-zinc-100 dark:bg-zinc-800 ring-1 ring-black ring-opacity-5">
          <div className="p-4">
            <div className="flex items-start">
              <div className={toastIconColor} aria-hidden="true">
                {toastIcon}
              </div>
              <div className="ml-3 w-0 flex-1 pt-0.5">
                <p className="text-sm font-medium text-zinc-900 dark:text-zinc-100">{title}</p>
                {description && (
                  <p className="mt-1 text-sm text-zinc-500 dark:text-zinc-400">{description}</p>
                )}
              </div>
              <div className="flex flex-shrink-0 ml-4">
                <button
                  className="inline-flex rounded-md bg-zinc-100 text-zinc-400 dark:bg-zinc-800 hover:text-zinc-500 dark:text-zinc-500 dark:hover:text-zinc-400 focus:outline-none"
                  onClick={() => ReactHotToast.dismiss(t.id)}
                >
                  <span className="sr-only">Close</span>
                  <TimesIcon />
                </button>
              </div>
            </div>
          </div>
        </div>
      </Transition>
    ),
    { position: "bottom-right", duration: level === ToastLevel.Danger ? 10000 : 5000 }
  );
};
