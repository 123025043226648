import { ContactRow } from "@shared/models/Contact";
import { ContactGroupRowForDisplay } from "@shared/models/ContactGroup";

export function getGroupsByContactId(groups: ContactGroupRowForDisplay[]) {
  const groupByContactId: { [contactId: string]: ContactGroupRowForDisplay[] } = {};
  for (const group of groups) {
    // probably don't need to be enforced here, but just in case
    const uniqueContactIds: { [id: string]: true } = {};
    if (!group.contactIds) continue;

    for (const contactId of group.contactIds) {
      if (!groupByContactId[contactId]) groupByContactId[contactId] = [];
      if (!uniqueContactIds[contactId]) groupByContactId[contactId].push(group);
      uniqueContactIds[contactId] = true;
    }
  }
  return groupByContactId;
}

export function getIdIndex(contacts: (ContactRow | ContactGroupRowForDisplay | { id: string })[]) {
  const index: { [id: string]: number } = {};
  for (let i = 0; i < contacts.length; i++) {
    const contact = contacts[i];
    if (contact.id) {
      index[contact.id] = i;
    } else {
      console.error("Contact without ID!", { contact });
    }
  }
  return index;
}
