import ms from "ms";

export function getExpiresAtSec(config: string) {
  return getCurEpocSec() + getSec(config);
}

export function getSec(config: string) {
  return ms(config.trim()) / 1000;
}

export function getMs(config: string) {
  return ms(config.trim());
}

export function getSecFromMs(ms: number) {
  return Math.round(ms / 1000);
}

export function getEpochMsFromDate(date: Date) {
  return date.getTime();
}

export function getCurEpochMs() {
  const now = new Date();
  return now.getTime();
}

export function getCurEpocSec() {
  const curEpochMs = getCurEpochMs();
  return getSecFromMs(curEpochMs);
}

export const getDateFromEpochMs = (epochNum: number | string) => new Date(Number(epochNum));

export function getEpochMsFromStr(dateTime: string | null) {
  if (dateTime) {
    const date = new Date(dateTime);
    return getEpochMsFromDate(date) || 0;
  }
  return 0;
}

export function getEpochMs1YearFromNow() {
  const oneYearFromNow = new Date();
  oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
  return getEpochMsFromDate(oneYearFromNow);
}

export function getDateStampFromDateIsoStr(dateIsoString: string) {
  return dateIsoString.replace("-", "/").split("T")[0].replace("-", "/");
}

export function isValidDate(d: any) {
  return d instanceof Date && !isNaN(Number(d));
}

export function parseNoneDashDelimitedDateString(icsDate: string) {
  if (/^[0-9]{8}T[0-9]{6}Z$/.test(icsDate)) {
    const year = icsDate.substr(0, 4);
    const month = icsDate.substr(4, 2);
    const day = icsDate.substr(6, 2);

    const hour = icsDate.substr(9, 2);
    const minute = icsDate.substr(11, 2);
    const second = icsDate.substr(13, 2);

    const date = new Date(
      Date.UTC(
        Number(year),
        Number(month) - 1,
        Number(day),
        Number(hour),
        Number(minute),
        Number(second)
      )
    );
    return isValidDate(date) ? date : undefined;
  }
  return undefined;
}

export function get_M_d_yyyy(datestamp?: number | string) {
  const date = datestamp ? new Date(datestamp) : new Date();
  return (
    (date.getMonth() > 8 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)) +
    "/" +
    (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
    "/" +
    date.getFullYear()
  );
}

export function toDateTime(secs: number) {
  const date = new Date(1970, 0, 1); // Epoch
  return date.setSeconds(secs);
}

export function getMonthName(
  { date, monthIndex, locale = "en-US" }: { date?: Date; monthIndex?: number; locale?: "en-US" },
  shortName = false
) {
  const monthNames = [...Array(12).keys()].map((key) =>
    new Date(0, key).toLocaleString(locale, { month: shortName ? "short" : "long" })
  );

  const i = typeof monthIndex !== "undefined" ? monthIndex : date?.getMonth();
  if (typeof i !== "undefined") return monthNames[i];
  return "";
}

export function getDateInTimeZone(date: Date, timeZone: string, locale: string = "en-US") {
  return new Date(
    date.toLocaleString(locale, {
      timeZone,
    })
  );
}
