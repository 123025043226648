import { objKeys } from "@packages/common/object";
import { ContactRow } from "@shared/models/Contact";
import { IsDeleted } from "@shared/models/types";
import ContactDetails from "@web/components/contacts/details";
import { getMergedContact, MergedContact } from "@web/components/contacts/merge/helpers";
import Alert from "@web/components/core/Alert";
import Button, { ButtonVariant } from "@web/components/core/Button";
import Header from "@web/components/core/Header";
import {
  BookmarkIcon,
  CheckCircleSolidIcon,
  MergeIcon,
  StopCircleSolidIcon,
} from "@web/components/core/Icon";
import LoadingSpinner from "@web/components/loading/LoadingSpinner";
import DupeContactItem from "@web/components/tools/deduplicate/DupeContactItem";
import MainContactToMerge from "@web/components/tools/deduplicate/MainContactToMerge";
import { useDeduplicateContactIds } from "@web/hooks/useDedupe";
import { useUpdateContactMutation } from "@web/integrations/contact/api";
import classNames from "clsx";
import { useCallback, useState } from "react";

const Deduplicate = () => {
  const { duplicates, isLoading } = useDeduplicateContactIds();

  const [mainContactOverride, setMainContactOverride] = useState<{
    [index: string]: ContactRow["id"];
  }>({});
  const [ignoredContactOverride, setIgnoredContactOverride] = useState<{
    [index: string]: ContactRow["id"][];
  }>({});

  const [mergeStatus, setMergeStatus] = useState<{
    [index: string]: {
      isLoading: boolean;
      isSuccess: boolean;
      isError: boolean;
      mergedContact: ContactRow;
    };
  }>({});

  const [contactToEdit, setContactToEdit] = useState<ContactRow>();

  const [updateContact] = useUpdateContactMutation();

  const mergeContacts = useCallback(
    (id: ContactRow["id"], mergedContact: MergedContact, contacts: ContactRow[]) => {
      const {
        emails,
        phoneNumbers,
        physicalAddresses,
        imHandles,
        webPages,
        relatives,
        photos,
        dates,
        notes,
        jobPositions,
      } = mergedContact;
      const contactsToDelete = contacts.filter((contact) => contact.id !== mergedContact.id);
      return updateContact({
        id,
        emails,
        phoneNumbers,
        physicalAddresses,
        imHandles,
        webPages,
        relatives,
        photos,
        dates,
        notes,
        jobPositions,
      }).then(() => {
        return Promise.all(
          contactsToDelete.map((contact) => {
            return updateContact({ id: contact.id, isDeleted: IsDeleted.YES });
          })
        );
      });
    },
    []
  );

  const undoMergeContacts = useCallback((mainContact: ContactRow, contacts: ContactRow[]) => {
    const contactsToRestore = contacts.filter((contact) => contact.id !== mainContact.id);
    return updateContact({
      ...mainContact,
    }).then(() => {
      return Promise.all(
        contactsToRestore.map((contact) => {
          return updateContact({ id: contact.id, isDeleted: IsDeleted.NO });
        })
      );
    });
  }, []);

  const closeEditModal = useCallback(() => {
    setContactToEdit(undefined);
  }, []);
  return (
    <>
      {isLoading ? (
        <div className="m-auto h-full">
          <LoadingSpinner loadingText="Loading duplicates..." />
        </div>
      ) : (
        <div className="container mx-auto mt-8">
          <Header
            title={`${duplicates.length} Duplicate${duplicates.length > 1 ? "s" : ""}`}
            details={`Merge contacts interactively, or click on "Merge All" to bulk merge, combining all fields.`}
            className="p-container"
          >
            <Button variant={ButtonVariant.Primary}>
              <MergeIcon rotation={270} />
              <span className="px-2">Merge All</span>
            </Button>
          </Header>
          <div className="divider" />
          {
            <section>
              {duplicates.map((dupeGroup, i) => {
                const mainContactId = mainContactOverride[i] || dupeGroup.mainContactId;
                const mainContact = dupeGroup.contacts[mainContactId];
                const ignoredContacts = ignoredContactOverride[i] || [];

                const allContacts = objKeys(dupeGroup.contacts).map((id) => dupeGroup.contacts[id]);
                const selectedContacts = allContacts.filter(
                  (contact) => !ignoredContacts.includes(contact.id)
                );
                const isCanMerge = selectedContacts.length > 1;
                const isMoreThanPair = allContacts.length > 2;
                const restContactIds: (string | number)[] = objKeys(dupeGroup.contacts).filter(
                  (id) => id !== mainContactId
                );

                const eleHeight = allContacts.length > 3 ? 750 : isMoreThanPair ? 700 : undefined;

                const setIgnoredContacts = (contactIds: ContactRow["id"][]) => {
                  setIgnoredContactOverride((prevState) => {
                    return {
                      ...prevState,
                      [i]: contactIds,
                    };
                  });
                };

                const setMain = (contactId: ContactRow["id"]) => {
                  setMainContactOverride((prevState) => {
                    return {
                      [i]: contactId,
                    };
                  });
                };

                const onMerge = () => {
                  const {
                    id,
                    givenName,
                    suffix,
                    prefix,
                    surname,
                    companyName,
                    jobTitle,
                    departmentName,
                    middleName,
                    nickname,
                  } = mainContact;
                  const mergedContact = getMergedContact(selectedContacts, {
                    id,
                    givenName,
                    suffix,
                    prefix,
                    surname,
                    companyName,
                    jobTitle,
                    departmentName,
                    middleName,
                    nickname,
                  });
                  setMergeStatus((prevState) => {
                    return {
                      ...prevState,
                      [i]: {
                        isLoading: true,
                        isLoaded: false,
                        isError: false,
                        mergedContact,
                      },
                    };
                  });
                  return mergeContacts(id, mergedContact, selectedContacts)
                    .then(() => {
                      setMergeStatus((prevState) => {
                        return {
                          ...prevState,
                          [i]: {
                            isLoading: false,
                            isSuccess: true,
                            isError: false,
                            mergedContact,
                          },
                        };
                      });
                    })
                    .catch(() => {
                      setMergeStatus((prevState) => {
                        return {
                          ...prevState,
                          [i]: {
                            isLoading: false,
                            isSuccess: false,
                            isError: true,
                            mergedContact,
                          },
                        };
                      });
                    });
                };

                const onUndoMerge = () => {
                  setMergeStatus((prevState) => {
                    const newState = { ...prevState };
                    delete newState[i];
                    return newState;
                  });
                  return undoMergeContacts(mainContact, selectedContacts);
                };

                return (
                  <div
                    key={i}
                    className="grid gap-6 lg:grid-cols-2 bg-gray-50 p-6 rounded-lg m-8 bg-primary shadow-lg border border-gray-100"
                  >
                    {mergeStatus[i] && (
                      <>
                        <div>
                          <MainContactToMerge mainContact={mergeStatus[i]?.mergedContact} />
                        </div>
                        {mergeStatus[i].isLoading && (
                          <LoadingSpinner
                            loadingText={`Merging ${selectedContacts.length} contacts...`}
                          />
                        )}
                        {mergeStatus[i].isSuccess && (
                          <div className="flex flex-col">
                            <div className="flex flex-col justify-center items-center h-full w-full text-center w-8 h-8 rounded-full bg-green-100">
                              <CheckCircleSolidIcon
                                size="lg"
                                className="text-green-600 animate-[ping_1s_ease-in-out]"
                                aria-hidden="true"
                              />
                            </div>
                            <h3 className="text-primary font-medium">Merge Succesful</h3>
                            <div className="grid grid-cols-2	gap-6">
                              <Button
                                variant={ButtonVariant.Secondary}
                                className="mt-6"
                                onClick={() => setContactToEdit(mergeStatus[i].mergedContact)}
                              >
                                Edit merged contact
                              </Button>
                              <Button
                                variant={ButtonVariant.Danger}
                                className="mt-6"
                                onClick={onUndoMerge}
                              >
                                Undo merge
                              </Button>
                            </div>
                          </div>
                        )}
                      </>
                    )}

                    {!mergeStatus[i] && (
                      <>
                        <div
                          className={classNames("overflow-y-scroll", {
                            "opacity-30": !isCanMerge,
                          })}
                          style={{ maxHeight: eleHeight }}
                        >
                          <span className="inline-flex items-center px-6 py-0.5 mb-6 rounded-full text-sm font-medium bg-purple-100 text-purple-700">
                            <BookmarkIcon className="text-purple-700 mr-4" />
                            Main Contact
                          </span>
                          <MainContactToMerge mainContact={mainContact} />
                        </div>
                        <div>
                          <div
                            className={classNames("sticky top-0 z-50 mb-6", {
                              invisible: !isCanMerge,
                            })}
                          >
                            <Button
                              variant={isCanMerge ? ButtonVariant.Success : ButtonVariant.Secondary}
                              disabled={!isCanMerge}
                              className="mr-4"
                              onClick={onMerge}
                            >
                              <MergeIcon rotation={270} />
                              <span className="px-2">
                                Merge Selected {isCanMerge && `(${selectedContacts.length - 1})`}
                              </span>
                            </Button>
                            {isMoreThanPair && (
                              <Button
                                variant={
                                  isCanMerge ? ButtonVariant.Secondary : ButtonVariant.Secondary
                                }
                                disabled={!isCanMerge}
                                onClick={() => {
                                  setIgnoredContactOverride((prevState) => {
                                    return {
                                      ...prevState,
                                      [i]: restContactIds,
                                    };
                                  });
                                }}
                              >
                                <StopCircleSolidIcon />
                                <span className="px-2">Ignore All</span>
                              </Button>
                            )}
                          </div>

                          <div
                            className={classNames({
                              "relative overflow-y-scroll pb-1": isMoreThanPair,
                            })}
                            style={{ minHeight: eleHeight }}
                          >
                            <ul
                              role="list"
                              className={classNames("grid grid-cols-1 gap-6 xl:px-0", {
                                "absolute top-0 bottom-0 left-0 right-0": isMoreThanPair,
                              })}
                            >
                              {restContactIds
                                .map((id) => dupeGroup.contacts[id])
                                .map((contact) => {
                                  return (
                                    <DupeContactItem
                                      key={contact.id}
                                      contact={contact}
                                      mainContact={mainContact}
                                      ignored={ignoredContacts}
                                      setIgnored={setIgnoredContacts}
                                      setMain={setMain}
                                    />
                                  );
                                })}
                            </ul>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                );
              })}
            </section>
          }
        </div>
      )}
      <Alert
        title=""
        width="3/5"
        variant={ButtonVariant.Success}
        isAlertOpen={!!contactToEdit}
        onClose={closeEditModal}
      >
        {contactToEdit && (
          <ContactDetails
            contact={contactToEdit}
            editOnly
            onSave={closeEditModal}
            onCancel={closeEditModal}
          />
        )}
      </Alert>
    </>
  );
};

export default Deduplicate;
