import { Dialog, Transition } from "@headlessui/react";
import classNames from "clsx";
import React, { FC, Fragment, MutableRefObject, ReactNode } from "react";

type DrawerProps = {
  isDrawerOpen: boolean;
  setIsDrawerOpen: (open: boolean) => void;
  initialFocusRef?: MutableRefObject<HTMLElement | null>;
  className?: string;
  children?: ReactNode;
};

const Drawer: FC<DrawerProps> = ({ isDrawerOpen, setIsDrawerOpen, initialFocusRef, children }) => {
  return (
    <Transition.Root show={isDrawerOpen} as={Fragment}>
      <Dialog
        as="div"
        initialFocus={initialFocusRef}
        onClose={setIsDrawerOpen}
        className="fixed inset-0 overflow-hidden"
      >
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 transition-opacity bg-overlay" />
          </Transition.Child>

          <div className="fixed inset-y-0 right-0 flex max-w-full pl-0 sm:pl-16">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-500"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-500"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className={classNames("w-screen max-w-3xl bg-primary shadow-xl", classNames)}>
                {children}
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Drawer;
