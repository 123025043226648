import { Transition } from "@headlessui/react";
import { KeyboardIcon } from "@web/components/core/Icon";
import { isMac } from "@web/helpers/device";
import {
  Fragment,
  MutableRefObject,
  Ref,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useKey, useKeys } from "rooks";

const useHotKeyWithPrompt = (
  hotkey?: string[],
  hotkeyHandler?: (e: KeyboardEvent) => void,
  matchAll = true,
  options?: {
    when?: boolean;
    /**
     * Keyboardevent types to listen for. Valid options are keyDown, keyPress and keyUp
     */
    eventTypes?: Array<number | string>;
    /**
     * target ref on which the events should be listened. If no target is specified,
     * events are listened to on the window
     */
    target?: Ref<HTMLElement> | MutableRefObject<Document> | MutableRefObject<HTMLElement | null>;
  }
) => {
  const isHotKeyEnabled = Boolean(hotkey);
  const hook = matchAll ? useKeys : useKey;

  const [showHotKey, setShowHotKey] = useState(false);
  const hotKeyTimerRef = useRef<NodeJS.Timeout | null>(null);

  const keyDownHandler = useCallback(
    (e: KeyboardEvent) => {
      if (isHotKeyEnabled && e.key === "Alt") {
        hotKeyTimerRef.current = setTimeout(() => {
          e.preventDefault();
          e.stopPropagation();
          setShowHotKey(true);
        }, 500);
      }
    },
    [isHotKeyEnabled]
  );

  useEffect(() => {
    if (isHotKeyEnabled) {
      window.removeEventListener("keydown", keyDownHandler);
      window.addEventListener("keydown", keyDownHandler);
      return () => window.removeEventListener("keydown", keyDownHandler);
    }
  }, [isHotKeyEnabled]);

  const keyUpHandler = useCallback(() => {
    if (hotKeyTimerRef.current) {
      clearTimeout(hotKeyTimerRef.current);
      setShowHotKey(false);
    }
  }, [isHotKeyEnabled]);

  useEffect(() => {
    if (isHotKeyEnabled) {
      window.removeEventListener("keyup", keyUpHandler);
      window.addEventListener("keyup", keyUpHandler);
      return () => window.removeEventListener("keyup", keyUpHandler);
    }
  }, [isHotKeyEnabled]);

  const hookOptions = useMemo(() => {
    const hookOptions = { ...options };
    if (typeof hookOptions.when == "undefined") {
      hookOptions.when = isHotKeyEnabled;
    }
    return hookOptions;
  }, [options, isHotKeyEnabled]);

  hook(
    [...(hotkey || [])],
    (e) => {
      if (hotkeyHandler) hotkeyHandler(e);
    },
    // @ts-ignore  bad typing from rooks, no reason the two options should be different
    hookOptions
  );

  return useMemo(() => {
    return hotkey ? (
      <Transition
        appear
        show={showHotKey}
        enter="transition-opacity duration-400"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-700"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        as={Fragment}
      >
        <span
          className="absolute z-50 inline-block px-2 py-0.5 rounded text-sm font-medium bg-yellow-100 text-yellow-800 whitespace-nowrap"
          style={{ marginTop: "-35px", marginLeft: "-2rem" }} // some space-x class in the parent that will override margin class, so hardcoding as inline
        >
          <KeyboardIcon className="mr-2" size="sm" />
          <span>
            {hotkey
              .map((key) => {
                let keyLabel = key.replace("Key", "");
                if (keyLabel === "Alt") keyLabel = isMac() ? "Opt" : "Alt";
                return keyLabel;
              })
              .join(" + ")}
          </span>
        </span>
      </Transition>
    ) : null;
  }, [showHotKey]);
};

export default useHotKeyWithPrompt;
