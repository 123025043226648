import {
  ContactVersionOperationGroups,
  createOperationGroups,
  inverse,
  OperationGroup,
} from "@web/helpers/operation";
import { FC } from "react";

import Alert from "../../../core/Alert";
import { ButtonVariant } from "../../../core/Button";
import OperationGroupRow from "./OperationGroupRow";

type UndoChangesConfirmationAlertProps = {
  versionOperationGroups: ContactVersionOperationGroups | null;
  isUndoAlertOpen: boolean;
  setIsUndoAlertOpen: (open: boolean) => void;
  onClickUndo: () => void;
  onClickCancel: () => void;
};

const UndoChangesConfirmationAlert: FC<UndoChangesConfirmationAlertProps> = ({
  versionOperationGroups,
  isUndoAlertOpen,
  setIsUndoAlertOpen,
  onClickUndo,
  onClickCancel,
}) => {
  let inverseOperationGroups: OperationGroup[] = [];
  if (versionOperationGroups) {
    const diff = versionOperationGroups.version.contactDiff;
    if (diff) {
      const inverseOperations = inverse(diff, versionOperationGroups.version.prevContact).flat();
      inverseOperationGroups = createOperationGroups(inverseOperations);
    }
  }

  return (
    <Alert
      title="Undo changes?"
      description={`Performing this action will make following changes to current contact data:`}
      variant={ButtonVariant.Primary}
      isAlertOpen={isUndoAlertOpen}
      onClose={setIsUndoAlertOpen}
      actionButtonTitle="Confirm"
      actionHandler={onClickUndo}
      cancelHandler={onClickCancel}
    >
      {/* List of operation groups */}
      <ul className="py-2">
        {versionOperationGroups &&
          inverseOperationGroups?.map((operationGroup, index) => {
            return (
              <OperationGroupRow
                key={index}
                group={operationGroup}
                isPastOperation={false}
                curContact={versionOperationGroups.version.prevContact}
                prevContact={versionOperationGroups.version.curContact}
              />
            );
          })}
      </ul>
    </Alert>
  );
};

export default UndoChangesConfirmationAlert;
