/* eslint-disable react/button-has-type */
import {
  DangerColor,
  DangerHoverColor,
  PrimaryColor,
  PrimaryHoverColor,
  SecondaryColor,
  SecondaryHoverColor,
  SuccessColor,
  SuccessHoverColor,
  WarningColor,
  WarningHoverColor,
} from "@web/components/core/colorVariant";
import useHotKeyWithPrompt from "@web/hooks/useHotKeyWithPrompt";
import classNames from "clsx";
import { FC, MouseEvent as ReactMouseEvent, MutableRefObject } from "react";

export enum ButtonVariant {
  Primary,
  Secondary,
  Success,
  Warning,
  Danger,
}

type ButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  full?: boolean;
  small?: boolean;
  variant?: ButtonVariant;
  endIcon?: JSX.Element;
  icon?: JSX.Element;
  className?: string;
  innerRef?: MutableRefObject<HTMLButtonElement | null>;
  hotkey?: string[];
  hotkeyHandler?: (e: KeyboardEvent) => void | boolean;
  hotkeyMatchAll?: boolean;
};

const Button: FC<ButtonProps> = ({
  type = "button",
  full = false,
  small = false,
  variant = ButtonVariant.Primary,
  endIcon,
  icon,
  className,
  innerRef,
  children,
  hotkey,
  hotkeyHandler,
  hotkeyMatchAll = true,
  onClick,
  ...rest
}) => {
  let colorClassNames: string;
  switch (variant) {
    case ButtonVariant.Primary:
      colorClassNames = classNames(PrimaryColor, { [PrimaryHoverColor]: !rest.disabled });
      break;
    case ButtonVariant.Secondary:
      colorClassNames = classNames(SecondaryColor, {
        [SecondaryHoverColor]: !rest.disabled,
      });
      break;
    case ButtonVariant.Success:
      colorClassNames = classNames(SuccessColor, {
        [SuccessHoverColor]: !rest.disabled,
      });

      break;
    case ButtonVariant.Warning:
      colorClassNames = classNames(WarningColor, {
        [WarningHoverColor]: !rest.disabled,
      });

      break;
    case ButtonVariant.Danger:
      colorClassNames = classNames(DangerColor, {
        [DangerHoverColor]: !rest.disabled,
      });

      break;
  }

  const sizeClassNames = small
    ? "px-2 py-1 text-xs rounded"
    : "px-2.5 py-1.5 sm:px-3 sm:py-2 text-sm rounded-md";

  const HotKeyPrompt = useHotKeyWithPrompt(
    hotkey,
    (e) => {
      if (hotkeyHandler) hotkeyHandler(e);
      else if (onClick) {
        // cast to MouseEvent to hide error. but really a keyboard event
        onClick(e as unknown as ReactMouseEvent<HTMLButtonElement, MouseEvent>);
      }
    },
    hotkeyMatchAll
  );

  return (
    <button
      type={type}
      onClick={onClick}
      className={classNames(
        full ? "flex w-full" : "inline-flex",
        colorClassNames,
        sizeClassNames,
        "font-medium relative shadow-sm disabled:opacity-50 disabled:cursor-default space-x-1 px-2",
        className
      )}
      ref={innerRef}
      {...rest}
    >
      {icon && (
        <div className="w-5 h-5 mr-2" aria-hidden="true">
          {icon}
        </div>
      )}
      {children}
      {endIcon && (
        <div className="w-5 h-5 ml-2 flex items-center" aria-hidden="true">
          {endIcon}
        </div>
      )}
      {HotKeyPrompt}
    </button>
  );
};

export default Button;
