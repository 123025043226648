import { Dialog } from "@headlessui/react";
import { ContactRow } from "@shared/models/Contact";
import { ContactVersion } from "@shared/models/ContactVersion";
import { FC, useCallback, useMemo, useRef, useState } from "react";

import { isEmpty } from "../../../../helpers/array";
import {
  ContactVersionOperationGroups,
  createOperationGroups,
} from "../../../../helpers/operation";
import { useGetContactChangelogQuery } from "../../../../integrations/contact/api";
import Drawer from "../../../core/Drawer";
import LoadingSpinner from "../../../loading/LoadingSpinner";
import ContactVersionRow from "./ContactVersionRow";
import RestoreContactConfirmationAlert from "./RestoreContactConfirmationAlert";
import UndoChangesConfirmationAlert from "./UndoChangesConfirmationAlert";

type ContactHistoryDrawerProps = {
  contact: ContactRow;
  undoVersion: (version: ContactVersion) => void;
  rollbackToVersion: (version: ContactVersion) => void;
  isDrawerOpen: boolean;
  setIsDrawerOpen: (open: boolean) => void;
};

const ContactHistoryDrawer: FC<ContactHistoryDrawerProps> = ({
  contact,
  undoVersion,
  rollbackToVersion,
  isDrawerOpen,
  setIsDrawerOpen,
}) => {
  // API
  const { data: versions } = useGetContactChangelogQuery(contact.id, { skip: !isDrawerOpen });

  // For each version, calculate operation groups to show
  const versionOperationGroupsList = useMemo(() => {
    if (versions && isDrawerOpen) {
      const result: ContactVersionOperationGroups[] = [];
      const sortedArray = [...versions];
      sortedArray.sort((obj1, obj2) => obj2.createdAt - obj1.createdAt);
      sortedArray?.forEach((version) => {
        const groups = createOperationGroups(version.contactDiff || []);
        if (!isEmpty(groups) || version.isNew) {
          result.push({ version, groups });
        }
      });
      return result;
    } else {
      return [];
    }
  }, [contact.id, versions]);

  const inputRef = useRef<HTMLElement>(null);

  const [selectedVersionOperationGroups, setSelectedVersionOperationGroups] =
    useState<ContactVersionOperationGroups | null>(null);
  const [isUndoAlertOpen, setIsUndoAlertOpen] = useState(false);
  const [isRestoreAlertOpen, setIsRestoreAlertOpen] = useState(false);

  const onClickUndo = useCallback(() => {
    if (selectedVersionOperationGroups) {
      undoVersion(selectedVersionOperationGroups.version);
    }
  }, [selectedVersionOperationGroups, undoVersion]);

  const onClickRestore = useCallback(() => {
    if (selectedVersionOperationGroups) {
      rollbackToVersion(selectedVersionOperationGroups.version);
    }
  }, [selectedVersionOperationGroups, rollbackToVersion]);

  return (
    <Drawer
      isDrawerOpen={isDrawerOpen}
      setIsDrawerOpen={() => setIsDrawerOpen(false)}
      initialFocusRef={inputRef}
    >
      <div className="flex flex-col h-screen divide-primary-y">
        {/* Header */}
        <div className="flex items-center justify-between flex-shrink-0 px-4 py-4 sm:px-6 bg-zinc-50 dark:bg-zinc-900">
          <Dialog.Title
            as="h3"
            className="text-2xl font-bold leading-6 text-zinc-900 dark:text-zinc-100"
          >
            Contact History
          </Dialog.Title>
        </div>

        {/* Main content */}
        {versions === undefined ? (
          <LoadingSpinner loadingText="Loading history…" />
        ) : (
          <div className="flex flex-col flex-1 overflow-y-scroll">
            <ul role="list" className="px-4 py-6 sm:px-6">
              {versionOperationGroupsList.map((versionOperationGroups, index) => (
                <ContactVersionRow
                  key={index}
                  versionOperationGroups={versionOperationGroups}
                  showSpine={index !== versionOperationGroupsList.length - 1}
                  showUndoOption={!versionOperationGroups.version.isNew}
                  onClickUndo={() => {
                    setSelectedVersionOperationGroups(versionOperationGroups);
                    setIsUndoAlertOpen(true);
                  }}
                  showRestoreOption={index !== 0}
                  onClickRestore={() => {
                    setSelectedVersionOperationGroups(versionOperationGroups);
                    setIsRestoreAlertOpen(true);
                  }}
                />
              ))}
            </ul>
          </div>
        )}
      </div>

      {/* Undo confirmation alert */}
      <UndoChangesConfirmationAlert
        versionOperationGroups={selectedVersionOperationGroups}
        isUndoAlertOpen={isUndoAlertOpen}
        setIsUndoAlertOpen={setIsUndoAlertOpen}
        onClickUndo={onClickUndo}
        onClickCancel={() => setIsUndoAlertOpen(false)}
      />

      {/* Restore confirmation alert */}
      <RestoreContactConfirmationAlert
        currentContact={contact}
        versionOperationGroups={selectedVersionOperationGroups}
        isRestoreAlertOpen={isRestoreAlertOpen}
        setIsRestoreAlertOpen={setIsRestoreAlertOpen}
        onClickRestore={onClickRestore}
        onClickCancel={() => setIsUndoAlertOpen(false)}
      />
    </Drawer>
  );
};

export default ContactHistoryDrawer;
