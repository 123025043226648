import classNames from "clsx";
import { FC, useCallback, useEffect, useMemo, useRef } from "react";

import { Email, IsDefault } from "../../../../../services/src/shared/models/types";
import { isEmpty } from "../../../../helpers/array";
import { openLink } from "../../../../helpers/window";
import {
  CopySolidIcon,
  EnvelopeIcon,
  PaperPlaneSolidIcon,
  PenSolidIcon,
  StarIcon,
  StarSolidIcon,
} from "../../../core/Icon";
import TextInput from "../../../core/TextInput";
import { showToast } from "../../../core/Toast";
import HighlightText from "../../../HighlightText";
import { generateTypeSelectItems } from "../helpers";
import BaseDetailsField from "../shared/BaseDetailsField";
import ContextualMenuField, { ContextualMenuItem } from "../shared/ContextualMenuField";
import MarkDefaultItemButton from "../shared/MarkDefaultItemButton";
import RemoveDetailsItemButton from "../shared/RemoveDetailsItemButton";
import TypeSelect from "../shared/TypeSelect";
import { ContactDataFieldProps } from "../types";

const EmailsField: FC<ContactDataFieldProps> = ({
  contactData,
  dispatch,
  isEditing,
  directlyUpdateContactData,
  focusedField,
  onFocusField,
}) => {
  const emails = contactData.emails || [];
  const emailsWithIndices: { email: Email; originalIndex: number }[] = useMemo(
    () =>
      [...emails]
        .map((email, index) => ({ email, originalIndex: index }))
        .sort((a, b) => (b.email.isDefault || 0) - (a.email.isDefault || 0)),
    [emails]
  );

  const lastEmailRowInputRef = useRef<HTMLInputElement | null>(null);
  const focusedEmailRowInputRef = useRef<HTMLInputElement | null>(null);

  const focusOnLastEmailInput = useCallback(() => {
    lastEmailRowInputRef.current?.focus();
  }, [lastEmailRowInputRef]);

  useEffect(() => {
    if (focusedField && focusedField.fieldName === "emails") {
      focusedEmailRowInputRef.current?.focus();
      focusedEmailRowInputRef.current?.scrollIntoView({ behavior: "auto", block: "center" });
    }
  }, [focusedField]);

  const emailContextualItems = useMemo<ContextualMenuItem<Email>[]>(() => {
    return [
      {
        icon: (payload) => (payload.value.isDefault ? StarSolidIcon : StarIcon),
        tooltip: (payload) => (payload.value.isDefault ? "Unmark as Default" : "Mark as Default"),
        action: (payload) => {
          if (payload.index !== undefined) {
            onMarkEmailDefaultAtIndex(!payload.value.isDefault, payload.index);
          }
        },
        isPinned: (payload) => payload.value.isDefault === IsDefault.YES,
      },
      {
        icon: () => PaperPlaneSolidIcon,
        tooltip: () => "Send email",
        action: (payload) => {
          openLink(`mailto:${payload.value.value}`);
        },
      },
      {
        icon: () => CopySolidIcon,
        tooltip: () => "Copy email to clipboard",
        action: (payload) => {
          if (navigator && navigator.clipboard) {
            navigator.clipboard.writeText(payload.value.value);
            showToast({ title: "Email copied to clipboard!" });
          }
        },
      },
      {
        icon: () => PenSolidIcon,
        tooltip: () => "Edit email",
        action: (payload) => {
          onFocusField?.(payload);
        },
      },
    ];
  }, [contactData]);

  const onMarkEmailDefaultAtIndex = useCallback(
    (isDefault: boolean, index: number) => {
      // If marking an email default, first reset isDefault state for every object in the array
      const emailsToUpdate: Email[] = isDefault
        ? emails.map((o) => ({
            ...o,
            isDefault: IsDefault.NO,
          }))
        : [...emails];
      emailsToUpdate[index] = {
        ...emailsToUpdate[index],
        isDefault: isDefault ? IsDefault.YES : IsDefault.NO,
      };
      if (!isEditing) {
        directlyUpdateContactData?.({ emails: emailsToUpdate });
      }
      updateEmails(emailsToUpdate);
    },
    [emails, isEditing]
  );

  // Resolve email types from the default list + types that are in-use
  const emailTypeSelectItems = generateTypeSelectItems(
    "emails",
    emails.map((email) => email.type)
  );

  const updateEmails = (emails: Email[]) => {
    dispatch({ type: "emails", payload: emails });
  };

  const onAddNewEmail = (type?: Email["type"]) => {
    const emailsToUpdate: Email[] = [
      ...emails,
      { value: "", type: type || emailTypeSelectItems[0]?.value },
    ];
    updateEmails(emailsToUpdate);

    // After a short delay, focus on the last email input (that was just added)
    setTimeout(() => {
      focusOnLastEmailInput();
    }, 100);
  };

  const onUpdateEmail = ({ index, data }: { index: number; data: Partial<Email> }) => {
    const emailsToUpdate: Email[] = [...emails];
    emailsToUpdate[index] = {
      ...emailsToUpdate[index],
      ...data,
    };
    updateEmails(emailsToUpdate);
  };

  const onRemoveEmailAtIndex = (index: number) => {
    const emailsToUpdate: Email[] = [...emails];
    emailsToUpdate.splice(index, 1);
    updateEmails(emailsToUpdate);
  };

  // Do not show for read-only mode if there are no emails
  if (!isEditing && isEmpty(emails)) {
    return null;
  }

  return (
    <BaseDetailsField
      label="Emails"
      isEditing={isEditing}
      icon={<EnvelopeIcon size="lg" className="icon-color-purple" />}
    >
      {isEditing ? (
        <>
          {emailsWithIndices.map(({ email, originalIndex }, index) => {
            const isLastEmailInput = originalIndex === emails.length - 1;
            const isFocusedField =
              focusedField?.fieldName === "emails" && focusedField?.index === originalIndex;
            const isDefault = email.isDefault === IsDefault.YES;
            return (
              <dd key={index} className="flex flex-row w-full group" tabIndex={-1}>
                <TypeSelect
                  items={emailTypeSelectItems}
                  initialItemId={email.type}
                  onSelectItem={(item) =>
                    onUpdateEmail({ index: originalIndex, data: { type: item.value } })
                  }
                  createNewItemTitle="Custom"
                />
                <TextInput
                  name="email"
                  type="email"
                  value={email.value}
                  onChange={(event) =>
                    onUpdateEmail({ index: originalIndex, data: { value: event.target.value } })
                  }
                  forwardedRef={
                    (isFocusedField && focusedEmailRowInputRef) ||
                    (isLastEmailInput && lastEmailRowInputRef) ||
                    undefined
                  }
                  className="flex-1 mr-2"
                />
                <MarkDefaultItemButton
                  isDefault={isDefault}
                  onClickButton={() => onMarkEmailDefaultAtIndex(!isDefault, originalIndex)}
                  tooltip={isDefault ? "Unmark as Default" : "Mark as Default"}
                  className={classNames(
                    "group-hover:visible",
                    isDefault ? "visible opacity-90" : "invisible opacity-70"
                  )}
                />
                <RemoveDetailsItemButton
                  onClickButton={() => onRemoveEmailAtIndex(originalIndex)}
                  tooltip="Remove Email"
                  className="invisible group-hover:visible opacity-70 group-hover:opacity-100"
                />
              </dd>
            );
          })}
          <dd key="add-new-email" className="flex flex-row w-full">
            <TypeSelect
              items={emailTypeSelectItems}
              onSelectItem={(item) => onAddNewEmail(item.value)}
              shouldResetSelection
              createNewItemTitle="Custom"
            />
            <button
              className="px-3 text-sm font-medium text-color-purple"
              onClick={() => onAddNewEmail()}
            >
              Add an email
            </button>
          </dd>
        </>
      ) : (
        emailsWithIndices.map(({ email, originalIndex }, index) => (
          <dd key={index}>
            <ContextualMenuField
              items={emailContextualItems}
              actionPayload={{
                fieldName: "emails",
                value: email,
                index: originalIndex,
              }}
            >
              <a
                href={`mailto:${email.value}`}
                target="_blank"
                rel="noreferrer"
                className="text-primary text-underline"
              >
                <HighlightText value={email.value} />
              </a>
              <div className="uppercase text-label">{email.type}</div>
            </ContextualMenuField>
          </dd>
        ))
      )}
    </BaseDetailsField>
  );
};

export default EmailsField;
