import { getCurEpochMs, getSec } from "@packages/common/dateTime";
import { ContactRow } from "@shared/models/Contact";
import { ContactGroup, ContactGroupRowForDisplay } from "@shared/models/ContactGroup";
import { ContactSyncConflict } from "@shared/models/ContactSyncConflict";
import { ContactVersion } from "@shared/models/ContactVersion";
import {
  fetchPaginatedContacts,
  getEdgeCacheTimestamp,
  setEdgeCacheTimestamp,
} from "@web/hooks/useContacts";
import { appApi, appNoCacheApi } from "@web/integrations/app/api";

import { queryFetch } from "../redux/baseQuery";
import TitledockWebSocket from "../titledockWS";
import { getIdIndex } from "./helpers";

const maxGetContactsRequests = 100; // Should cover max contacts limit / number of results per request

export type ContactSnapshotState = {
  contacts: ContactRow[];
  timestamp: number;
  delegatedUserId: string | null;
};

export type ContactRemoved = {
  contactId: ContactRow["id"];
  createdAt: ContactVersion["createdAt"];
  userId_contactId?: string;
  userId_isDeleted?: string;
};

export const contactNoCacheApi = appNoCacheApi.injectEndpoints({
  endpoints: (builder) => ({
    getContactsUpdated: builder.query<ContactRow[], number>({
      providesTags: [{ type: "CONTACTS_UPDATED", id: "LIST" }],
      query: (timestamp) => `/contacts?updatedAt=${timestamp}`,
    }),
    getContactsPurged: builder.query<ContactRemoved[], number>({
      providesTags: [{ type: "CONTACTS_PURGED", id: "LIST" }],
      query: (timestamp) => `/contacts/deleted?deletedAt=${timestamp}`,
    }),
  }),
});

export const contactApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getContactSnapshot: builder.query<ContactSnapshotState, void>({
      providesTags: () => [{ type: "CONTACT_SNAPSHOT", id: "LIST" }],
      query: () => "/contacts",
      transformResponse: (contacts: ContactRow[], meta) => {
        const timestamp = (meta as any)?.response?.headers.get("updated-at");
        const delegatedUserId: string | null =
          (meta as any)?.response.headers.get("delegated-user-id") || null;
        setEdgeCacheTimestamp(timestamp);
        return {
          contacts,
          timestamp,
          delegatedUserId,
        };
      },
      async onQueryStarted(_arg, { dispatch, updateCachedData, queryFulfilled }) {
        const { meta } = await queryFulfilled;

        // Call /contacts endpoint with incremental page # until startKey is empty
        let page: number = 1;
        let startKey: string = (meta as any)?.response?.headers.get("next-start-key");
        while (page < maxGetContactsRequests && startKey) {
          const results = await fetchPaginatedContacts(page, startKey, async (contactsUpdated) => {
            updateCachedData(({ contacts, timestamp }) => {
              timestamp = getCurEpochMs();
              const contactIdIndex = getIdIndex(contacts);
              for (const contact of contactsUpdated) {
                const i = contactIdIndex[contact.id];
                if (contacts[i]) {
                  contacts[i] = contact;
                } else {
                  contacts.push(contact);
                }
              }
            });
          });
          page = results.page;
          startKey = results.startKey;
        }
      },
    }),
    getPinnedContactIds: builder.query<ContactRow[], void>({
      query: () => "/contacts/pinned",
      providesTags: ["PINNED_CONTACT_IDS"],
    }),
    createContact: builder.mutation<ContactRow, ContactRow>({
      query: (contact) => ({
        url: `contacts`,
        method: "POST",
        body: contact,
      }),
      async onQueryStarted(patch, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          contactNoCacheApi.util.updateQueryData(
            "getContactsUpdated",
            getEdgeCacheTimestamp(),
            (draft) => {
              draft.push(patch);
            }
          )
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    updateContact: builder.mutation<ContactRow, Partial<ContactRow>>({
      query: (contact) => ({
        url: `contacts/${contact.id}`,
        method: "POST",
        body: contact,
      }),
      async onQueryStarted(patch, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          contactNoCacheApi.util.updateQueryData(
            "getContactsUpdated",
            getEdgeCacheTimestamp(),
            (draft) => {
              const index = draft?.findIndex((contact) => contact.id === patch.id);
              const updated = { ...draft[index], ...patch };
              if (index > -1) {
                draft.splice(index, 1, updated);
              } else {
                draft.push(updated);
              }
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          console.error("Contact update failed");
          patchResult.undo();
        }
      },
    }),
    deleteContact: builder.mutation<ContactRow, string>({
      query: (contactId) => ({
        url: `contacts/${contactId}`,
        method: "DELETE",
      }),
      async onQueryStarted(contactId, { dispatch, queryFulfilled }) {
        const timestamp = getCurEpochMs();
        console.log({ draft: timestamp });

        const patchResult = dispatch(
          contactNoCacheApi.util.updateQueryData(
            "getContactsPurged",
            getEdgeCacheTimestamp(),
            (draft) => {
              console.log({ draft });
              draft.push({ contactId, createdAt: timestamp });
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    deleteContacts: builder.mutation<void, { contactIds: string[] }>({
      query: (contactIds) => ({
        url: "bulk/contacts",
        method: "DELETE",
        body: contactIds,
      }),
      async onQueryStarted({ contactIds }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          contactNoCacheApi.util.updateQueryData(
            "getContactsPurged",
            getEdgeCacheTimestamp(),
            (draft) => {
              for (const id of contactIds) {
                draft.push({ contactId: id, createdAt: getCurEpochMs() });
              }
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),

    // Groups
    getContactGroups: builder.query<ContactGroupRowForDisplay[], void>({
      query: () => "/contacts/groups",
      providesTags: ["GROUPS"],
      transformResponse: (response: ContactGroupRowForDisplay[]) => {
        return response
          .filter((a) => a.isDeleted !== 1)
          .sort((a, b) => a.name.localeCompare(b.name));
      },
      async onCacheEntryAdded(_arg, { updateCachedData, cacheDataLoaded }) {
        // Create a websocket connection when the cache subscription starts
        let timestamp = getCurEpochMs();
        const tdWs = new TitledockWebSocket();

        await cacheDataLoaded;

        try {
          if (tdWs.isReady()) {
            tdWs.subscribe("/contacts/groups", async (_e, body) => {
              if (body.entity === "/contacts/groups") {
                const { data: contactGroupUpdates } = await queryFetch<ContactGroupRowForDisplay[]>(
                  `/contacts/groups?updatedAt=${timestamp}`
                );
                timestamp = getCurEpochMs();
                if (contactGroupUpdates) {
                  updateCachedData((groups) => {
                    const groupIndex = getIdIndex(groups);
                    for (const group of contactGroupUpdates) {
                      const i = groupIndex[group.id];
                      if (groups[i]) groups[i] = group;
                      else groups.push(group);
                    }
                  });
                }
              }
            });
          }
        } catch {}
      },
    }),
    createContactGroup: builder.mutation<
      ContactGroupRowForDisplay,
      Partial<ContactGroupRowForDisplay>
    >({
      query: (group) => ({
        url: `contacts/groups`,
        method: "POST",
        body: group,
      }),
      async onQueryStarted(patch, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          contactApi.util.updateQueryData("getContactGroups", undefined, (draft) => {
            if (patch.id) {
              const index = draft.findIndex((group) => group.id === patch.id);
              if (index > -1) {
                draft.splice(index, 1, patch as ContactGroupRowForDisplay);
              } else {
                draft.push(patch as ContactGroupRowForDisplay);
              }
              draft.sort((a, b) => a.name.localeCompare(b.name));
            }
          })
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();

          // On failure, invalidate GROUPS tag to trigger a re-fetch
          dispatch(contactApi.util.invalidateTags(["GROUPS"]));
        }
      },
    }),
    createContactGroups: builder.mutation<void, Partial<ContactGroupRowForDisplay>[]>({
      query: (groups) => ({
        url: `bulk/contacts/groups`,
        method: "POST",
        body: { groups },
      }),
      async onQueryStarted(patch, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          contactApi.util.updateQueryData("getContactGroups", undefined, (draft) => {
            draft.push(...(patch as ContactGroupRowForDisplay[]));
            draft.sort((a, b) => a.name.localeCompare(b.name));
          })
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();

          // On failure, invalidate GROUPS tag to trigger a re-fetch
          dispatch(contactApi.util.invalidateTags(["GROUPS"]));
        }
      },
    }),
    updateContactGroup: builder.mutation<ContactGroupRowForDisplay, ContactGroupRowForDisplay>({
      query: (group) => ({
        url: `contacts/groups/${group.id}`,
        method: "POST",
        body: group,
      }),
      invalidatesTags: (_result, _error, { id }) => [{ type: "GROUPS", id }],
      async onQueryStarted(patch, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          contactApi.util.updateQueryData("getContactGroups", undefined, (draft) => {
            const index = draft.findIndex((group) => group.id === patch.id);
            if (index > -1) {
              if (patch.isDeleted === 1) {
                draft.splice(index, 1);
              } else {
                draft.splice(index, 1, patch);
              }
            }
          })
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();

          // On failure, invalidate GROUPS tag to trigger a re-fetch
          dispatch(contactApi.util.invalidateTags(["GROUPS"]));
        }
      },
    }),
    purgeContactGroup: builder.mutation<ContactGroup, string>({
      query: (groupId) => ({
        url: `contacts/groups/${groupId}`,
        method: "DELETE",
      }),
      async onQueryStarted(groupId, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          contactApi.util.updateQueryData("getContactGroups", undefined, (draft) => {
            const index = draft.findIndex((group) => group.id === groupId);
            if (index > -1) {
              draft.splice(index, 1);
            }
          })
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();

          // On failure, invalidate GROUPS tag to trigger a re-fetch
          dispatch(contactApi.util.invalidateTags(["GROUPS"]));
        }
      },
    }),

    // Changelog
    getContactChangelog: builder.query<ContactVersion[], string>({
      query: (contactId) => `/contacts/${contactId}/changelog`,
      providesTags: (_result, _error, contactId) => [{ type: "CHANGELOG", id: contactId }],
      async onCacheEntryAdded(contactId, { updateCachedData, cacheDataLoaded, cacheEntryRemoved }) {
        const tdWs = new TitledockWebSocket();
        const url = `/contacts/${contactId}/changelog`;
        try {
          await cacheDataLoaded;
          if (tdWs.isReady()) {
            tdWs.subscribe(url, async (_e, body) => {
              if (body.entity === url) {
                const { data: contactVersions } = await queryFetch<ContactVersion[]>(
                  `/contacts/${contactId}/changelog`
                );
                if (contactVersions) {
                  updateCachedData((versions) => {
                    versions.length = 0;
                    for (const v of contactVersions) {
                      versions.push(v);
                    }
                  });
                }
              }
            });
            await cacheEntryRemoved;
            tdWs.unSubscribe(url);
          }
        } catch {}
      },
    }),

    // Conflicts
    getConflicts: builder.query<ContactSyncConflict[], void>({
      query: () => "/contacts/conflicts",
      providesTags: ["CONFLICTS"],
    }),
    getConflict: builder.query<ContactSyncConflict[], string>({
      query: (contactId) => `/contacts/conflicts/${contactId}`,
      providesTags: (_result, _error, contactId) => [{ type: "CONFLICTS", id: contactId }],
    }),
    resolveConflict: builder.mutation<any, ContactRow>({
      query: (contact) => ({
        url: `/contacts/conflicts/${contact.id}/resolve`,
        method: "POST",
        body: contact,
      }),
      invalidatesTags: (contact) => ["CONFLICTS", { type: "CONFLICTS", id: contact.id }],
      async onQueryStarted(contact, { dispatch, queryFulfilled }) {
        // Update GET: /contacts/conflicts/${contactId}
        const getConflictPatchResult = dispatch(
          contactApi.util.updateQueryData("getConflict", contact.id, (draft) => {
            const index = draft.findIndex((conflict) => conflict.entityId === contact.id);
            if (index > -1) {
              draft.splice(index, 1);
            }
          })
        );

        // Update GET: /contacts/conflicts
        const getConflictsPatchResult = dispatch(
          contactApi.util.updateQueryData("getConflicts", undefined, (draft) => {
            const index = draft.findIndex((conflict) => conflict.entityId === contact.id);
            if (index > -1) {
              draft.splice(index, 1);
            }
          })
        );

        try {
          await queryFulfilled;
        } catch {
          getConflictPatchResult.undo();
          getConflictsPatchResult.undo();

          // On failure, invalidate CONTACTS tag to trigger a re-fetch
          dispatch(
            contactApi.util.invalidateTags(["CONFLICTS", { type: "CONFLICTS", id: contact.id }])
          );
        }
      },
    }),
  }),
});

export const {
  usePrefetch,
  useGetContactSnapshotQuery,

  useLazyGetContactSnapshotQuery,
  useGetPinnedContactIdsQuery,
  useCreateContactMutation,
  useUpdateContactMutation,
  useDeleteContactMutation,
  useDeleteContactsMutation,
  useGetContactChangelogQuery,
  useGetContactGroupsQuery,
  useLazyGetContactGroupsQuery,
  useCreateContactGroupMutation,
  useCreateContactGroupsMutation,
  useUpdateContactGroupMutation,
  usePurgeContactGroupMutation,
  useGetConflictsQuery,
  useGetConflictQuery,
  useResolveConflictMutation,
} = contactApi;

export const {
  useGetContactsUpdatedQuery,
  useGetContactsPurgedQuery,
  useLazyGetContactsUpdatedQuery,
  useLazyGetContactsPurgedQuery,
} = contactNoCacheApi;
