import { FC, useCallback, useState } from "react";

import { isEmpty } from "../../../../helpers/array";
import Avatar from "../../../core/Avatar";
import { UserAltIcon } from "../../../core/Icon";
import TextInput from "../../../core/TextInput";
import BaseDetailsField from "../shared/BaseDetailsField";
import FieldSelect, { FieldSelectItem } from "../shared/FieldSelect";
import RemoveDetailsItemButton from "../shared/RemoveDetailsItemButton";
import TypeLabel from "../shared/TypeLabel";
import { ContactDataFieldProps } from "../types";

const NamesField: FC<ContactDataFieldProps> = ({ contactData, dispatch, isEditing }) => {
  // Required fields
  const givenName = contactData.givenName || "";
  const middleName = contactData.middleName || "";
  const surname = contactData.surname || "";

  // Optional fields
  const prefix = contactData.prefix || "";
  const suffix = contactData.suffix || "";
  const nickname = contactData.nickname || "";

  const [showPrefixField, setShowPrefixField] = useState(!!prefix);
  const [showSuffixField, setShowSuffixField] = useState(!!suffix);
  const [showNicknameField, setShowNicknameField] = useState(!!nickname);

  // Assemble select items from missing optional fields
  const nameTypeSelectItems: FieldSelectItem[] = [];
  if (!showPrefixField) {
    nameTypeSelectItems.push({ value: "prefix", displayName: "Prefix" });
  }
  if (!showSuffixField) {
    nameTypeSelectItems.push({ value: "suffix", displayName: "Suffix" });
  }
  if (!showNicknameField) {
    nameTypeSelectItems.push({ value: "nickname", displayName: "Nickname" });
  }

  const onClickAddMoreFields = useCallback((value: string) => {
    if (value === "prefix") {
      setShowPrefixField(true);
    } else if (value === "suffix") {
      setShowSuffixField(true);
    } else if (value === "nickname") {
      setShowNicknameField(true);
    }
  }, []);

  const onRemovePrefixField = useCallback(() => {
    dispatch({ type: "prefix", payload: undefined });
    setShowPrefixField(false);
  }, []);

  const onRemoveSuffixField = useCallback(() => {
    dispatch({ type: "suffix", payload: undefined });
    setShowSuffixField(false);
  }, []);

  const onRemoveNicknameField = useCallback(() => {
    dispatch({ type: "nickname", payload: undefined });
    setShowNicknameField(false);
  }, []);

  // Only visible when editing
  if (!isEditing) {
    return null;
  }

  return (
    <BaseDetailsField
      label="Names"
      isEditing={isEditing}
      icon={<UserAltIcon size="lg" className="mt-8 icon-color-purple" />}
    >
      {/*/!* Avatar *!/*/}
      {/*<dd key="avatar" className="flex flex-row items-center w-full">*/}
      {/*  <TypeLabel>Avatar</TypeLabel>*/}
      {/*  <Avatar*/}
      {/*    firstName={contactData.givenName}*/}
      {/*    lastName={contactData.surname}*/}
      {/*    imageUrl={contactData.pictureUrl}*/}
      {/*    className="w-16 h-16 mt-4 mb-2 text-2xl"*/}
      {/*  />*/}
      {/*</dd>*/}

      {/* Prefix */}
      {showPrefixField && (
        <dd key="prefix" className="flex flex-row w-full">
          <TypeLabel>Prefix</TypeLabel>
          <TextInput
            name="prefix"
            placeholder="Prefix"
            type="text"
            value={prefix}
            onChange={(event) => {
              dispatch({ type: "prefix", payload: event.target.value });
            }}
            className="flex-1 mr-12"
          />
          <RemoveDetailsItemButton onClickButton={onRemovePrefixField} />
        </dd>
      )}

      {/* Name */}
      <dd key="givenName" className="flex flex-row w-full">
        <TypeLabel>Name</TypeLabel>
        <TextInput
          name="givenName"
          placeholder="First"
          type="text"
          value={givenName}
          onChange={(event) => {
            dispatch({ type: "givenName", payload: event.target.value });
          }}
          className="flex-1 mr-2"
        />
        <TextInput
          name="middleName"
          placeholder="Middle"
          type="text"
          value={middleName}
          onChange={(event) => {
            dispatch({ type: "middleName", payload: event.target.value });
          }}
          className="flex-1 mr-2"
        />
        <TextInput
          name="surname"
          placeholder="Last"
          type="text"
          value={surname}
          onChange={(event) => {
            dispatch({ type: "surname", payload: event.target.value });
          }}
          className="flex-1 mr-20"
        />
      </dd>

      {/* Suffix */}
      {showSuffixField && (
        <dd key="suffix" className="flex flex-row w-full">
          <TypeLabel>Suffix</TypeLabel>
          <TextInput
            name="suffix"
            placeholder="Suffix"
            type="text"
            value={suffix}
            onChange={(event) => {
              dispatch({ type: "suffix", payload: event.target.value });
            }}
            className="flex-1 mr-12"
          />
          <RemoveDetailsItemButton onClickButton={onRemoveSuffixField} />
        </dd>
      )}

      {/* Nickname */}
      {showNicknameField && (
        <dd key="nickname" className="flex flex-row w-full">
          <TypeLabel>Nickname</TypeLabel>
          <TextInput
            name="nickname"
            placeholder="Nickname"
            type="text"
            value={nickname}
            onChange={(event) => {
              dispatch({ type: "nickname", payload: event.target.value });
            }}
            className="flex-1 mr-12"
          />
          <RemoveDetailsItemButton onClickButton={onRemoveNicknameField} />
        </dd>
      )}

      {/* Add name field */}
      {!isEmpty(nameTypeSelectItems) && (
        <dd key="add-more-fields" className="flex flex-row w-full pl-28">
          <FieldSelect
            title="Add more fields..."
            items={nameTypeSelectItems}
            onSelectItemValue={onClickAddMoreFields}
            shouldResetSelection
          />
        </dd>
      )}
    </BaseDetailsField>
  );
};

export default NamesField;
