import { UserIsActive } from "@shared/models/types";
import AppDisabled from "@web/components/AppDisabled";
import LoadingSpinner from "@web/components/loading/LoadingSpinner";
import { useUpdateUserTimeZone } from "@web/hooks/useUpdateUserTimeZone";
import TitledockWebSocket from "@web/integrations/titledockWS";
import { useGetUserQuery } from "@web/integrations/user/api";
import React, { FC, PropsWithChildren, useEffect, useState } from "react";

import Sidebar from "../components/sidebar";
import SidebarMobileNavBar from "../components/sidebar/SidebarMobileNavBar";
import useAuth from "../hooks/useAuth";
import { PageWithLayout } from "./types";

const AppLayout: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const { session } = useAuth();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const { data } = useGetUserQuery();

  useEffect(() => {
    let titledockWS: any;
    if (data && process.env.NEXT_PUBLIC_WS_URL) {
      titledockWS = new TitledockWebSocket(process.env.NEXT_PUBLIC_WS_URL);
    }
    return () => {
      if (titledockWS) {
        titledockWS.shutdown();
      }
    };
  }, [data]);

  useUpdateUserTimeZone();

  if (data && data.isWaitListed === false && data.user.isActive === UserIsActive.NO)
    return <AppDisabled />;

  return session && data ? (
    <div className="flex h-full">
      <Sidebar isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} />

      <div className="flex flex-col flex-1 min-w-0 overflow-hidden">
        <SidebarMobileNavBar setIsSidebarOpen={setIsSidebarOpen} />
        <div className="relative z-0 flex flex-1 overflow-hidden">
          <main className="relative z-0 flex-1 overflow-y-auto focus:outline-none xl:order-last">
            {/* Main content */}
            {children}
          </main>
        </div>
      </div>
    </div>
  ) : (
    <LoadingSpinner loadingText="Loading..." />
  );
};

const withAppLayout = (component: PageWithLayout) => {
  component.getLayout = (page) => <AppLayout>{page}</AppLayout>;
  return component;
};

export default withAppLayout;
