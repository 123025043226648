import { WebSocketReceiveBody, WebSocketSendBody } from "@shared/ws";

import { auth } from "./firebase";
let ws: WebSocket | null;
export type EventHandler = (event: MessageEvent, body: WebSocketReceiveBody) => any;

let registeredListeners: {
  [id: string]: EventHandler;
} = {};
let retryCount = 0;
let retryTimer: NodeJS.Timeout;

export default class TitledockWebSocket {
  public url?: string;
  constructor(url?: string) {
    if (url && !ws) {
      this.url = url;
      this.connect();
    }
  }

  private handleMessage = (event: MessageEvent) => {
    console.log({ event });
    if (Object.keys(registeredListeners).length > 0) {
      console.log(event.data);

      const data = JSON.parse(event.data);
      for (const id in registeredListeners) {
        if (registeredListeners[id]) {
          const fn = registeredListeners[id];
          try {
            fn(event, data);
          } catch (e) {
            console.error(e);
          }
        }
      }
    }
  };

  static getRegisteredListeners() {
    return registeredListeners;
  }

  static getWs() {
    return ws;
  }

  static getConnectionState() {
    switch (ws?.readyState) {
      case 0:
        return "connecting";
      case 1:
        return "open";
      case 2:
        return "closing";
      case 3:
        return "closed";
    }
  }

  public isReady() {
    return ws?.readyState === 1;
  }

  public isConnecting() {
    return ws?.readyState === 0;
  }

  private delayedRetry = () => {
    console.log("current retry", retryCount);
    const retryMultiplier = retryCount <= 5 ? 3 : 10;
    console.log("will retry in ", retryMultiplier, "s");
    retryTimer = setTimeout(this.connect, 1000 * retryMultiplier);
    retryCount++;
  };

  public connect = async () => {
    if (!this.url) {
      console.error("Cannot connect to WS, no url defined.");
    }
    if (!this.isReady() && !this.isConnecting()) {
      try {
        const idToken = await auth.currentUser?.getIdToken();
        if (idToken) {
          ws = new WebSocket(this.url + "?idToken=" + idToken);
          ws.addEventListener("open", (e) => {
            retryCount = 0;
            if (retryTimer) clearTimeout(retryTimer);
            console.log("WebSocket connected!", e);
          });

          ws.addEventListener("close", (e) => {
            console.log("connection closed");
            if (window.navigator.onLine) {
              this.delayedRetry();
            } else {
              window.addEventListener("online", this.delayedRetry);
            }
          });
          ws.addEventListener("message", this.handleMessage);
          ws.addEventListener("error", (e) => {
            console.error("WebSocket closed due to error.", e);
            ws?.close();
          });
          window.addEventListener("beforeunload", () => {
            this.shutdown();
          });
          return ws;
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  public subscribe(id: string, callback: EventHandler) {
    registeredListeners[id] = callback;
  }

  public unSubscribe(id: string) {
    if (registeredListeners[id]) {
      delete registeredListeners[id];
    }
  }

  public unsubscribeAll() {
    registeredListeners = {};
  }

  public extendTTL() {
    const body: WebSocketSendBody = {
      action: "extendTTL",
    };
    ws?.send(JSON.stringify(body));
  }

  public shutdown() {
    console.log("Shutting down WS...");
    this.unsubscribeAll();
    ws = null;
  }
}
