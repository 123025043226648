import { useEffect, useMemo } from "react";
import { useMediaQuery } from "react-responsive";
import createPersistedState from "use-persisted-state";

const useDarkModeState = createPersistedState("darkMode");

const useDarkMode = (): [boolean, (isDarkMode: boolean) => void] => {
  const systemPrefersDark = useMediaQuery(
    {
      query: "(prefers-color-scheme: dark)",
    },
    undefined
  );
  const [isDark, setIsDark] = useDarkModeState();

  const value = useMemo<boolean>(
    () => (isDark === undefined ? !!systemPrefersDark : !!isDark),
    [isDark, systemPrefersDark]
  );
  useEffect(() => {
    if (value) {
      document.body.classList.add("dark");
    } else {
      document.body.classList.remove("dark");
    }
  }, [value]);
  return [value, setIsDark];
};

export default useDarkMode;
