import { Transition } from "@headlessui/react";
import { ContactRow } from "@shared/models/Contact";
import { FC, PropsWithChildren, useMemo, useRef, useState } from "react";
import { usePopper } from "react-popper";
import { usePopperTooltip } from "react-popper-tooltip";

import { IconType } from "../../../core/Icon";

export type ContextualMenuFieldValue<T> = {
  fieldName: keyof ContactRow | "socialProfiles";
  value: T;
  index?: number;
};

export type ContextualMenuItem<T> = {
  icon: (payload: ContextualMenuFieldValue<T>) => IconType;
  tooltip: (payload: ContextualMenuFieldValue<T>) => string;
  action?: (payload: ContextualMenuFieldValue<T>) => void;
  isVisible?: (payload: ContextualMenuFieldValue<T>) => boolean;
  isPinned?: (payload: ContextualMenuFieldValue<T>) => boolean;
};

type ContextualMenuFieldProps<T = any> = {
  items: ContextualMenuItem<T>[];
  actionPayload: ContextualMenuFieldValue<T>;
};

const ContextualMenuField: FC<PropsWithChildren<ContextualMenuFieldProps>> = ({
  items,
  actionPayload,
  children,
}) => {
  const popperDivRef = useRef(null);
  const [referenceElement, setReferenceElement] = useState<HTMLSpanElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);

  const [showContextualMenu, setShowContextualMenu] = useState(false);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "right-start",
  });

  // Split between pinned and unpinned items (e.g. is default marker)
  const [pinnedItems, unpinnedItems] = useMemo(() => {
    const pinned: ContextualMenuItem<any>[] = [];
    const unpinned: ContextualMenuItem<any>[] = [];
    items.forEach((item) => {
      if (item.isPinned && item.isPinned(actionPayload)) {
        pinned.push(item);
      } else {
        unpinned.push(item);
      }
    });
    return [pinned, unpinned];
  }, [items]);

  return (
    <span
      onMouseEnter={() => setShowContextualMenu(true)}
      onMouseLeave={() => setShowContextualMenu(false)}
      className="flex py-2 pl-2 mr-2 -my-2 -ml-2 rounded-md group hover:bg-zinc-100 dark:hover:bg-zinc-900 pr-28"
    >
      {/* Main content */}
      <span className="overflow-hidden" ref={setReferenceElement}>
        {children}
      </span>
      <span className="ml-1.5">
        {!showContextualMenu &&
          pinnedItems
            .filter((item) => (item.isVisible ? item.isVisible(actionPayload) : true))
            .map((item, index) => (
              <ContextualMenuItemButton key={index} item={item} actionPayload={actionPayload} />
            ))}
      </span>

      {/* Contextual menu items, pinned and unpinned */}
      <div ref={popperDivRef} style={{ ...styles.popper, zIndex: 100 }} {...attributes.popper}>
        <Transition
          show={showContextualMenu}
          enter="transition ease-out duration-100"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition ease-in duration-75"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          beforeEnter={() => setPopperElement(popperDivRef.current)}
          afterLeave={() => setPopperElement(null)}
        >
          <div className="w-48">
            <span className="ml-1.5 -mt-px">
              {pinnedItems
                .filter((item) => (item.isVisible ? item.isVisible(actionPayload) : true))
                .map((item, index) => (
                  <ContextualMenuItemButton key={index} item={item} actionPayload={actionPayload} />
                ))}
            </span>
            <span className="invisible -mt-px group-hover:visible">
              {unpinnedItems
                .filter((item) => (item.isVisible ? item.isVisible(actionPayload) : true))
                .map((item, index) => (
                  <ContextualMenuItemButton key={index} item={item} actionPayload={actionPayload} />
                ))}
            </span>
          </div>
        </Transition>
      </div>
    </span>
  );
};

const ContextualMenuItemButton = ({
  item,
  actionPayload,
}: {
  item: ContextualMenuItem<any>;
  actionPayload: ContextualMenuFieldValue<any>;
}) => {
  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible } =
    usePopperTooltip();

  const Icon = item.icon(actionPayload);
  const tooltip = item.tooltip(actionPayload);

  return (
    <>
      <button
        className="px-1.5 text-zinc-400 dark:text-zinc-600 hover:text-zinc-500 dark:hover:text-zinc-200"
        ref={setTriggerRef}
        onClick={() => item.action?.(actionPayload)}
      >
        <Icon size="sm" />
      </button>
      {visible && (
        <div ref={setTooltipRef} {...getTooltipProps({ className: "tooltip-container" })}>
          <div {...getArrowProps({ className: "tooltip-arrow" })} />
          {tooltip}
        </div>
      )}
    </>
  );
};

export default ContextualMenuField;
