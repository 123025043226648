import { Transition } from "@headlessui/react";
import { User } from "@shared/models/User";
import { UserDelegation } from "@shared/models/UserDelegation";
import { selectAuthenticatedSession } from "@web/integrations/auth/selectors";
import { ChevronRightIcon } from "components/core/Icon";
import { MenuItem } from "components/core/MenuItem";
import { selectDelegatedUser } from "integrations/user/selectors";
import { UserState } from "integrations/user/types";
import { FC, useRef, useState } from "react";
import { usePopper } from "react-popper";
import { useSelector } from "react-redux";

type SidebarUserDelegationsItemProps = {
  userDelegations?: (UserDelegation & {
    profile?: Partial<Pick<User, "givenName" | "surname" | "isConfirmed" | "isActive">> &
      Pick<User, "email">;
  })[];
  onSelectDelegatedUser: (delegatedUser: UserState["delegatedUser"]) => void;
};

const SidebarUserDelegationsItem: FC<SidebarUserDelegationsItemProps> = ({
  userDelegations,
  onSelectDelegatedUser,
}) => {
  const session = useSelector(selectAuthenticatedSession);
  const delegatedUser = useSelector(selectDelegatedUser);
  const currentSelectedEmail = delegatedUser?.profile?.email || session?.email;

  const [showUserDelegations, setShowUserDelegations] = useState(false);

  const popperDivRef = useRef(null);
  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "right-start",
    strategy: "fixed",
    modifiers: [{ name: "offset", options: { offset: [-8, 4] } }],
  });

  return (
    <>
      <div
        className="justify-between w-full menu-item"
        onMouseEnter={() => setShowUserDelegations(true)}
        onMouseLeave={() => setShowUserDelegations(false)}
        ref={setReferenceElement}
      >
        Switch Accounts
        <ChevronRightIcon />
      </div>

      {/* Contextual menu items, pinned and unpinned */}
      <div
        ref={popperDivRef}
        style={{ ...styles.popper, zIndex: 100 }}
        {...attributes.popper}
        onMouseEnter={() => setShowUserDelegations(true)}
        onMouseLeave={() => setShowUserDelegations(false)}
      >
        <Transition
          show={showUserDelegations}
          enter="transition ease-out duration-100"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition ease-in duration-75"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          beforeEnter={() => setPopperElement(popperDivRef.current)}
          afterLeave={() => setPopperElement(null)}
        >
          <div className="z-50 mt-2 origin-top-right rounded-md shadow-lg divide-primary-y bg-primary ring-primary focus:outline-none overflow-clip">
            {session && (
              <MenuItem
                title={session.email || ""}
                key={session.email}
                onClick={() => {
                  if (currentSelectedEmail !== session.email) {
                    onSelectDelegatedUser(null);
                  }
                }}
                showCheckmark={currentSelectedEmail === session.email}
              />
            )}
            {userDelegations?.map((delegatedUser) => {
              const isSelected = currentSelectedEmail === delegatedUser.profile?.email;
              return (
                <MenuItem
                  title={delegatedUser.profile?.email || ""}
                  key={delegatedUser.delegatedUserId}
                  onClick={() => {
                    if (!isSelected) {
                      onSelectDelegatedUser(delegatedUser);
                    }
                  }}
                  showCheckmark={isSelected}
                />
              );
            })}
          </div>
        </Transition>
      </div>
    </>
  );
};

export default SidebarUserDelegationsItem;
