import classNames from "clsx";
import { ClipboardEvent, FC, useCallback, useEffect, useMemo, useRef } from "react";

import { IsDefault, PhysicalAddress } from "../../../../../services/src/shared/models/types";
import {
  formatAddressToString,
  getGoogleMapsLink,
  parseAddressString,
} from "../../../../helpers/address";
import { isEmpty } from "../../../../helpers/array";
import {
  CopySolidIcon,
  MapMarkedAltSolidIcon,
  MapMarkerAltIcon,
  PenSolidIcon,
  StarIcon,
  StarSolidIcon,
} from "../../../core/Icon";
import TextInput from "../../../core/TextInput";
import { showToast } from "../../../core/Toast";
import HighlightText from "../../../HighlightText";
import { generateTypeSelectItems } from "../helpers";
import BaseDetailsField from "../shared/BaseDetailsField";
import ContextualMenuField, { ContextualMenuItem } from "../shared/ContextualMenuField";
import MarkDefaultItemButton from "../shared/MarkDefaultItemButton";
import RemoveDetailsItemButton from "../shared/RemoveDetailsItemButton";
import TypeSelect from "../shared/TypeSelect";
import { ContactDataFieldProps } from "../types";

const PhysicalAddressesField: FC<ContactDataFieldProps> = ({
  contactData,
  dispatch,
  isEditing,
  directlyUpdateContactData,
  focusedField,
  onFocusField,
}) => {
  const addresses = contactData.physicalAddresses || [];
  const addressesWithIndices: { address: PhysicalAddress; originalIndex: number }[] = useMemo(
    () =>
      [...addresses]
        .map((address, index) => ({ address, originalIndex: index }))
        .sort((a, b) => (b.address.isDefault || 0) - (a.address.isDefault || 0)),
    [addresses]
  );

  const lastAddressRowInputRef = useRef<HTMLInputElement | null>(null);
  const focusedAddressRowInputRef = useRef<HTMLInputElement | null>(null);

  const focusOnLastAddressInput = useCallback(() => {
    lastAddressRowInputRef.current?.focus();
  }, [lastAddressRowInputRef]);

  useEffect(() => {
    if (focusedField && focusedField.fieldName === "physicalAddresses") {
      focusedAddressRowInputRef.current?.focus();
      focusedAddressRowInputRef.current?.scrollIntoView({ behavior: "auto", block: "center" });
    }
  }, [focusedField]);

  const addressContextualItems = useMemo<ContextualMenuItem<PhysicalAddress>[]>(() => {
    return [
      {
        icon: (payload) => (payload.value.isDefault ? StarSolidIcon : StarIcon),
        tooltip: (payload) => (payload.value.isDefault ? "Unmark as Default" : "Mark as Default"),
        action: (payload) => {
          if (payload.index !== undefined) {
            onMarkAddressDefaultAtIndex(!payload.value.isDefault, payload.index);
          }
        },
        isPinned: (payload) => payload.value.isDefault === IsDefault.YES,
      },
      {
        icon: () => MapMarkedAltSolidIcon,
        tooltip: () => "Open in maps",
        action: (payload) => {
          if (window) {
            const link = getGoogleMapsLink(payload.value);
            window.open(link, "_blank");
          }
        },
      },
      {
        icon: () => CopySolidIcon,
        tooltip: () => "Copy address to clipboard",
        action: (payload) => {
          if (navigator && navigator.clipboard) {
            const addressString = formatAddressToString(payload.value);
            navigator.clipboard.writeText(addressString);
            showToast({ title: "Address copied to clipboard!" });
          }
        },
      },
      {
        icon: () => PenSolidIcon,
        tooltip: () => "Edit address",
        action: (payload) => {
          onFocusField?.(payload);
        },
      },
    ];
  }, [contactData]);

  const onMarkAddressDefaultAtIndex = useCallback(
    (isDefault: boolean, index: number) => {
      // If marking an address default, first reset isDefault state for every object in the array
      const addressesToUpdate: PhysicalAddress[] = isDefault
        ? addresses.map((o) => ({
            ...o,
            isDefault: IsDefault.NO,
          }))
        : [...addresses];
      addressesToUpdate[index] = {
        ...addressesToUpdate[index],
        isDefault: isDefault ? IsDefault.YES : IsDefault.NO,
      };
      if (!isEditing) {
        directlyUpdateContactData?.({ physicalAddresses: addressesToUpdate });
      }
      updateAddresses(addressesToUpdate);
    },
    [addresses, isEditing]
  );

  // Resolve address types from the default list + types that are in-use
  const addressTypeSelectItems = generateTypeSelectItems(
    "physicalAddresses",
    addresses.map((address) => address.type)
  );

  const updateAddresses = (addresses: PhysicalAddress[]) => {
    dispatch({ type: "physicalAddresses", payload: addresses });
  };

  const onAddNewAddress = (type?: PhysicalAddress["type"]) => {
    const addressesToUpdate: PhysicalAddress[] = [
      ...addresses,
      { type: type || addressTypeSelectItems[0]?.value },
    ];
    updateAddresses(addressesToUpdate);

    // After a short delay, focus on the last address input (that was just added)
    setTimeout(() => {
      focusOnLastAddressInput();
    }, 100);
  };

  const onUpdateAddress = ({ index, data }: { index: number; data: Partial<PhysicalAddress> }) => {
    const addressesToUpdate: PhysicalAddress[] = [...addresses];
    addressesToUpdate[index] = {
      ...addressesToUpdate[index],
      ...data,
    };
    updateAddresses(addressesToUpdate);
  };

  const onRemoveAddressAtIndex = (index: number) => {
    const addressesToUpdate: PhysicalAddress[] = [...addresses];
    addressesToUpdate.splice(index, 1);
    updateAddresses(addressesToUpdate);
  };

  const onPasteAddress = ({
    index,
    event,
    textData,
  }: {
    index: number;
    event: ClipboardEvent<HTMLInputElement>;
    textData: string;
  }) => {
    if (textData) {
      const addressType = addresses[index]?.type || addressTypeSelectItems[0]?.value;
      const parsedAddress = parseAddressString(textData, addressType);
      if (parsedAddress.street && parsedAddress.city) {
        // Valid parsed address data. Prevent default event propagation and update address data.
        event.preventDefault();
        onUpdateAddress({ index, data: parsedAddress });
        return;
      }
    }
  };

  // Do not show for read-only mode if there are no addresses
  if (!isEditing && isEmpty(addresses)) {
    return null;
  }

  return (
    <BaseDetailsField
      label="Physical addresses"
      isEditing={isEditing}
      icon={<MapMarkerAltIcon size="lg" className="icon-color-purple" />}
      className={isEditing ? "space-y-10" : "space-y-3"}
    >
      {isEditing ? (
        <>
          {addressesWithIndices.map(({ address, originalIndex }, index) => {
            const isLastAdressInput = index === addresses.length - 1;
            const isFocusedField =
              focusedField?.fieldName === "physicalAddresses" &&
              focusedField?.index === originalIndex;
            const isDefault = address.isDefault === IsDefault.YES;

            return (
              <dd key={index} className="flex flex-row w-full group" tabIndex={-1}>
                <TypeSelect
                  items={addressTypeSelectItems}
                  initialItemId={address.type}
                  onSelectItem={(item) =>
                    onUpdateAddress({ index: originalIndex, data: { type: item.value } })
                  }
                  createNewItemTitle="Custom"
                />
                <div className="flex-1 mr-2 space-y-3">
                  <TextInput
                    name="addressLine1"
                    placeholder="Address line 1"
                    type="text"
                    value={address.street}
                    onChange={(event) =>
                      onUpdateAddress({
                        index: originalIndex,
                        data: { street: event.target.value },
                      })
                    }
                    onPaste={(event) => {
                      const textData = event.clipboardData.getData("Text");
                      onPasteAddress({
                        index: originalIndex,
                        event,
                        textData,
                      });
                    }}
                    forwardedRef={
                      (isFocusedField && focusedAddressRowInputRef) ||
                      (isLastAdressInput && lastAddressRowInputRef) ||
                      undefined
                    }
                    className="w-full"
                  />
                  <TextInput
                    name="addressLine2"
                    placeholder="Address line 2"
                    type="text"
                    value={address.line2}
                    onChange={(event) =>
                      onUpdateAddress({ index: originalIndex, data: { line2: event.target.value } })
                    }
                    className="w-full"
                  />
                  <TextInput
                    name="city"
                    placeholder="City"
                    type="text"
                    value={address.city}
                    onChange={(event) =>
                      onUpdateAddress({ index: originalIndex, data: { city: event.target.value } })
                    }
                    className="flex-1"
                  />
                  <div className="flex flex-row space-x-2">
                    <TextInput
                      name="state"
                      placeholder="State"
                      type="text"
                      value={address.state}
                      onChange={(event) =>
                        onUpdateAddress({
                          index: originalIndex,
                          data: { state: event.target.value },
                        })
                      }
                      className="flex-1"
                    />
                    <TextInput
                      name="postalCode"
                      placeholder="Postal code"
                      type="text"
                      value={address.postalCode}
                      onChange={(event) =>
                        onUpdateAddress({
                          index: originalIndex,
                          data: { postalCode: event.target.value },
                        })
                      }
                      className="flex-1"
                    />
                  </div>
                  <TextInput
                    name="country"
                    placeholder="Country"
                    type="text"
                    value={address.country}
                    onChange={(event) =>
                      onUpdateAddress({
                        index: originalIndex,
                        data: { country: event.target.value },
                      })
                    }
                    className="flex-1"
                  />
                </div>
                <MarkDefaultItemButton
                  isDefault={isDefault}
                  onClickButton={() => onMarkAddressDefaultAtIndex(!isDefault, originalIndex)}
                  tooltip={isDefault ? "Unmark as Default" : "Mark as Default"}
                  className={classNames(
                    "group-hover:visible",
                    isDefault ? "visible opacity-90" : "invisible opacity-70"
                  )}
                />
                <RemoveDetailsItemButton
                  onClickButton={() => onRemoveAddressAtIndex(originalIndex)}
                  tooltip="Remove Address"
                  className="invisible group-hover:visible opacity-70 group-hover:opacity-100"
                />
              </dd>
            );
          })}
          <dd key="add-new-address" className="flex flex-row w-full">
            <TypeSelect
              items={addressTypeSelectItems}
              onSelectItem={(item) => onAddNewAddress(item.value)}
              shouldResetSelection
              createNewItemTitle="Custom"
            />
            <button
              className="px-3 text-sm font-medium text-color-purple"
              onClick={() => onAddNewAddress()}
            >
              Add an address
            </button>
          </dd>
        </>
      ) : (
        addressesWithIndices.map(({ address, originalIndex }, index) => {
          const link = getGoogleMapsLink(address);
          return (
            <dd key={index}>
              <ContextualMenuField
                items={addressContextualItems}
                actionPayload={{
                  fieldName: "physicalAddresses",
                  value: address,
                  index: originalIndex,
                }}
              >
                <a
                  href={link}
                  target="_blank"
                  rel="noreferrer"
                  className="text-primary text-underline"
                >
                  <div>
                    <HighlightText value={address.street} />
                  </div>
                  {address.line2 && (
                    <div>
                      {address.line2
                        ? address.line2.split("\\n").map((str, i) => (
                            <div key={i}>
                              <HighlightText value={str} />
                            </div>
                          ))
                        : ""}
                    </div>
                  )}
                  <div>
                    {address.city && address.state && <HighlightText value={`${address.city},`} />}{" "}
                    <HighlightText value={address.state} />{" "}
                    <HighlightText value={address.postalCode} />
                  </div>
                  <div>
                    <HighlightText value={address.country} />
                  </div>
                </a>
                <div className="uppercase text-label">{address.type}</div>
              </ContextualMenuField>
            </dd>
          );
        })
      )}
    </BaseDetailsField>
  );
};

export default PhysicalAddressesField;
