import { ArbitraryDate } from "@shared/models/types";
import { isValid, parse } from "date-fns";

import { MONTHS, OMIT_YEAR } from "../constants";

/**
 * Pretty print date format used by arbitrary date / birthday fields.
 * @param date Date in yyyy-MM-dd format
 * @returns Pretty printed string
 */
export function prettyPrintArbitraryDateString(date?: string): string {
  if (!date) return "";

  const [year, month, day] = date.split("-");

  return `${MONTHS[Number(month) - 1]} ${Number(day)}${
    year && String(year) !== `${OMIT_YEAR}` ? `, ${year}` : ""
  }`;
}

/**
 * Check if passed in date is a valid JS Date object
 * @param date Potential date object
 * @returns Boolean whether or not passed date is a valid Date object
 */
export function isValidDateInstance(date: any) {
  return date instanceof Date && !isNaN(date as any);
}

/**
 * Use date-fns isValid to see if date string is valid.
 * @param date Date in yyyy-MM-dd format
 * @returns Whether or not date is valid
 */
export function isValidDateString(date?: string): boolean {
  if (!date) return false;

  const parsedDate = parse(date, "yyyy-MM-dd", new Date());
  return isValid(parsedDate);
}

export function parseArbitraryDate(dateStr: ArbitraryDate["value"] | null) {
  if (!dateStr) return null;
  const [year, month, day] = dateStr.split("-");

  return {
    month,
    day,
    year: String(year) !== String(OMIT_YEAR) ? year : undefined,
  };
}
