import RestoreSection from "@web/components/settings/restore";
import Collaborate from "@web/components/tools/collaborate";
import DeduplicateSection from "@web/components/tools/deduplicate";
import SyncManagement from "@web/components/tools/syncManagement";

export const ToolsTabs = [
  {
    name: "sync",
    href: "sync",
    component: SyncManagement,
    props: { isNewSync: false },
  },
  {
    name: "sync",
    href: "sync/new",
    component: SyncManagement,
    props: { isNewSync: true },
    hidden: true,
  },
  {
    name: "deduplicate",
    href: "deduplicate",
    component: DeduplicateSection,
    title: "Deduplicate",
    props: {},
  },
  {
    name: "collaborate",
    href: "collaborate",
    title: "Collaborate",
    component: Collaborate,
    props: {},
  },
  { name: "restore", href: "restore", component: RestoreSection, title: "Restore", props: {} },
];

export const defaultToolRoute = ToolsTabs[0].href;
