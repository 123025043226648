import { ContactRow } from "@shared/models/Contact";
import { formatAddressToString } from "@web/helpers/address";
import { getFullName } from "@web/helpers/contact";
import { isValidDateString, prettyPrintArbitraryDateString } from "@web/helpers/date";
import { FC, useMemo } from "react";

import {
  BirthdayCakeIcon,
  BriefcaseIcon,
  CalendarIcon,
  CommentsAltIcon,
  EnvelopeIcon,
  GlobeIcon,
  MapMarkerAltIcon,
  PenIcon,
  PhoneIcon,
  UserAltIcon,
  UsersIcon,
} from "../../core/Icon";
import ContactPreviewRow, { ContactPreviewRowProps } from "./ContactPreviewRow";

type ContactPreviewProps = {
  contact: ContactRow;
  className?: string;
};

export const primaryPreviewRowTypes: ContactPreviewRowProps["type"][] = [
  "email",
  "phoneNumber",
  "physicalAddress",
  "relative",
  "date",
];

export function usePreviewRows(contact: Partial<ContactRow> = {}) {
  return useMemo<ContactPreviewRowProps[]>(() => {
    const rows: ContactPreviewRowProps[] = [];

    // Names
    const fullName = getFullName(contact);
    if (fullName) {
      rows.push({
        text: fullName,
        type: "fullName",
        icon: <UserAltIcon size="sm" className="icon-color-purple" />,
      });
    }

    // Job
    const jobDescriptions = [
      [contact.jobTitle, contact.departmentName].filter(Boolean).join(" - "),
      contact.companyName,
    ].filter((a) => !!a?.trim());
    if (jobDescriptions && jobDescriptions.length > 0) {
      rows.push({
        text: jobDescriptions.join(", "),
        type: "jobDescription",
        icon: <BriefcaseIcon size="sm" className="icon-color-purple" />,
      });
    }

    // Emails
    rows.push(
      ...(contact.emails || []).map((email) => ({
        text: email.value,
        label: email.type,
        type: "email" as const,
        icon: <EnvelopeIcon size="sm" className="icon-color-purple" />,
      }))
    );

    // Phone numbers
    rows.push(
      ...(contact.phoneNumbers || []).map((phoneNumber) => ({
        text: phoneNumber.value,
        label: phoneNumber.type,
        type: "phoneNumber" as const,
        icon: <PhoneIcon size="sm" className="icon-color-purple" />,
      }))
    );

    // Physical addresses
    rows.push(
      ...(contact.physicalAddresses || []).map((address) => ({
        text: formatAddressToString(address),
        label: address.type,
        type: "physicalAddress" as const,
        icon: <MapMarkerAltIcon size="sm" className="icon-color-purple" />,
      }))
    );

    // ImHandles
    rows.push(
      ...(contact.imHandles || []).map((imHandle) => ({
        text: imHandle.value?.replace("xmpp:", ""),
        label: imHandle.service || imHandle.type,
        type: "imHandle" as const,
        icon: <CommentsAltIcon size="sm" className="icon-color-purple" />,
      }))
    );

    // Web pages
    rows.push(
      ...(contact.webPages || []).map((webPage) => ({
        text: webPage.value,
        type: "webPage" as const,
        label: webPage.service || webPage.type,
        icon: <GlobeIcon size="sm" className="icon-color-purple" />,
      }))
    );

    // Relatives
    rows.push(
      ...(contact.relatives || []).map((relative) => ({
        text: relative.value,
        label: relative.label,
        type: "relative" as const,
        icon: <UsersIcon />,
      }))
    );

    // Birthday
    if (isValidDateString(contact.birthday)) {
      rows.push({
        type: "birthday",
        text: prettyPrintArbitraryDateString(contact.birthday),
        icon: <BirthdayCakeIcon size="sm" className="icon-color-purple" />,
      });
    }

    // Arbitrary dates
    rows.push(
      ...(contact.dates || []).map((date) => ({
        text: prettyPrintArbitraryDateString(date.value),
        type: "date" as const,
        label: date.label,
        icon: <CalendarIcon size="sm" className="icon-color-purple" />,
      }))
    );

    // Notes
    if (contact.notes) {
      rows.push({
        text: contact.notes,
        type: "notes",
        icon: <PenIcon size="sm" className="icon-color-purple" />,
      });
    }
    return rows;
  }, [contact]);
}

const ContactPreview: FC<ContactPreviewProps> = ({ contact, className }) => {
  const previewRows = usePreviewRows(contact);

  return (
    <ul className={className}>
      {previewRows.map((item, index) => (
        <ContactPreviewRow
          key={index}
          text={item.text}
          label={item.label}
          icon={item.icon}
          type={item.type}
        />
      ))}
    </ul>
  );
};

export default ContactPreview;
