import { getCurEpochMs, getMs } from "@packages/common/dateTime";
import { ContactRow } from "@shared/models/Contact";
import { isEmpty } from "@web/helpers/array";
import { getIdIndex } from "@web/integrations/contact/helpers";
import { queryFetch } from "@web/integrations/redux/baseQuery";
import TitledockWebSocket from "@web/integrations/titledockWS";
import { satisfies } from "compare-versions";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useDebouncedCallback } from "use-debounce";

import { ALL_ALPHABET_KEYS } from "../constants";
import {
  ContactRemoved,
  ContactSnapshotState,
  useGetPinnedContactIdsQuery,
  useLazyGetContactSnapshotQuery,
  useLazyGetContactsPurgedQuery,
  useLazyGetContactsUpdatedQuery,
} from "../integrations/contact/api";
import { get, set } from "../integrations/idbKeyVal";
import { selectDelegatedUser } from "../integrations/user/selectors";

const CONTACT_NAMESPACE = "TITLEDOCK_USER_CONTACTS";

const timestamps = {
  updatesFetchedAt: 0, // last call to /contacts, or /contacts?updatedAt= or /contacts?/deletedAt=
  edgeCacheTimestamp: 0,
};

export function setUpdatesLastFetchedAt(timestamp: number) {
  timestamps.updatesFetchedAt = timestamp;
}

export function setEdgeCacheTimestamp(timestamp: number) {
  if (timestamp) timestamps.edgeCacheTimestamp = timestamp;
}

export function getEdgeCacheTimestamp() {
  return timestamps.edgeCacheTimestamp;
}

type LocalCachedState = {
  isLocalCache?: boolean;
  isLoaded?: boolean;
  contactSnapshot?: ContactSnapshotState;
  contactFetchedTimestamp?: number;
  didFetchUpdates?: boolean;
  contactsUpdated?: ContactRow[];
  contactsPurged?: ContactRemoved[];
  delegatedUserId?: string | null;
  appVersion?: string;
};

const LOCAL_CACHE_REQUIRED_SEM_VER = "~1.6.0";

// execute read before react mounts, further minimizes delay
const readContactsLocalCache =
  typeof window !== "undefined"
    ? get<LocalCachedState>(CONTACT_NAMESPACE).then((res) => {
        try {
          if (satisfies(res?.appVersion || "", LOCAL_CACHE_REQUIRED_SEM_VER)) return res;
        } catch {}
        return undefined;
      })
    : Promise.resolve(undefined);

export type SortableKeys = "email" | "surname" | "givenName" | "nickname" | "companyName";

export function getSortedContacts(contacts: ContactRow[], sortKey: SortableKeys) {
  return contacts.sort((a: ContactRow, b: ContactRow) => {
    const contactA = a as ContactRow;
    const contactB = b as ContactRow;
    if (sortKey === "email") {
      const [emailA] = contactA?.emails || [{ value: "" }];
      const [emailB] = contactB?.emails || [{ value: "" }];
      return emailA.value.toLowerCase().localeCompare(emailB.value.toLowerCase()) || 0;
    }

    if (sortKey === "surname") {
      const keyA = `${contactA.surname}${contactA.givenName}`.toLowerCase();
      const keyB = `${contactB.surname}${contactB.givenName}`.toLowerCase();
      return keyA.localeCompare(keyB);
    }

    if (["givenName", "nickName", "companyName"].includes(sortKey)) {
      return contactA[sortKey]?.localeCompare(contactB[sortKey] || "") || 0;
    }
    return 0;
  });
}

export function useSortedContacts(contacts: ContactRow[] | undefined, sortKey: SortableKeys) {
  const sortPredicate = useCallback(
    (a: ContactRow, b: ContactRow) => {
      const contactA = a as ContactRow;
      const contactB = b as ContactRow;
      if (sortKey === "email") {
        const [emailA] = contactA?.emails || [{ value: "" }];
        const [emailB] = contactB?.emails || [{ value: "" }];
        return emailA.value.toLowerCase().localeCompare(emailB.value.toLowerCase()) || 0;
      }

      if (sortKey === "surname") {
        const keyA = `${contactA.surname}${contactA.givenName}`.toLowerCase();
        const keyB = `${contactB.surname}${contactB.givenName}`.toLowerCase();
        return keyA.localeCompare(keyB);
      }

      if (["givenName", "nickName", "companyName"].includes(sortKey)) {
        return contactA[sortKey]?.localeCompare(contactB[sortKey] || "") || 0;
      }
      return 0;
    },
    [sortKey]
  );

  const sortedContacts = useMemo(() => {
    if (!contacts) return undefined;
    return (contacts || []).slice().sort(sortPredicate);
  }, [contacts, sortPredicate]);

  return {
    sortedContacts,
    sortPredicate,
  };
}

export function useSortedContactsGroupedByLetter(
  sortedContacts: ContactRow[] | undefined,
  sortKey: SortableKeys
) {
  const shouldSortByLetter = useMemo(
    () => ["givenName", "surname", "nickName"].includes(sortKey),
    [sortKey]
  );

  const sortedContactsByLetter = useMemo(() => {
    const contactsByLetter: { [letter: string]: ContactRow[] } = {};

    if (shouldSortByLetter && sortedContacts) {
      ALL_ALPHABET_KEYS.forEach((letter) => {
        contactsByLetter[letter] = [];
      });
      for (const contact of sortedContacts) {
        const keyVal = contact[sortKey as "givenName" | "surname" | "nickname"] || "#";
        const letter = keyVal.charAt(0).toUpperCase();
        if (!contactsByLetter[letter]) {
          contactsByLetter["#"].push(contact);
        } else contactsByLetter[letter].push(contact);
      }

      // Sort the last bucket
      const lastBucket = contactsByLetter["#"];
      if (lastBucket.length > 0) {
        contactsByLetter["#"] = lastBucket.sort((a, b) => {
          const oppositeSortKey = sortKey === "givenName" ? "surname" : "givenName";
          return a[oppositeSortKey]?.localeCompare(b[oppositeSortKey] || "") || 0;
        });
      }

      // Remove empty buckets
      ALL_ALPHABET_KEYS.forEach((letter) => {
        if (isEmpty(contactsByLetter[letter])) delete contactsByLetter[letter];
      });
    }

    return {
      sortedContacts: shouldSortByLetter ? contactsByLetter : sortedContacts,
      count: sortedContacts ? sortedContacts.length : 0,
    };
  }, [sortedContacts, shouldSortByLetter, sortKey]);

  return { sortedContactsByLetter, shouldSortByLetter };
}

export async function fetchPaginatedContacts(
  page: number,
  startKey: string,
  callback?: (contacts: ContactRow[]) => Promise<any>
) {
  const curTimestamp = getCurEpochMs();

  const {
    data: contactUpdates,
    headers,
    error,
  } = await queryFetch<ContactRow[]>(`/contacts?page=${page}&startKey=${startKey}`);

  if (contactUpdates && contactUpdates.length > 0) {
    setUpdatesLastFetchedAt(curTimestamp);
    if (callback) await callback(contactUpdates);
  }
  return { startKey: headers?.get("next-start-key"), page: page + 1, error };
}

function getDefaultTimestampToFetch() {
  return getCurEpochMs() - getMs("12h");
}

export function useContactSnapshot({ skip = false }) {
  const delegatedUser = useSelector(selectDelegatedUser);
  const [fetch, result] = useLazyGetContactSnapshotQuery();

  const [state, setState] = useState(result);

  useEffect(() => {
    if (!skip) fetch();
  }, [delegatedUser?.delegatedUserId, skip]);

  useEffect(() => {
    setState(result);
  }, [result]);

  return state;
}

export function useRealtimeContactUpdate({
  timestamp = getDefaultTimestampToFetch(),
  skip = false,
  enableWs = true,
}) {
  const [refetchContactsUpdated, contactsUpdated] = useLazyGetContactsUpdatedQuery();
  const [refetchContactsPurged, contactsPurged] = useLazyGetContactsPurgedQuery();

  const [state, setState] = useState({ contactsUpdated, contactsPurged });

  const debouncedFetchContactsUpdated = useDebouncedCallback(refetchContactsUpdated, 2000, {
    leading: true,
  });

  const debouncedFetchContactsPurged = useDebouncedCallback(refetchContactsPurged, 2000, {
    leading: true,
  });

  useEffect(() => {
    if (enableWs) {
      const tdWs = new TitledockWebSocket();
      tdWs.subscribe("/contacts", async (_e, body) => {
        if (body.entity === "/contacts") {
          await debouncedFetchContactsUpdated(timestamp);
        }
      });
      tdWs.subscribe("/contacts/purged", async (_e, body) => {
        if (body.entity === "/contacts/purged") {
          await debouncedFetchContactsPurged(timestamp);
        }
      });

      return () => {
        tdWs.unSubscribe("/contacts");
        tdWs.unSubscribe("/contacts/purged");
      };
    }
  }, [timestamp, enableWs]);

  useEffect(() => {
    if (timestamp && !skip) {
      debouncedFetchContactsUpdated(timestamp);
      debouncedFetchContactsPurged(timestamp);
    }
  }, [timestamp, skip, debouncedFetchContactsUpdated, debouncedFetchContactsPurged]);

  useEffect(() => {
    setState({ contactsPurged, contactsUpdated });
  }, [contactsUpdated, contactsPurged]);

  return state;
}

export function useLocalCachedSnapshot({ fetchUpdates = true }) {
  const delegatedUser = useSelector(selectDelegatedUser);
  const delegatedUserId = delegatedUser?.delegatedUserId || null;

  const [state, setState] = useState<LocalCachedState | null>();

  useEffect(() => {
    readContactsLocalCache.then((local) => {
      if (
        local &&
        local.contactSnapshot &&
        local.contactSnapshot.delegatedUserId === local.delegatedUserId &&
        (local.contactSnapshot.delegatedUserId && delegatedUser
          ? local.contactSnapshot.delegatedUserId === delegatedUser.delegatedUserId
          : true)
      ) {
        console.log("set local contact cache", Date.now());
        local.isLocalCache = true;
        local.isLoaded = true;
        setState(local);
      } else {
        setState(null);
      }
    });
  }, []);

  const shouldReloadSnapshot =
    state === null || state?.contactSnapshot?.delegatedUserId !== delegatedUserId;

  const contactSnapshot = useContactSnapshot({ skip: !shouldReloadSnapshot });

  useEffect(() => {
    setState((prevState) => {
      const payload = {
        ...prevState,
        contactSnapshot: contactSnapshot.data,
        contactsUpdated: [],
        contactsPurged: [],
        delegatedUserId,
        didFetchUpdates: false,
        isLoaded: contactSnapshot.isSuccess,
        isLocalCache: false,
        appVersion: process.env.WEB_APP_VERSION,
      };
      set(CONTACT_NAMESPACE, payload).catch((err) => {
        console.error(err, "Contacts local cache update failed.");
      });
      return payload;
    });
  }, [contactSnapshot, delegatedUserId]);

  const { contactsUpdated, contactsPurged } = useRealtimeContactUpdate({
    timestamp: state?.contactSnapshot?.timestamp,
    skip: !fetchUpdates
      ? true
      : state?.contactSnapshot?.delegatedUserId !== delegatedUserId ||
        !state?.contactSnapshot?.timestamp,
  });

  useEffect(() => {
    if (contactsUpdated.isSuccess && contactsPurged.isSuccess) {
      setState((prevState) => {
        const payload = {
          ...prevState,
          contactsUpdated: contactsUpdated.data,
          contactsPurged: contactsPurged.data,
          contactFetchedTimestamp: Math.max(
            contactsUpdated.fulfilledTimeStamp,
            contactsPurged.fulfilledTimeStamp
          ),
          delegatedUserId,
          didFetchUpdates: true,
          appVersion: process.env.WEB_APP_VERSION,
        };
        set(CONTACT_NAMESPACE, payload).catch((err) => {
          console.error(err, "Contacts local cache update failed.");
        });
        return payload;
      });
    }
  }, [
    contactsPurged.data,
    contactsPurged.isSuccess,
    contactsPurged.fulfilledTimeStamp,
    contactsUpdated.data,
    contactsUpdated.isSuccess,
    contactsUpdated.fulfilledTimeStamp,
    delegatedUserId,
  ]);

  return state || {};
}

export function useContacts() {
  const {
    contactsPurged,
    contactsUpdated,
    contactSnapshot,
    didFetchUpdates,
    contactFetchedTimestamp,
    isLoaded,
  } = useLocalCachedSnapshot({});

  const contacts = useMemo(() => {
    if (!didFetchUpdates) return contactSnapshot?.contacts || [];
    const allContacts: ContactRow[] = [];
    const updatedContactIndex: { [id: string]: number | null } = getIdIndex(contactsUpdated || []);
    const purgedContactIndex = getIdIndex(
      (contactsPurged || []).map(({ contactId }) => ({
        id: contactId,
      }))
    );
    for (const contact of contactSnapshot?.contacts || []) {
      if (!purgedContactIndex[contact.id]) {
        if (typeof updatedContactIndex[contact.id] !== "undefined" && contactsUpdated) {
          const i = updatedContactIndex[contact.id];
          if (i !== null) {
            allContacts.push(contactsUpdated[i]);
            updatedContactIndex[contact.id] = null;
          }
        } else allContacts.push(contact);
      }
    }

    // left over after merging updates, newly created contacts
    if (contactsUpdated) {
      for (const id in updatedContactIndex) {
        const i = updatedContactIndex[id];
        if (i !== null) {
          const contact = contactsUpdated[i];
          allContacts.push(contact);
        }
      }
    }
    return allContacts;
  }, [contactSnapshot?.contacts, contactsPurged, contactsUpdated, didFetchUpdates]);

  const contactIndex = useMemo(() => {
    return getIdIndex(contacts);
  }, [contacts]);

  return {
    contacts,
    contactIndex,
    isLoaded,
    timestamp: Math.max(contactFetchedTimestamp || 0, contactSnapshot?.timestamp || 0),
    delegatedUserId: contactSnapshot?.delegatedUserId,
  };
}

export function usePinnedContacts() {
  const { data: pinnedContactIds } = useGetPinnedContactIdsQuery();
  const { contactIndex, contacts } = useContacts();

  return useMemo(() => {
    return (pinnedContactIds || []).map(({ id }) => {
      return contacts[contactIndex[id]];
    });
  }, [contactIndex, contacts, pinnedContactIds]);
}
