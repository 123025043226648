import { Transition } from "@headlessui/react";
import Button, { ButtonVariant } from "@web/components/core/Button";
import { StopwatchIcon } from "@web/components/core/Icon";
import { useOnClickOutside } from "@web/hooks/useOutsideClick";
import classNames from "clsx";
import {
  ChangeEvent,
  ChangeEventHandler,
  Fragment,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import { useKey } from "rooks";

export default function useCollaborateExpiration() {
  const [days, setDays] = useState<number | null>(null);
  const [hours, setHours] = useState<number | null>(null);

  const [showDropdown, setShowDropdown] = useState(false);

  const toggleDropdown = useCallback(() => setShowDropdown((prevState) => !prevState), []);
  const hideDropdown = useCallback(() => setShowDropdown(false), []);

  const daysOnChange = useCallback(
    ({ target, key, ...e }: ChangeEvent<HTMLInputElement> & KeyboardEvent) => {
      if (key === ".") e.preventDefault();
      const val = Math.max(parseInt(target.value), 0) || null;
      setDays(val);
    },
    []
  );

  const hoursOnChange = useCallback(
    ({ target, key, ...e }: ChangeEvent<HTMLInputElement> & KeyboardEvent) => {
      if (key === ".") e.preventDefault();
      const val = Math.max(parseInt(target.value), 0) || null;
      setHours(val !== null && val > 23 ? 23 : val);
    },
    []
  );

  const reset = useCallback(() => {
    setDays(null);
    setHours(null);
    hideDropdown();
  }, []);

  const ref = useRef(null);
  useOnClickOutside(ref, hideDropdown);

  useKey(["Enter", "Escape"], (e) => {
    e.stopPropagation();
    e.preventDefault();
    hideDropdown();
  });

  const ExpirationSelector = useMemo(() => {
    const hasExpiration = days || hours;
    return (
      <div className="relative">
        <button
          onClick={toggleDropdown}
          className="relative inline-flex items-center rounded-md py-2 px-2 bg-gray-50 text-sm font-medium text-gray-500 whitespace-nowrap hover:bg-gray-100 sm:px-3"
        >
          <StopwatchIcon
            size="lg"
            className={classNames(
              hasExpiration ? "text-gray-500" : "text-gray-300",
              "flex-shrink-0 h-5 w-5 sm:-ml-1"
            )}
            aria-hidden="true"
          />
          <span
            className={classNames(
              hasExpiration ? "text-gray-900" : "",
              "hidden truncate sm:ml-2 sm:block"
            )}
          >
            {hasExpiration
              ? `Expires in ${days ? days + " days" : ""} ${hours ? hours + " hours" : ""}`
              : "No expiration"}
          </span>
        </button>

        <Transition
          show={showDropdown}
          as={Fragment}
          enter="transition ease-in duration-100"
          enterFrom="opacity-0 -translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-out duration-100"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 -translate-y-1"
        >
          <div
            className="origin-top-right absolute z-50 right-0 -mr-1 w-72 rounded-md shadow-lg bg-primary ring-1 ring-black ring-opacity-5 focus:outline-none"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="option-menu-button"
          >
            <div className="p-4" role="none" ref={ref}>
              <div className="flex -space-x-px">
                <div className="w-1/2 flex-1 min-w-0">
                  <label
                    htmlFor="card-expiration-date"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Days
                  </label>
                  <input
                    type="number"
                    className="focus:ring-indigo-500 focus:border-indigo-500 relative block w-full rounded-none bg-transparent focus:z-10 sm:text-sm border-gray-300"
                    value={days || ""}
                    onChange={daysOnChange}
                  />
                </div>
                <div className="flex-1 min-w-0">
                  <label htmlFor="exipre-hours" className="block text-sm font-medium text-gray-700">
                    Hours
                  </label>
                  <input
                    type="number"
                    className="focus:ring-indigo-500 focus:border-indigo-500 relative block w-full rounded-none bg-transparent focus:z-10 sm:text-sm border-gray-300"
                    value={hours || ""}
                    onChange={hoursOnChange}
                  />
                </div>
              </div>
              <div className="mt-4 flex space-x-4 px-2">
                <Button
                  variant={ButtonVariant.Secondary}
                  className="w-0 flex-1 flex justify-end"
                  onClick={reset}
                >
                  Cancel
                </Button>

                <Button
                  variant={ButtonVariant.Primary}
                  className="w-0 flex-1 flex justify-end"
                  onClick={hideDropdown}
                >
                  Set
                </Button>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    );
  }, [showDropdown, days, hours]);

  return { expiration: days || hours ? { days, hours } : null, ExpirationSelector };
}
