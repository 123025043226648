import { objKeys } from "@packages/common/object";
import { ContactRow } from "@shared/models/Contact";
import { usePreviewRows } from "@web/components/contacts/preview";
import ContactPreviewRow, {
  ContactPreviewRowVariant,
} from "@web/components/contacts/preview/ContactPreviewRow";
import Avatar from "@web/components/core/Avatar";
import Button, { ButtonVariant } from "@web/components/core/Button";
import { BookmarkIcon, MinusCircleSolidIcon, PlusCircleSolidIcon } from "@web/components/core/Icon";
import JobDescription from "@web/components/tools/deduplicate/JobDescription";
import { getFullName } from "@web/helpers/contact";
import { getContactDiffForMerge } from "@web/helpers/contactDiff";
import classNames from "clsx";
import { FC, useCallback, useMemo, useState } from "react";

const NUM_PREVIEW_ROWS = 3;

const DupeContactItem: FC<{
  contact: ContactRow;
  mainContact: ContactRow;
  ignored: ContactRow["id"][];
  setIgnored: (contactIds: ContactRow["id"][]) => void;
  setMain: (contactId: ContactRow["id"]) => void;
  showAllFields?: boolean;
}> = ({ contact, mainContact, showAllFields = false, ignored = [], setIgnored, setMain }) => {
  const { diff, matched } = useMemo(
    () => getContactDiffForMerge(mainContact, contact),
    [contact, mainContact]
  );

  const diffRows = usePreviewRows(diff as ContactRow);
  const matchedRows = usePreviewRows(matched as ContactRow);

  const [showAllDiffRows, setShowAllDiffRow] = useState(showAllFields);
  const [showAllMatchedRows, setShowAllMatchedRow] = useState(showAllFields);

  const selected = !ignored.includes(contact.id);

  const onToggle = useCallback(() => {
    const contactIdsIndex: { [id: ContactRow["id"]]: true } = {};
    for (const contactId of ignored) {
      contactIdsIndex[contactId] = true;
    }
    if (contactIdsIndex[contact.id]) {
      delete contactIdsIndex[contact.id];
    } else {
      contactIdsIndex[contact.id] = true;
    }
    setIgnored(objKeys(contactIdsIndex) as ContactRow["id"][]);
  }, [contact.id, ignored]);

  const onSetMain = useCallback(() => {
    const contactIdsIndex: { [id: ContactRow["id"]]: true } = {};
    for (const contactId of ignored) {
      contactIdsIndex[contactId] = true;
    }
    if (contactIdsIndex[contact.id]) {
      delete contactIdsIndex[contact.id];
    }
    setIgnored(objKeys(contactIdsIndex) as ContactRow["id"][]);
    setMain(contact.id);
  }, [contact.id, ignored]);

  return (
    <li className="group p-1" tabIndex={-1}>
      <div
        className={classNames("block xl:flex xl:outline-0", {
          "rounded-lg outline outline-offset-2 outline-purple-700": selected,
        })}
      >
        <div
          tabIndex={1}
          className={classNames(
            { "xl:outline outline-purple-700": selected },
            { "opacity-40 overflow-hidden": !selected },
            "bg-primary w-full dark:bg-zinc-800 rounded-lg shadow dark:divide-zinc-600 dark:border-zinc-600 dark:border dark:bg-opacity-80 w-54 overflow-x-scroll"
          )}
          style={
            !selected
              ? {
                  maxHeight: "200px",
                }
              : undefined
          }
        >
          <div className="flex p-2">
            <div className="flex-shrink-0 flex items-center justify-center w-16">
              <Avatar firstName={contact.givenName} lastName={contact.surname} />
            </div>
            <div className="flex flex-1 items-center justify-between truncate">
              <div className="p-4 text-sm truncate">
                <div className="text-primary hover:text-gray-600 truncate">
                  {getFullName(contact)}
                </div>
                <JobDescription contact={contact} />
              </div>
            </div>
          </div>
          <div className="text-sm py-2">
            {(showAllMatchedRows || showAllFields
              ? matchedRows
              : matchedRows.slice(0, NUM_PREVIEW_ROWS)
            ).map((row, index) => (
              <ContactPreviewRow
                className="px-6"
                key={index}
                text={row.text}
                label={row.label}
                icon={row.icon}
                variant={ContactPreviewRowVariant.Additive}
              />
            ))}
            {matchedRows.length > NUM_PREVIEW_ROWS && (
              <div className="relative py-2 flex justify-center">
                <button
                  className="px-2 text-sm text-gray-500"
                  onClick={() => setShowAllMatchedRow((prev) => !prev)}
                >
                  {selected && !showAllFields
                    ? showAllMatchedRows
                      ? "Hide"
                      : `Show all (${matchedRows.length})`
                    : null}
                </button>
              </div>
            )}
          </div>
          <div className="text-sm py-2">
            {(showAllDiffRows || showAllFields
              ? diffRows
              : diffRows.slice(0, NUM_PREVIEW_ROWS)
            ).map((row, index) => (
              <ContactPreviewRow
                className="px-6"
                key={index}
                text={row.text}
                label={row.label}
                icon={row.icon}
                variant={ContactPreviewRowVariant.Subtractive}
              />
            ))}
            {diffRows.length > NUM_PREVIEW_ROWS && (
              <div className="relative flex justify-center py-2">
                <button
                  className="px-2 text-sm text-gray-500"
                  onClick={() => setShowAllDiffRow((prev) => !prev)}
                >
                  {!showAllFields
                    ? showAllDiffRows
                      ? "Hide"
                      : `Show all (${diffRows.length})`
                    : null}
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="xl:space-y-6 mt-1 xl:mt-0 flex-shrink xl:w-52 xl:pl-6">
          <Button variant={ButtonVariant.Secondary} onClick={onToggle} className="w-full">
            {selected ? <MinusCircleSolidIcon /> : <PlusCircleSolidIcon />}
            <span className="px-2 whitespace-nowrap">{selected ? "Ignore" : "Mark for Merge"}</span>
          </Button>
          {selected && (
            <Button variant={ButtonVariant.Secondary} className="w-full" onClick={onSetMain}>
              <BookmarkIcon />
              <span className="px-2 whitespace-nowrap">Set as Main</span>
            </Button>
          )}
        </div>
      </div>
    </li>
  );
};

export default DupeContactItem;
