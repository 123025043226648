export function pluralize(
  count: number,
  singular: string,
  plural: string,
  showCount: boolean = false
) {
  const pluralized = count === 1 ? singular : plural;
  return (showCount ? `${count} ` : "") + pluralized;
}

export function addSpaceBetweenCapitalLetters(str?: string) {
  if (!str) {
    return "";
  }
  return str.replace(/([A-Z])/g, " $1").trim();
}

export function generateRandomString(length: number = 20): string {
  let result = "";
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
}

const UUID_REGEX =
  /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/i;

export function isValidUuid(uuid?: string | null): boolean {
  return uuid ? UUID_REGEX.test(uuid) : false;
}

const NUMERIC_REGEXP = /[\d]*[.]{0,1}[\d]+/g;

export function extractNumbers(string?: string) {
  return string ? string.match(NUMERIC_REGEXP) : [];
}

export function renderLineBreaks(str: string) {}
