import { ContactRow } from "@shared/models/Contact";
import { FC } from "react";

const JobDescription: FC<{ contact: ContactRow }> = ({ contact }) => {
  return (
    <>
      {contact.jobTitle && contact.departmentName && (
        <p className="mt-0.5 text-label truncate">
          <span>{contact.jobTitle}&nbsp;</span>{" "}
          {contact.jobTitle && contact.departmentName && ` - `}
          &nbsp;
          <span>{contact.departmentName}&nbsp;</span>{" "}
        </p>
      )}
      {contact.companyName && (
        <p className="mt-0.5 text-label">
          <span>{contact.companyName || ""}&nbsp;</span>
        </p>
      )}
    </>
  );
};

export default JobDescription;
