import {
  DangerColor,
  SecondaryColor,
  SuccessColor,
  WarningColor,
} from "@web/components/core/colorVariant";
import classNames from "clsx";
import { FC, PropsWithChildren } from "react";

export const SuccessBadge: FC<PropsWithChildren<unknown>> = ({ children }) => (
  <span
    className={classNames(
      "inline-flex items-center px-2 py-0.5 rounded text-xs font-medium",
      SuccessColor
    )}
  >
    {children}
  </span>
);

export const SecondaryBadge: FC<PropsWithChildren<unknown>> = ({ children }) => (
  <span
    className={classNames(
      "inline-flex items-center px-2 py-0.5 rounded text-xs font-medium",
      SecondaryColor
    )}
  >
    {children}
  </span>
);

export const WarningBadge: FC<PropsWithChildren<unknown>> = ({ children }) => (
  <span
    className={classNames(
      "inline-flex items-center px-2 py-0.5 rounded text-xs font-medium",
      WarningColor
    )}
  >
    {children}
  </span>
);

export const DangerBadge: FC<PropsWithChildren<unknown>> = ({ children }) => (
  <span
    className={classNames(
      "inline-flex items-center px-2 py-0.5 rounded text-xs font-medium",
      DangerColor
    )}
  >
    {children}
  </span>
);
