import { getCurEpochMs } from "@packages/common/dateTime";
import uuid from "@packages/common/uuid";
import { ContactRow } from "@shared/models/Contact";
import { getEntityId } from "@shared/models/helpers";
import { IsDeleted, IsDoNotSync } from "@shared/models/types";
import { isValid, parse } from "date-fns";
import isEmpty from "lodash/isEmpty";
import partition from "lodash/partition";

/**
 * Use date-fns isValid to see if date string is valid.
 * @param date Date in yyyy-MM-dd format
 * @returns Whether or not date is valid
 */
export function isValidDateString(date?: string): boolean {
  if (!date) return false;

  const parsedDate = parse(date, "yyyy-MM-dd", new Date());
  return isValid(parsedDate);
}

/*
 * todo:  sanitizeContactData below is a copy of
 *  web/helpers/contact.ts:sanitizeContactData, so
 *  we need to bundle this out in the future
 *
 */
export function sanitizeContactData<T extends ContactRow | Partial<ContactRow>>(
  contactData: T
): { validContact: T; invalidContact?: T } {
  const [validEmails, invalidEmails] = partition(
    contactData.emails,
    (email) => !!email.value?.trim()
  );
  const [validPhoneNumbers, invalidPhoneNumbers] = partition(
    contactData.phoneNumbers,
    (phoneNumber) => !!phoneNumber.value?.trim()
  );
  const [validImHandles, invalidImHandles] = partition(
    contactData.imHandles,
    (imHandle) => !!imHandle.value?.trim()
  );
  const [validWebPages, invalidWebPages] = partition(
    contactData.webPages,
    (webPage) => !!webPage.value?.trim()
  );
  const [validPhysicalAddresses, invalidPhysicalAddresses] = partition(
    contactData.physicalAddresses,
    (physicalAddress) =>
      !!physicalAddress.street?.trim() ||
      !!physicalAddress.line2?.trim() ||
      !!physicalAddress.city?.trim() ||
      !!physicalAddress.postalCode?.trim() ||
      !!physicalAddress.state?.trim() ||
      !!physicalAddress.country?.trim()
  );
  const [validDates, invalidDates] = partition(contactData.dates, (date) =>
    isValidDateString(date.value)
  );
  const [validRelatives, invalidRelatives] = partition(
    contactData.relatives,
    (relative) => !!relative.value?.trim()
  );

  const validContact = {
    ...contactData,
    emails: validEmails,
    phoneNumbers: validPhoneNumbers,
    imHandles: validImHandles,
    webPages: validWebPages,
    physicalAddresses: validPhysicalAddresses,
    dates: validDates,
    relatives: validRelatives,
  };
  const invalidContact: ContactRow | Partial<ContactRow> = {
    emails: invalidEmails,
    phoneNumbers: invalidPhoneNumbers,
    imHandles: invalidImHandles,
    webPages: invalidWebPages,
    physicalAddresses: invalidPhysicalAddresses,
    dates: invalidDates,
    relatives: invalidRelatives,
  };
  const hasInvalidValues =
    !isEmpty(invalidEmails) ||
    !isEmpty(invalidPhoneNumbers) ||
    !isEmpty(invalidImHandles) ||
    !isEmpty(invalidWebPages) ||
    !isEmpty(invalidPhysicalAddresses) ||
    !isEmpty(invalidDates) ||
    !isEmpty(invalidRelatives);

  return { validContact, invalidContact: hasInvalidValues && (invalidContact as any) };
}

export function jsonContactToContactRow(
  contactJson: any[],
  userId: string,
  isDoNotSync: IsDoNotSync = IsDoNotSync.NO
) {
  // sanitize json object and convert to ContactRow[]
  const contactRows: ContactRow[] = [];
  const updatedAt = getCurEpochMs();

  contactJson.forEach((contactRow) => {
    const { validContact: sanitizedContact } = sanitizeContactData(contactRow);
    const newContact = {
      ...sanitizedContact,
      id: getEntityId({ id: uuid(), isContactGroup: false, remoteApiId: "" }),
      userId,
      source: "user",
      isDeleted: IsDeleted.NO,
      isDoNotSync,
      updatedAt,
      userId_isDoNotSync: `${userId}_${
        isDoNotSync === IsDoNotSync.YES ? IsDoNotSync.YES : IsDoNotSync.NO
      }`,
      userId_isDeleted: `${userId}_${IsDeleted.NO}`,
      userId_isReadOnly: `${userId}_${IsDeleted.NO}`,
    } as ContactRow;
    contactRows.push(newContact);
  });

  return contactRows;
}
