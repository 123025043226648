import { removeUndefinedFromObj } from "./object";
import { NoUndefinedField } from "./types";

export function removeUndefinedFromList<T>(list: T) {
  for (const i in list) {
    if (typeof list[i] === "undefined") {
      // @ts-ignore
      list.splice(Number(i), 1);
    } else if (Array.isArray(list[i])) {
      removeUndefinedFromList(list[i]);
    } else if (typeof list[i] === "object") {
      removeUndefinedFromObj(list[i]);
    }
  }
  return list as unknown as NoUndefinedField<T>;
}

export function getRandomNodeFromArray<T>(list: T[]) {
  return list[Math.floor(Math.random() * list.length)];
}

export function getUniqueListBy<T>(list: T[], key: keyof T) {
  const preservedItemsWithoutKey = list.filter((i) => typeof i[key] === "undefined");
  return [...new Map(list.map((item) => [item[key], item])).values(), ...preservedItemsWithoutKey];
}

export function uniqueOnlyPredicate<T>(value: T, index: number, list: T[]) {
  return list.indexOf(value) === index;
}

export function chunkList<T>(list: T[], size: number): T[][] {
  return list.reduce((acc: T[][], _: T, i: number) => {
    if (i % size === 0) acc.push(list.slice(i, i + size));
    return acc;
  }, []);
}
