import Modal from "@web/components/core/Modal";
import { Dispatch, FC, SetStateAction } from "react";

const ZapierIntegrationModal: FC<{
  isModalOpen: boolean;
  setIsClosed: Dispatch<SetStateAction<boolean>>;
}> = ({ isModalOpen, setIsClosed }) => {
  return (
    <Modal
      isModalOpen={isModalOpen}
      setIsClosed={setIsClosed}
      className="align-middle sm:max-w-lg sm:w-full p-6 sm:p-8"
    >
      <div>
        <img src="/zapier-logo-v2.svg" alt="logo" />
      </div>
    </Modal>
  );
};

export default ZapierIntegrationModal;
