import { format, parse } from "date-fns";
import { ClipboardEventHandler, FC, KeyboardEventHandler, MutableRefObject, useState } from "react";

import { OMIT_YEAR } from "../../constants";
import { isEmpty } from "../../helpers/array";
import { isValidDateInstance } from "../../helpers/date";
import { extractNumbers } from "../../helpers/string";
import TextInput from "./TextInput";

type SimpleDateInputProps = {
  name: string;
  id?: string;
  label?: string;
  initialDateString?: string;
  onChangeDateString?: (dateString: string) => void;
  singleColumn?: boolean;
  disabled?: boolean;
  className?: string;
  forwardedRef?: MutableRefObject<HTMLInputElement | null>;
};

const calculateDateStrings = (
  dateString: string
): { displayString: string; serverString: string } => {
  const numberComponents = extractNumbers(dateString);
  if (!numberComponents || isEmpty(numberComponents)) {
    // Invalid date
    return { displayString: dateString, serverString: "" };
  }

  // Process input string and append OMIT_YEAR if year component is not found
  let processedString = dateString;
  const year = numberComponents
    .map((component) => parseInt(component, 10))
    .find((number) => number >= 1000);
  if (!year) {
    let separator = " ";
    if (dateString.includes("-")) {
      separator = "-";
    } else if (dateString.includes("/")) {
      separator = "/";
    } else if (dateString.includes(",")) {
      separator = ",";
    }
    processedString = `${OMIT_YEAR}${separator}${processedString}`;
  }

  // First try to parse using our server format, then use browser implementation
  let parsedDate = parse(processedString, "yyyy-MM-dd", new Date());
  if (!isValidDateInstance(parsedDate)) {
    parsedDate = new Date(processedString);
  }

  // If parsed date is valid, re-format the date to server format and return, omitting OMIT_YEAR for
  // display value in the process.
  if (isValidDateInstance(parsedDate)) {
    const serverDateString = format(parsedDate, "yyyy-MM-dd");
    const displayDateString = format(
      parsedDate,
      parsedDate.getFullYear() === OMIT_YEAR ? "MMMM d" : "MMMM d, yyyy"
    );
    return { displayString: displayDateString, serverString: serverDateString };
  } else {
    console.warn("Invalid date instance trying to parse: ", processedString);
    return { displayString: dateString, serverString: "" };
  }
};

const SimpleDateInput: FC<SimpleDateInputProps> = ({
  name,
  id,
  label,
  initialDateString,
  onChangeDateString,
  singleColumn = true,
  disabled = false,
  className,
  forwardedRef,
}) => {
  // Parse server string
  const { displayString: initialDisplayString } = calculateDateStrings(initialDateString || "");
  const [inputString, setInputString] = useState<string>(initialDisplayString);

  const handleOnBlur = () => {
    const { displayString, serverString } = calculateDateStrings(inputString);
    setInputString(displayString);
    onChangeDateString?.(serverString);
  };

  const handleOnPaste: ClipboardEventHandler<HTMLInputElement> = (event) => {
    const textData = event.clipboardData.getData("Text");
    const { displayString, serverString } = calculateDateStrings(textData);
    setInputString(displayString);
    onChangeDateString?.(serverString);
  };

  const handleOnKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.key === "Enter") {
      (event.target as any).blur();
    }
  };

  return (
    <TextInput
      name={name}
      id={id}
      label={label}
      singleColumn={singleColumn}
      disabled={disabled}
      className={className}
      forwardedRef={forwardedRef}
      value={inputString}
      onChange={(e) => setInputString(e.target.value)}
      onPaste={handleOnPaste}
      onKeyDown={handleOnKeyDown}
      onBlur={handleOnBlur}
    />
  );
};

export default SimpleDateInput;
