import { Dialog, Transition } from "@headlessui/react";
import { useGetContactGroupsQuery } from "integrations/contact/api";
import { isEmpty } from "lodash";
import Link from "next/link";
import { FC, Fragment, useMemo } from "react";

import { ContactsRoute, GroupsRoute, ToolsRoute, UpdatesRoute } from "../../helpers/routes";
import { TimesIcon } from "../core/Icon";
import { SidebarGroupsSection } from "./SidebarGroupsSection";
import SidebarProfileItem from "./SidebarProfileItem";
import SidebarRouteItem from "./SidebarRouteItem";

type SidebarProps = {
  isSidebarOpen: boolean;
  setIsSidebarOpen: (open: boolean) => void;
};

const Sidebar: FC<SidebarProps> = ({ isSidebarOpen, setIsSidebarOpen }) => {
  const { data: groups } = useGetContactGroupsQuery();
  const sidebarContent = useMemo(() => {
    return (
      <div className="flex flex-col h-screen pt-1.5">
        {/* Logo */}
        <Link href="/dashboard" passHref>
          <div className="relative flex items-center h-12 px-4 cursor-pointer">
            <img
              className="absolute w-auto h-12 dark:visible"
              src="/titledock-logo-light-text.svg"
              alt="TitleDock light text logo"
            />
            <img
              className="absolute w-auto h-12 dark:invisible"
              src="/titledock-logo-dark-text.svg"
              alt="TitleDock dark text logo"
            />
          </div>
        </Link>

        {/* Routes */}
        <nav className="flex-1 mt-2 overflow-y-auto" aria-label="Sidebar">
          <div className="px-2 space-y-1">
            <SidebarRouteItem route={ContactsRoute} />
            <SidebarRouteItem route={UpdatesRoute} />
            <SidebarRouteItem route={ToolsRoute} />
            <div className="w-full py-2">
              <div className="w-full h-px bg-zinc-200 dark:bg-zinc-600" />
            </div>
            <SidebarRouteItem route={GroupsRoute} actionTitle={isEmpty(groups) ? "" : "manage"} />
            <SidebarGroupsSection groups={groups} />
          </div>
        </nav>

        {/* Profile item */}
        <SidebarProfileItem />
      </div>
    );
  }, [groups]);

  return (
    <>
      {/* Mobile sidebar */}
      <Transition.Root show={isSidebarOpen} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 z-40 flex lg:hidden" onClose={setIsSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-opacity-75 bg-zinc-600" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex flex-col flex-1 w-full max-w-xs bg-secondary focus:outline-none">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 pt-2 -mr-12">
                  <button
                    type="button"
                    className="flex items-centerustify-center w-10 h-10 ml-1 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setIsSidebarOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <TimesIcon className="w-6 h-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </Transition.Child>

              {/* Main content */}
              {sidebarContent}
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14" aria-hidden="true">
            {/* Force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>

      {/* Desktop sidebar */}
      <div className="hidden lg:flex lg:flex-shrink-0">
        <div className="flex flex-col w-60">
          <div className="flex flex-col flex-1 min-h-0 border-r bg-secondary border-primary">
            {/* Main content */}
            {sidebarContent}
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
