import { addDays, format } from "date-fns";

/**
 * Create a link to add an all day event to Google calendar. Follows conventions found in
 * https://github.com/InteractionDesignFoundation/add-event-to-calendar-docs/blob/main/services/google.md
 * @param eventName Name of the event
 * @param date Date of the event
 * @returns
 */
export function buildGoogleCalendarEventLink(eventName: string, date: Date): string {
  let eventLink = "";
  try {
    const textString = encodeURIComponent(eventName);
    const datesString = `${format(date, "yyyyMMddTHHmmSS")}/${format(
      addDays(date, 1),
      "yyyyMMddTHHmmSS"
    )}`;
    eventLink = `https://www.google.com/calendar/render?action=TEMPLATE&text=${textString}&dates=${datesString}`;
  } catch (error) {
    console.error("Error building date string. Error: ", error);
  }

  return eventLink;
}
