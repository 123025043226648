import { objKeys } from "@packages/common/object";
import { ContactRow } from "@shared/models/Contact";
import { PendingContactRow } from "@shared/models/PendingContact";
import { getDuplicatesFromContacts, getMatchingPairs, ScoredResult } from "@web/helpers/dedupe";
import { useContacts } from "@web/hooks/useContacts";
import usePrevious from "@web/hooks/usePrevious";
import { get, set } from "@web/integrations/idbKeyVal";
import { selectDelegatedUser } from "@web/integrations/user/selectors";
import { satisfies } from "compare-versions";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDebouncedCallback } from "use-debounce";

const CONTACT_DUPLICATES_NAMESPACE = "TITLEDOCK_CONTACT_DUPLICATES";

type DedupeContactRow = {
  mainContactId: ContactRow["id"];
  contacts: { [id: string]: ContactRow };
};

type ContactDeduplicateState = {
  duplicates: DedupeContactRow[];
  timestamp: number;
  isLoading: boolean;
  isLocal: boolean;
  delegatedUserId?: string | null;
};

const LOCAL_CACHE_REQUIRED_SEM_VER = "~1.6.0";
const appVersion = process.env.WEB_APP_VERSION;

const readDuplicatesLocalCache =
  typeof window !== "undefined"
    ? get<ContactDeduplicateState & { appVersion: string }>(CONTACT_DUPLICATES_NAMESPACE).then(
        (res) => {
          try {
            if (satisfies(res?.appVersion || "", LOCAL_CACHE_REQUIRED_SEM_VER)) return res;
          } catch {}
          return undefined;
        }
      )
    : Promise.resolve(undefined);

const initialState = {
  delegatedUserId: undefined,
  duplicates: [],
  isLoading: true,
  isLocal: false,
  timestamp: 0,
};

export function useDuplicateLocalCache() {
  const delegatedUser = useSelector(selectDelegatedUser);
  const delegatedUserId = delegatedUser?.delegatedUserId || null;
  const [local, setLocal] = useState<ContactDeduplicateState>({
    ...initialState,
    delegatedUserId: delegatedUser?.delegatedUserId,
  });

  useEffect(() => {
    readDuplicatesLocalCache.then((local) => {
      if (
        local &&
        (local?.duplicates || []).length > 0 &&
        local?.delegatedUserId === delegatedUserId
      ) {
        setLocal({ ...local, isLocal: true, isLoading: false });
      } else {
        const state = { ...initialState, delegatedUserId };
        setLocal(state);
      }
    });
  }, [delegatedUserId]);

  const setLocalCache = useCallback(
    (duplicates: DedupeContactRow[], timestamp: number) => {
      const state: ContactDeduplicateState = {
        duplicates,
        timestamp,
        isLoading: false,
        isLocal: false,
        delegatedUserId,
      };
      set(CONTACT_DUPLICATES_NAMESPACE, { ...state, appVersion }).catch((err) => {
        console.error(err, "Contacts duplicates local cache update failed.");
      });
      setLocal(state);
    },
    [delegatedUserId]
  );

  return { local, setLocalCache };
}

export function useDeduplicateContactIds() {
  const { contacts, timestamp, delegatedUserId: snapshotDelegatedUserId } = useContacts();
  const delegatedUser = useSelector(selectDelegatedUser);
  const delegatedUserId = delegatedUser?.delegatedUserId || null;
  const prevContactSnapshotTimestamp = usePrevious(timestamp);

  const { local, setLocalCache } = useDuplicateLocalCache();

  const debouncedGetDuplicatesFromContacts = useDebouncedCallback(
    (contacts: ContactRow[], timestamp: number) => {
      console.log("got contacts, finding dupes");
      getDuplicatesFromContacts(contacts).then((keyGroupsToMerge) => {
        console.log("got keyGroupsToMerge", Date.now());
        const duplicates = keyGroupsToMerge.map((dedupeGroup) => {
          const dedupeContactRow: DedupeContactRow = { contacts: {}, mainContactId: "" };
          for (const contactId in dedupeGroup) {
            const contactKey = dedupeGroup[contactId];
            dedupeContactRow.contacts[contactId] = contacts[Number(contactKey)];
          }
          const contactList = Object.values(dedupeContactRow.contacts);

          // find default main contact, user can change this later
          let [mainContact] = contactList
            .filter(({ updatedAt }) => updatedAt)
            .sort((a, b) => b.updatedAt - a.updatedAt);

          if (!mainContact) {
            const [mostDetailedContact] = contactList.sort(
              (a, b) => objKeys(b).length - objKeys(a).length
            );
            mainContact = mostDetailedContact;
          }

          dedupeContactRow.mainContactId = mainContact.id;
          return dedupeContactRow;
        });
        setLocalCache(duplicates, timestamp);
      });
    },
    2000,
    {
      leading: true,
    }
  );

  useEffect(() => {
    if (
      contacts &&
      timestamp !== prevContactSnapshotTimestamp &&
      timestamp !== local.timestamp &&
      snapshotDelegatedUserId === delegatedUserId
    ) {
      debouncedGetDuplicatesFromContacts(contacts, timestamp);
    }
  }, [
    contacts,
    delegatedUserId,
    local.timestamp,
    prevContactSnapshotTimestamp,
    snapshotDelegatedUserId,
    timestamp,
  ]);

  return local;
}

export function useFindMatchingPairsBySearchList(
  contactSearchList: (ContactRow | PendingContactRow)[]
) {
  const { contacts, timestamp, delegatedUserId: snapshotDelegatedUserId } = useContacts();
  const delegatedUser = useSelector(selectDelegatedUser);
  const delegatedUserId = delegatedUser?.delegatedUserId || null;
  const prevContactSnapshotTimestamp = usePrevious(timestamp);

  const [local, setLocal] = useState<{
    pairs: { matched: ScoredResult; contact: ContactRow | PendingContactRow }[];
    delegatedUserId: string | null;
    timestamp: number;
  }>({
    pairs: [],
    delegatedUserId,
    timestamp: 0,
  });

  const debouncedPairs = useDebouncedCallback(
    async (contacts: ContactRow[], timestamp: number) => {
      const pairs = await getMatchingPairs(contacts, contactSearchList);
      setLocal({ pairs, delegatedUserId, timestamp });
    },
    2000,
    { leading: true }
  );

  useEffect(() => {
    if (
      contacts &&
      timestamp !== prevContactSnapshotTimestamp &&
      timestamp !== local.timestamp &&
      snapshotDelegatedUserId === delegatedUserId
    ) {
      debouncedPairs(contacts, timestamp);
    }
  }, [
    contacts,
    debouncedPairs,
    delegatedUserId,
    prevContactSnapshotTimestamp,
    snapshotDelegatedUserId,
    timestamp,
  ]);

  return local;
}
