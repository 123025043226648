import { ContactRow } from "@shared/models/Contact";
import { FC } from "react";

import Avatar from "../../../core/Avatar";
import Button, { ButtonVariant } from "../../../core/Button";
import { EditSolidIcon, EllipsisVIcon } from "../../../core/Icon";
import MenuButton, { MenuButtonMenuItem } from "../../../core/MenuButton";
import HighlightText from "../../../HighlightText";

type ContactDetailsHeaderProps = {
  contactData: ContactRow;
  isEditing: boolean;
  isUpdatingContact: boolean;
  onClickEdit: () => void;
  onClickSave: () => void;
  onClickCancel: () => void;
  onClickShowHistory: (contact: ContactRow) => void;
  onClickDeleteContact: (contact: ContactRow) => void;
};

const ContactDetailsHeader: FC<ContactDetailsHeaderProps> = ({
  contactData,
  isEditing,
  isUpdatingContact,
  onClickEdit,
  onClickSave,
  onClickCancel,
  onClickShowHistory,
  onClickDeleteContact,
}) => {
  const jobDescriptions = [
    [contactData.jobTitle, contactData.departmentName].filter(Boolean).join(" - "),
    contactData.companyName,
  ];

  // Instantiate more button menu items
  const moreButtonMenuItems: MenuButtonMenuItem[] = [
    { id: "history", title: "Show History", onClickHandler: () => onClickShowHistory(contactData) },
    { id: "delete", title: "Delete", onClickHandler: () => onClickDeleteContact(contactData) },
  ];

  return (
    <div className="sticky top-0 z-10 px-4 pt-6 mx-auto sm:px-6 lg:px-8 bg-primary">
      <div className="flex flex-row items-center justify-between space-x-2 sm:space-x-4">
        {/* Avatar + Name */}
        {isEditing ? (
          <h1 className="text-lg font-semibold truncate text-zinc-900 dark:text-white sm:font-bold sm:text-3xl">
            Edit Contact
          </h1>
        ) : (
          <>
            <div className="flex flex-row items-center space-x-4">
              <Avatar
                firstName={contactData.givenName}
                lastName={contactData.surname}
                imageUrl={contactData.pictureUrl}
                className="w-10 h-10 text-md sm:text-3xl sm:h-20 sm:w-20"
              />
            </div>
            <div className="flex-1 min-w-0 -mt-1 sm:mt-0">
              <h1 className="mb-1 text-lg font-semibold truncate text-zinc-900 dark:text-white sm:font-bold sm:text-3xl">
                <HighlightText
                  matchPhrase={false}
                  value={`${contactData.prefix || ""} ${contactData.givenName || ""} ${
                    contactData.middleName || ""
                  } ${contactData.surname || ""} ${contactData.suffix || ""}`}
                />
              </h1>
              {jobDescriptions.map((desc, index) => (
                <h4 key={index} className="-mt-0.5 text-xs text-zinc-500 truncate sm:text-base">
                  <HighlightText value={desc} matchPhrase={false} />
                </h4>
              ))}
            </div>
          </>
        )}

        {/* Action items */}
        <div className="flex flex-row items-center space-x-2">
          {isEditing ? (
            <>
              <Button
                variant={ButtonVariant.Success}
                disabled={isUpdatingContact}
                onClick={onClickSave}
                hotkey={["Alt", "KeyS"]}
              >
                <span className="px-4">{isUpdatingContact ? "Saving…" : "Save"}</span>
              </Button>
              <Button
                variant={ButtonVariant.Secondary}
                disabled={isUpdatingContact}
                onClick={onClickCancel}
                hotkey={["Escape"]}
              >
                <span className="px-2">Cancel</span>
              </Button>
            </>
          ) : (
            <>
              <Button
                icon={<EditSolidIcon className="mb-px" />}
                onClick={onClickEdit}
                hotkey={["Alt", "KeyE"]}
              >
                <span className="hidden sm:block">Edit</span>
              </Button>
              <MenuButton menuItems={moreButtonMenuItems}>
                <div className="w-5 h-5" aria-hidden="true">
                  <EllipsisVIcon />
                </div>
                <span className="hidden sm:block">More</span>
              </MenuButton>
            </>
          )}
        </div>
      </div>

      {/* Divider */}
      <div className="mt-4 sm:mt-6 divider" />
    </div>
  );
};

export default ContactDetailsHeader;
