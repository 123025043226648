import { getCurEpochMs } from "@packages/common/dateTime";
import uuid from "@packages/common/uuid";
import type { Contact, ContactDbRowFields, ContactRow } from "@shared/models/Contact";
import type { ContactGroup } from "@shared/models/ContactGroup";
import type { RemoteApi } from "@shared/models/RemoteApi";
import { IsDeleted, IsDoNotSync, IsPinned, IsReadOnly } from "@shared/models/types";
import type { User } from "@shared/models/User";
import type { UserDelegation } from "@shared/models/UserDelegation";

export function getUserLastUpdate(userId: User["id"]): {
  lastUpdatedBy: ContactRow["lastUpdatedBy"];
  lastUpdatedByUserId: ContactRow["lastUpdatedByUserId"];
  updatedAt: number;
} {
  return {
    lastUpdatedBy: "user",
    lastUpdatedByUserId: userId,
    updatedAt: getCurEpochMs(),
  };
}

export function getUserConfResolutionLastUpdate(userDelegation?: UserDelegation) {
  return (
    "user-conf-res" + (userDelegation?.delegatedUserId ? `_${userDelegation.delegatedUserId}` : "")
  );
}

export function isUserUpdated(lastUpdatedBy: ContactRow["lastUpdatedBy"]) {
  return lastUpdatedBy.startsWith("user");
}

export function isForcedUpstream(lastUpdatedBy: ContactRow["lastUpdatedBy"]) {
  return lastUpdatedBy.startsWith("user-conf-res");
}

export function getEntityId({
  id,
  remoteApiId,
  isContactGroup,
}: {
  id: string;
  remoteApiId: RemoteApi["id"];
  isContactGroup: boolean;
}): Contact["id"] | ContactGroup["id"] {
  const uid = uuid(`${id}_${remoteApiId}`);
  if (isContactGroup) return `${uid}_group`;
  return uid;
}

export function isGroupEntityId(id: string) {
  return id.endsWith("_group");
}

export function pickDbRowFields(row: Partial<ContactRow>): ContactDbRowFields {
  const {
    isDeleted = row.isDeleted === IsDeleted.YES ? IsDeleted.YES : IsDeleted.NO,
    userId_isDeleted = "",
    isPinned = row.isPinned === IsPinned.YES ? IsPinned.YES : IsPinned.NO,
    userId_isPinned = "",
    lastUpdatedBy = "",
    updatedAt = 0,
    sourceUpdatedAt = 0,
    sourceRemoteApiId,
    isDoNotSync = row.isDoNotSync === IsDoNotSync.YES ? IsDoNotSync.YES : IsDoNotSync.NO,
    isReadOnly = row.isReadOnly === IsReadOnly.YES ? IsReadOnly.YES : IsReadOnly.NO,
    userId_isReadOnly = "",
    userId_isDoNotSync = "",
    lastUpdatedByUserId,
  } = row;
  return {
    userId_isDoNotSync,
    isDeleted,
    userId_isDeleted,
    isPinned,
    userId_isPinned,
    lastUpdatedBy,
    updatedAt,
    sourceUpdatedAt,
    sourceRemoteApiId,
    lastUpdatedByUserId,
    isDoNotSync,
    isReadOnly,
    userId_isReadOnly,
  };
}

export function pickContactFields(row: Partial<ContactRow> = {}) {
  const {
    userId,
    id,
    givenName,
    middleName,
    surname,
    suffix,
    prefix,
    nickname,
    birthday,
    companyName,
    departmentName,
    jobTitle,
    managerName,
    notes,
    pictureUrl,
    emails,
    relatives,
    dates,
    photos,
    imHandles,
    physicalAddresses,
    phoneNumbers,
    webPages,
    source,
  } = row;
  return {
    userId,
    id,
    givenName,
    middleName,
    surname,
    suffix,
    prefix,
    nickname,
    birthday,
    companyName,
    departmentName,
    jobTitle,
    managerName,
    notes,
    pictureUrl,
    emails,
    relatives,
    dates,
    photos,
    imHandles,
    physicalAddresses,
    phoneNumbers,
    webPages,
    source,
  };
}
