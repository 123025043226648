import { numAvg, roundToTwo } from "@packages/common/number";
import { objKeys } from "@packages/common/object";
import { getNumsFromString, isUrl } from "@packages/common/string";
import { ContactRow } from "@shared/models/Contact";
import { PendingContactRow } from "@shared/models/PendingContact";
import { Email, ImHandle, IsDefault, PhoneNumber, WebPage } from "@shared/models/types";
import { compareTwoStrings as strDiff } from "string-similarity";

import { SearchableContact } from "../hooks/useSearch";
import {
  Document,
  EnrichedDocumentSearchResultSetUnit,
  EnrichedDocumentSearchResultSetUnitResultUnit,
} from "../types/flexsearch";
import { getFullName } from "./contact";

const noOpTokenize = function (str: any) {
  return [str];
};

export type DedupeSearchableContact = SearchableContact & {
  _phones: PhoneNumber[];
  _contactKey: number;
};

function getContactPhoneList(contact: ContactRow) {
  return (
    contact.phoneNumbers?.map((phone) => {
      return {
        ...phone,
        value: getNumsFromString(phone.value),
      };
    }) || []
  );
}

export function createStrictContactIndex(
  contactList: ContactRow[],
  excludedIds: { [id: string]: true }
) {
  const list: SearchableContact[] = [];
  const idIndex: { [id: string]: number } = {};
  const searchIndex = new window.FlexSearch.Document<DedupeSearchableContact>({
    tokenize: "strict",
    encode: noOpTokenize,
    worker: true,
    document: {
      id: "id",
      store: ["_fullName", "givenName", "surname", "_contactKey", "_phones"],
      index: [
        { field: "_fullName", tokenize: "full" },
        { field: "emails[]:value" },
        { field: "_phones[]:value" },
        { field: "imHandles[]:value" },
        { field: "webPages[]:value" },
      ],
    },
  });

  contactList.map((contact, _contactKey) => {
    if (excludedIds.hasOwnProperty(contact.id)) return;
    const searchableContact = {
      ...contact,
      _fullName: getFullName(contact),
      _phones: getContactPhoneList(contact),
      _contactKey,
    } as DedupeSearchableContact;
    list.push(searchableContact);
    idIndex[contact.id] = list.length - 1;
    return searchIndex.addAsync(searchableContact);
  });

  return {
    searchIndex,
    list,
    idIndex,
  };
}

const EMAIL_MATCH_SCORE = 100;
const PHONE_MATCH_SCORE = 60;
const FULLNAME_MATCH_SCORE = 50;
const IMHANDLE_MATCH_SCORE = 25;
const WEBPAGE_MATCH_SCORE = 25;

export type ScoredResult = {
  score: number;
  contactKey: number;
  needles: { matched: any; score: number }[];
};
export type MergedScoredResult = {
  [id: string]: ScoredResult;
};
export type DupeGroup = { [contactId: string]: number | null };
export type SearchResult = EnrichedDocumentSearchResultSetUnitResultUnit<DedupeSearchableContact>;

export class ContactDedupe {
  public contact: DedupeSearchableContact;
  public strictSearchIndex: Document<DedupeSearchableContact>;
  public excludedIds: { [p: string]: true };
  private nameMultiplierCache: { [id: string]: number } = {};

  constructor(
    contact: ContactRow,
    contactKey: number,
    strictSearchIndex: Document<DedupeSearchableContact>,
    excludedIds?: { [id: string]: true }
  ) {
    this.contact = {
      ...contact,
      _fullName: getFullName(contact),
      _contactKey: contactKey,
      _phones: getContactPhoneList(contact),
    };
    this.strictSearchIndex = strictSearchIndex;
    this.excludedIds = { ...(excludedIds || {}), [this.contact.id]: true };
  }

  private async getEmailMatches(email: string) {
    return (await this.strictSearchIndex.searchAsync<true>(email, {
      enrich: true,
      index: ["emails[]:value"],
    })) as unknown as EnrichedDocumentSearchResultSetUnit<DedupeSearchableContact>[];
  }

  private async getPhoneMatches(phone: string) {
    return (await this.strictSearchIndex.searchAsync<true>(getNumsFromString(phone), {
      enrich: true,
      index: ["_phones[]:value"],
    })) as unknown as EnrichedDocumentSearchResultSetUnit<DedupeSearchableContact>[];
  }

  private async getImHandleMatches(handle: string) {
    return (await this.strictSearchIndex.searchAsync<true>(handle, {
      enrich: true,
      index: ["imHandles[]:value"],
    })) as unknown as EnrichedDocumentSearchResultSetUnit<DedupeSearchableContact>[];
  }

  private async getWebPageMatches(webPage: string) {
    return (await this.strictSearchIndex.searchAsync<true>(webPage, {
      enrich: true,
      index: ["webPages[]:value"],
    })) as unknown as EnrichedDocumentSearchResultSetUnit<DedupeSearchableContact>[];
  }

  /**
   * Avg name similarity score as multiplier to boost or reduce match score
   * @private
   * @param id
   * @param doc
   */
  private getNameMultiplier({ id, doc }: SearchResult) {
    if (!this.nameMultiplierCache[id]) {
      this.nameMultiplierCache[id] = numAvg([
        strDiff(this.contact.givenName?.toLowerCase() || "", doc.givenName?.toLowerCase() || ""),
        strDiff(this.contact.surname.toLowerCase() || "", doc.surname.toLowerCase() || ""),
      ]);
    }
    return this.nameMultiplierCache[id];
  }

  private async getFullNameMatches() {
    const scoredResult: { [id: string]: ScoredResult } = {};
    const searchResult = (await this.strictSearchIndex.searchAsync<true>(this.contact._fullName, {
      enrich: true,
      index: ["_fullName"],
    })) as unknown as EnrichedDocumentSearchResultSetUnit<DedupeSearchableContact>[];
    for (const r of searchResult) {
      for (const i of r.result) {
        if (this.excludedIds[i.id]) continue;
        const multiplier = this.getNameMultiplier(i);
        const score = roundToTwo(FULLNAME_MATCH_SCORE * multiplier);
        if (score <= 0) continue;
        const contactKey = i.doc._contactKey;
        scoredResult[i.id] =
          typeof scoredResult[i.id] !== "undefined"
            ? {
                score: scoredResult[i.id].score + score,
                contactKey,
                needles: scoredResult[i.id].needles.concat({
                  matched: this.contact._fullName,
                  score,
                }),
              }
            : { score, contactKey, needles: [{ matched: this.contact._fullName, score }] };
      }
    }
    return scoredResult;
  }

  private async multiMatchBase(
    list: (Email | PhoneNumber)[],
    getter: (
      item: Email | PhoneNumber
    ) => Promise<EnrichedDocumentSearchResultSetUnit<DedupeSearchableContact>[] | null>,
    score: number,
    getMultiplier?: (p: PhoneNumber | Email, result: SearchResult) => number
  ) {
    const scoredResult: { [id: string]: ScoredResult } = {};
    for (const item of list) {
      const results = await getter(item);
      if (!results) continue;
      for (const r of results) {
        for (const i of r.result) {
          if (this.excludedIds[i.id]) continue;
          const multiplier = getMultiplier
            ? getMultiplier(item, i) * this.getNameMultiplier(i)
            : this.getNameMultiplier(i);
          score = roundToTwo(score * multiplier);
          if (score <= 0) continue;
          const contactKey = i.doc._contactKey;
          scoredResult[i.id] =
            typeof scoredResult[i.id] !== "undefined"
              ? {
                  score: scoredResult[i.id].score + score,
                  contactKey,
                  needles: scoredResult[i.id].needles.concat({ matched: item, score }),
                }
              : { score, contactKey, needles: [{ matched: item, score }] };
        }
      }
    }
    return scoredResult;
  }

  private async getAllEmailMatches() {
    const getter = async (item: Email) => {
      return this.getEmailMatches(item.value);
    };
    return this.multiMatchBase(this.contact.emails || [], getter, EMAIL_MATCH_SCORE);
  }

  private async getAllPhoneMatches() {
    const getter = async (item: PhoneNumber) => {
      return this.getPhoneMatches(getNumsFromString(item.value));
    };
    const multiplier = (needle: PhoneNumber, matchedResult: SearchResult) => {
      const lowScoreTypes: PhoneNumber["type"][] = [
        "business",
        "businessFax",
        "school",
        "assistant",
        "work",
        "homeFax",
        "organizationMain",
      ];
      if (lowScoreTypes.includes(needle.type)) {
        return 0.1;
      } else {
        const matchedPhone = matchedResult.doc._phones.find(
          (phone) => phone.value === needle.value
        );
        if (matchedPhone) {
          if (needle.isDefault === IsDefault.YES || matchedPhone.isDefault === IsDefault.YES) {
            return 1.5;
          }
          if (needle.type === matchedPhone.type) {
            return 1;
          }
        }
      }
      return 0;
    };
    return this.multiMatchBase(
      this.contact.phoneNumbers || [],
      getter,
      PHONE_MATCH_SCORE,
      multiplier
    );
  }

  private async getAllImHandleMatches() {
    const getter = async (item: ImHandle) => {
      return this.getImHandleMatches(item.value);
    };
    return this.multiMatchBase(this.contact.imHandles || [], getter, IMHANDLE_MATCH_SCORE);
  }

  private async getAllWebPageMatches() {
    const getter = async (item: WebPage) => {
      if (isUrl(item.value)) return this.getWebPageMatches(item.value);
      return null;
    };
    return this.multiMatchBase(this.contact.webPages || [], getter, WEBPAGE_MATCH_SCORE);
  }

  private static mergeScoredResults(list: { [id: string]: ScoredResult }[]) {
    const scoredResult: MergedScoredResult = {};

    for (const result of list) {
      for (const id in result) {
        if (!scoredResult[id]) {
          scoredResult[id] = {
            score: result[id].score,
            contactKey: result[id].contactKey,
            needles: result[id].needles,
          };
        } else {
          scoredResult[id] = {
            contactKey: scoredResult[id].contactKey,
            score: scoredResult[id].score + result[id].score,
            needles: [...scoredResult[id].needles, ...result[id].needles],
          };
        }
      }
    }

    return scoredResult;
  }

  /**
   * score <60, >50 - similar contacts, but most likely not dupe
   * score > 60 - most likely dupe, this is a combination of a high match + high degree of similarity in name multiplier
   */
  public async getScoredMatches() {
    const emailMatches = await this.getAllEmailMatches();
    const phoneMatches = await this.getAllPhoneMatches();
    const fullNameMatches = await this.getFullNameMatches();
    const imHandleMatches = await this.getAllImHandleMatches();
    const webPageMatches = await this.getAllWebPageMatches();

    return ContactDedupe.mergeScoredResults([
      emailMatches,
      phoneMatches,
      fullNameMatches,
      imHandleMatches,
      webPageMatches,
    ]);
  }
}

export async function getDuplicatesFromContacts(contacts: ContactRow[] | undefined) {
  if (!contacts) return [];
  const { searchIndex } = createStrictContactIndex(contacts, {});

  const keyGroupsToMerge: DupeGroup[] = [];

  await Promise.all(
    (contacts || []).map(async (contact, contactKey) => {
      const dedupe = new ContactDedupe(contact, contactKey, searchIndex);
      const result = await dedupe.getScoredMatches();

      const highConfidenceKeys = objKeys(result).filter(
        (key) => result[key].score >= 60
      ) as string[];

      if (!highConfidenceKeys.length) return null;

      const contactIds = highConfidenceKeys.concat(contact.id);

      let groupIndex;
      for (const index in keyGroupsToMerge) {
        const group = keyGroupsToMerge[index];

        for (const key in group) {
          if (contactIds.includes(key)) {
            groupIndex = index;
          }
        }
      }

      if (groupIndex) {
        // a group has matched with at least one of the contact ids, merge them all
        for (const id of contactIds) {
          keyGroupsToMerge[Number(groupIndex)][id] =
            id === contact.id ? contactKey : result[id].contactKey;
        }
      } else {
        const matched: DupeGroup = {};
        for (const id of contactIds) {
          matched[id] = id === contact.id ? contactKey : result[id].contactKey;
        }
        keyGroupsToMerge.push(matched);
      }
    })
  );

  return keyGroupsToMerge;
}

export async function getMatchingPairs(
  haystack: ContactRow[] | undefined,
  needles: (ContactRow | PendingContactRow)[]
) {
  if (!haystack) return [];
  const { searchIndex } = createStrictContactIndex(haystack, {});

  const matchingPairs: { matched: ScoredResult; contact: ContactRow | PendingContactRow }[] = [];

  await Promise.all(
    needles.map(async (contact, contactKey) => {
      const dedupe = new ContactDedupe(contact, contactKey, searchIndex);
      const result = await dedupe.getScoredMatches();

      const [matchedKey] = objKeys(result)
        .filter((key) => result[key].score >= 60)
        .sort((a, b) => result[b].score - result[a].score) as string[];

      if (matchedKey) {
        matchingPairs.push({ matched: result[matchedKey], contact });
      }
    })
  );

  return matchingPairs;
}
