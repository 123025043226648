import { Switch } from "@headlessui/react";
import classNames from "clsx";
import { FC } from "react";

import useDarkMode from "../../hooks/useDarkMode";
import { MoonSolidIcon, SunSolidIcon } from "../core/Icon";

const DarkModeButton: FC = () => {
  const [isDarkMode, setIsDarkMode] = useDarkMode();

  return (
    <Switch
      checked={isDarkMode}
      onChange={setIsDarkMode}
      className={classNames(
        isDarkMode ? "bg-indigo-600" : "bg-zinc-200",
        "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      )}
    >
      <span className="sr-only">Dark mode</span>
      <span
        className={classNames(
          isDarkMode ? "translate-x-5" : "translate-x-0",
          "pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
        )}
      >
        <span
          className={classNames(
            isDarkMode ? "opacity-0 ease-out duration-100" : "opacity-100 ease-in duration-200",
            "absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
          )}
          aria-hidden="true"
        >
          <SunSolidIcon size="xs" className="w-3 h-3 text-zinc-400" />
        </span>
        <span
          className={classNames(
            isDarkMode ? "opacity-100 ease-in duration-200" : "opacity-0 ease-out duration-100",
            "absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
          )}
          aria-hidden="true"
        >
          <MoonSolidIcon size="xs" className="w-3 h-3 text-indigo-600" />
        </span>
      </span>
    </Switch>
  );
};

export default DarkModeButton;
