/* This example requires Tailwind CSS v2.0+ */
import { Dialog, Transition } from "@headlessui/react";
import { ExternalLinkIcon, XIcon } from "@heroicons/react/outline";
import Button, { ButtonVariant } from "@web/components/core/Button";
import Link from "next/link";
import { Dispatch, FC, Fragment, PropsWithChildren, SetStateAction } from "react";

const IcloudAppSpecificPassHelp: FC<{
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}> = ({ open, setOpen }) => {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col overflow-y-scroll bg-primary py-6 shadow-xl">
                    <div className="px-4 sm:px-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-lg font-medium text-primary">
                          iCloud app-specific password
                        </Dialog.Title>
                        <div className="flex h-7 items-center">
                          <button onClick={() => setOpen(false)}>
                            <span className="sr-only">Close</span>
                            <XIcon className="h-5 w-5 m-0" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="relative mt-6 flex-1 px-4 sm:px-6 text-primary">
                      <div className="flex mb-9">
                        <div className="flex-1">
                          <p className="flex items-center">
                            Visit&nbsp;
                            <Link href="https://appleid.apple.com">
                              <a
                                className="flex items-center text-indigo-600 underline"
                                target="_blank"
                              >
                                https://appleid.apple.com{" "}
                                <ExternalLinkIcon className="h-4 w-4 ml-2" />
                              </a>
                            </Link>
                          </p>
                          <p>You may have to authenticate if you have two-factor turned on.</p>
                          <img className="w-full block" alt="" src="/apple-id.png" />
                        </div>
                      </div>
                      <div className="flex mb-8">
                        <div className="flex-1">
                          <img className="w-full" alt="" src="/apple-specific-password.png" />
                          <p>
                            In Security, click on{" "}
                            <Link href="">
                              <a>App-Specific Passwords</a>
                            </Link>{" "}
                            and generate a password for TitleDock. This password will only be
                            accessible once, so be sure to copy it for the next step.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default IcloudAppSpecificPassHelp;
