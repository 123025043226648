import { ContactRow } from "@shared/models/Contact";
import { WebPage } from "@shared/models/types";
import {
  AppleIcon,
  FacebookIcon,
  FlickrIcon,
  IconType,
  InstagramIcon,
  LinkedinIcon,
  TikTokIcon,
  TwitterIcon,
  UserFriendsSolidIcon,
  WeiboIcon,
  WeixinIcon,
  YoutubeIcon,
} from "components/core/Icon";
import isEmpty from "lodash/isEmpty";

import { TypeSelectItem } from "./shared/TypeSelect";
import { SocialProfileType } from "./types";

const DEFAULT_TYPES: { [key: string]: string[] } = {
  emails: ["work", "home", "school", "other"],
  phoneNumbers: [
    "mobile",
    "home",
    "school",
    "business",
    "pager",
    "organizationMain",
    "assistant",
    "voice",
    "radio",
    "homeFax",
    "businessFax",
    "other",
  ],
  physicalAddresses: ["work", "home", "other"],
  imHandles: [
    "gtalk",
    "aim",
    "yahoo",
    "lync",
    "skype",
    "qq",
    "msn",
    "icc",
    "jabber",
    "wechat",
    "whatsapp",
    "facebook",
    "instagram",
    "linkedin",
    "twitter",
  ],
  webPages: ["work", "home", "school", "other"],
  dates: ["anniversary", "other"],
  relatives: [
    "mother",
    "father",
    "parent",
    "brother",
    "sister",
    "son",
    "daughter",
    "child",
    "friend",
    "spouse",
    "partner",
    "assistant",
    "manager",
    "other",
  ],
};

export function generateTypeSelectItems(
  type: keyof ContactRow,
  values: (string | null | undefined)[]
): TypeSelectItem[] {
  const defaultValues = DEFAULT_TYPES[type] || [];
  const existingValues: string[] = values.filter((value): value is string => !!type);
  return Array.from(new Set([...defaultValues, ...existingValues])).map((value) => ({
    id: value,
    value,
  }));
}

export const ALL_SOCIAL_PROFILE_TYPES: SocialProfileType[] = [
  SocialProfileType.Apple,
  SocialProfileType.Facebook,
  SocialProfileType.Flickr,
  SocialProfileType.Instagram,
  SocialProfileType.LinkedIn,
  SocialProfileType.Myspace,
  SocialProfileType.TikTok,
  SocialProfileType.Twitter,
  SocialProfileType.Wechat,
  SocialProfileType.Weibo,
  SocialProfileType.Youtube,
];

export const SOCIAL_PROFILE_ICONS: { [type: string]: IconType } = {
  [SocialProfileType.Apple]: AppleIcon,
  [SocialProfileType.Facebook]: FacebookIcon,
  [SocialProfileType.Flickr]: FlickrIcon,
  [SocialProfileType.Generic]: UserFriendsSolidIcon,
  [SocialProfileType.Instagram]: InstagramIcon,
  [SocialProfileType.LinkedIn]: LinkedinIcon,
  [SocialProfileType.Myspace]: UserFriendsSolidIcon,
  [SocialProfileType.TikTok]: TikTokIcon,
  [SocialProfileType.Twitter]: TwitterIcon,
  [SocialProfileType.Wechat]: WeixinIcon,
  [SocialProfileType.Weibo]: WeiboIcon,
  [SocialProfileType.Youtube]: YoutubeIcon,
};

export function getSocialProfile(webPage?: WebPage): SocialProfileType | null {
  if (isEmpty(webPage) || webPage?.type !== "social") {
    return null;
  }
  return (
    ALL_SOCIAL_PROFILE_TYPES.find(
      (type) => webPage?.value?.toLowerCase()?.includes(type) === true
    ) || SocialProfileType.Generic
  );
}
