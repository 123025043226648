import { get_M_d_yyyy } from "@packages/common/dateTime";
import { UserProfilePayload } from "@packages/services/src/http/get-me";
import { UserInvite } from "@shared/models/UserInvite";
import { SecondaryBadge, SuccessBadge } from "@web/components/core/Badge";
import classNames from "clsx";
import { FC } from "react";

export const CollaboratorUserTable: FC<{
  userDelegations: UserProfilePayload["userDelegations"];
  invites?: UserInvite[];
}> = ({ userDelegations, invites }) => {
  return (
    <table className="min-w-full border-separate" style={{ borderSpacing: 0 }}>
      <thead className="bg-gray-50 dark:bg-gray-600 bg-opacity-75 text-gray-900 dark:text-gray-300">
        <tr>
          <th
            scope="col"
            className="sticky top-0 z-10 border-b border-gray-300 py-3.5 pl-4 pr-3 text-left text-sm font-semibold  backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
          >
            Name
          </th>
          <th
            scope="col"
            className="sticky top-0 z-10 hidden border-b border-gray-300 px-3 py-3.5 text-left text-sm font-semibold backdrop-blur backdrop-filter lg:table-cell"
          >
            Email
          </th>
          <th
            scope="col"
            className="sticky top-0 z-10 border-b border-gray-300 px-3 py-3.5 text-left text-sm font-semibold backdrop-blur backdrop-filter"
          >
            Role
          </th>{" "}
          <th
            scope="col"
            className="sticky top-0 z-10 border-b border-gray-300 px-3 py-3.5 text-left text-sm font-semibold backdrop-blur backdrop-filter"
          >
            Expiration
          </th>
          <th
            scope="col"
            className="sticky top-0 z-10 border-b border-gray-300 py-3.5 pr-4 pl-3 backdrop-blur backdrop-filter sm:pr-6 lg:pr-8"
          >
            <span className="sr-only">Delete</span>
          </th>
        </tr>
      </thead>
      <tbody className="bg-primary text-gray-900 dark:text-gray-300">
        {invites &&
          invites.map((invite, i) => {
            const rowHighlightClassname =
              i !== userDelegations.length + (invites?.length || 0) - 1
                ? "border-b border-gray-200 dark:border-gray-600"
                : "";
            return (
              <tr key={invite.id}>
                <td
                  className={classNames(
                    rowHighlightClassname,
                    "whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium  sm:pl-6 lg:pl-8"
                  )}
                >
                  <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-gray-100 text-gray-800">
                    Invite sent
                  </span>
                </td>
                <td
                  className={classNames(
                    rowHighlightClassname,
                    "whitespace-nowrap px-3 py-4 text-sm text-gray-500 hidden lg:table-cell"
                  )}
                >
                  {invite.recipientEmail}
                </td>
                <td
                  className={classNames(
                    rowHighlightClassname,
                    "whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                  )}
                >
                  {invite.userDelegation?.delegatedRoles?.includes("read") && (
                    <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-gray-100 text-gray-800">
                      Can view
                    </span>
                  )}
                  {!invite.userDelegation?.delegatedRoles && (
                    <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-green-100 text-green-800">
                      Can edit
                    </span>
                  )}
                </td>
                <td
                  className={classNames(
                    rowHighlightClassname,
                    "whitespace-nowrap px-3 py-4 text-sm text-gray-500 hidden lg:table-cell"
                  )}
                >
                  {invite.userDelegation?.expiresAt ? (
                    <span className="inline-flex items-center px-2 py-0.5">
                      {get_M_d_yyyy(invite.userDelegation?.expiresAt)}
                    </span>
                  ) : (
                    <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-gray-100 text-gray-800">
                      No expiration
                    </span>
                  )}
                </td>
                <td
                  className={classNames(
                    rowHighlightClassname,
                    "relative whitespace-nowrap py-4 pr-4 pl-3 text-right text-sm font-medium sm:pr-6 lg:pr-8"
                  )}
                >
                  <a href="#" className="text-indigo-600 hover:text-indigo-900">
                    Delete<span className="sr-only">, {invite.recipientEmail}</span>
                  </a>
                </td>
              </tr>
            );
          })}
        {userDelegations.map((user, i) => {
          const rowHighlightClassname =
            i !== (invites?.length || 0) + userDelegations.length - 1
              ? "border-b border-gray-200 dark:border-gray-600"
              : "";
          return (
            <tr key={user.delegatedUserId}>
              <td
                className={classNames(
                  rowHighlightClassname,
                  "whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 lg:pl-8"
                )}
              >
                {user.profile?.displayName || `${user.profile?.givenName} ${user.profile?.surname}`}
              </td>
              <td
                className={classNames(
                  rowHighlightClassname,
                  "whitespace-nowrap px-3 py-4 text-sm text-gray-500 hidden lg:table-cell"
                )}
              >
                {user.profile?.email}
              </td>
              <td
                className={classNames(
                  rowHighlightClassname,
                  "whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                )}
              >
                {user.delegatedRoles?.includes("read") && <SecondaryBadge>Can view</SecondaryBadge>}
                {!user.delegatedRoles && <SuccessBadge>Can edit</SuccessBadge>}
              </td>
              <td
                className={classNames(
                  rowHighlightClassname,
                  "whitespace-nowrap px-3 py-4 text-sm text-gray-500 hidden lg:table-cell"
                )}
              >
                {user.expiresAt ? (
                  get_M_d_yyyy(user.expiresAt)
                ) : (
                  <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-gray-100 text-gray-800">
                    No expiration
                  </span>
                )}
              </td>
              <td
                className={classNames(
                  rowHighlightClassname,
                  "relative whitespace-nowrap py-4 pr-4 pl-3 text-right text-sm font-medium sm:pr-6 lg:pr-8"
                )}
              >
                <a href="#" className="text-indigo-600 hover:text-indigo-900">
                  Delete<span className="sr-only">, {user.profile?.givenName}</span>
                </a>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default CollaboratorUserTable;
