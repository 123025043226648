import { ContactGroupRowForDisplay } from "@shared/models/ContactGroup";
import classNames from "clsx";
import { CheckIcon, CircleSolidIcon } from "components/core/Icon";
import { stringToHslColor } from "helpers/color";
import { ContactsRoute } from "helpers/routes";
import useDarkMode from "hooks/useDarkMode";
import { selectSelectedGroups } from "integrations/contact/selectors";
import contactSlice from "integrations/contact/slice";
import { useAppDispatch } from "integrations/redux/store";
import { useRouter } from "next/router";
import { FC, useCallback } from "react";
import { useSelector } from "react-redux";

type SidebarGroupsSectionProps = {
  groups?: ContactGroupRowForDisplay[];
};

export const SidebarGroupsSection: FC<SidebarGroupsSectionProps> = ({ groups }) => {
  const [isDarkMode] = useDarkMode();
  const router = useRouter();
  const currentPathName = router?.pathname;

  const dispatch = useAppDispatch();
  const selectedGroups = useSelector(selectSelectedGroups);
  const selectedGroupIds = selectedGroups.map((o) => o.id);

  const onToggleGroupSelection = useCallback(
    (group: ContactGroupRowForDisplay) => {
      if (selectedGroupIds.includes(group.id)) {
        const groups = selectedGroups.filter((o) => o.id !== group.id);
        dispatch(contactSlice.actions.setSelectedGroups({ groups }));
      } else {
        const groups = [...selectedGroups, group];
        dispatch(contactSlice.actions.setSelectedGroups({ groups }));
      }

      // If we are not currently showing /contacts route, navigate to it
      if (ContactsRoute.isCurrent?.(currentPathName) !== true) {
        router.push("/contacts");
      }
    },
    [selectedGroupIds, currentPathName, selectedGroups, dispatch, router]
  );

  return (
    <div>
      {groups?.map((group, index) => {
        const isSelected = selectedGroupIds.includes(group.id);
        const hslColor = group.name
          ? stringToHslColor(group.name, isDarkMode ? 70 : 80, 40)
          : "rgb(209, 213, 219)";
        return (
          <div
            className="flex flex-row items-center px-4 rounded-md cursor-pointer hover:text-zinc-900 dark:hover:text-zinc-100 hover:bg-zinc-50 dark:hover:bg-zinc-800"
            key={index}
            onClick={() => onToggleGroupSelection(group)}
          >
            <CircleSolidIcon style={{ color: hslColor, height: "8px", width: "8px" }} />
            <span
              className={classNames(
                isSelected
                  ? "text-zinc-900 dark:text-zinc-100"
                  : "text-zinc-700 dark:text-zinc-500",
                "group flex items-center ml-5 py-1.5 text-sm font-medium rounded-md"
              )}
            >
              {group.name}
            </span>
            {isSelected && <CheckIcon className="ml-1.5 text-green-500" size="sm" />}
          </div>
        );
      })}
    </div>
  );
};
