export enum IsInProgress {
  NO,
  YES,
}

export enum IsToBeDeleted {
  NO,
  YES,
}
export enum IsQueued {
  NO,
  YES,
  FAILED,
  SUCCEEDED,
}

export enum IsObsolete {
  NO,
  YES,
}

export enum IsForced {
  NO,
  YES,
}

export enum IsContactInConflict {
  NO,
  YES,
}

export enum IsDefault {
  NO,
  YES,
}

export enum IsDeleted {
  NO,
  YES,
}
export enum IsNew {
  NO,
  YES,
}
export enum IsPurged {
  NO,
  YES,
}

export enum IsDisabled {
  NO,
  YES,
}

export enum IsDoNotSync {
  NO,
  YES,
}

export enum IsReadOnly {
  NO,
  YES,
}

export enum IsPinned {
  NO,
  YES,
}

export enum IsAutoFilling {
  NO,
  YES,
}

export enum EventType {
  SearchQuery = "search",
  OpenContact = "openContact",
  OpenGroup = "openGroup",
}

export type Email = {
  type?: "work" | "home" | "school" | "other" | string; // system facing enum
  label?: string; // user facing display
  value: string;
  isDefault?: IsDefault;
};

export type JobPosition = {
  companyName?: string;
  departmentName?: string;
  jobTitle?: string;
  managerName?: string;
  isDefault?: IsDefault;
};

export type ImHandle = {
  type?: "work" | "personal" | string;
  service?:
    | "gtalk"
    | "aim"
    | "yahoo"
    | "lync"
    | "skype"
    | "qq"
    | "msn"
    | "icc"
    | "jabber"
    | "wechat"
    | "whatsapp"
    | "facebook"
    | "instagram"
    | "linkedin"
    | "twitter"
    | string;
  value: string;
  isDefault?: IsDefault;
};

export type PhysicalAddress = {
  type?: string | "work" | "home";
  street?: string;
  line2?: string;
  city?: string;
  postalCode?: string;
  state?: string;
  country?: string;
  placeKey?: string;
  long?: number;
  lat?: number;
  isDefault?: IsDefault;
};

export type PhoneNumber = {
  type?:
    | "business"
    | "home"
    | "mobile"
    | "pager"
    | "businessFax"
    | "homeFax"
    | "organizationMain"
    | "assistant"
    | "voice"
    | "school"
    | "radio"
    | "other"
    | string;
  label?: string;
  value: string;
  isDefault?: IsDefault;
};

export type WebPage = {
  type?: "profile" | "blog" | "homepage" | "work" | "school" | string;
  label?: string;
  service?: string;
  value: string;
  isDefault?: IsDefault;
};

export type Relative = {
  label?: string;
  value: string;
  contactId?: string;
  isDefault?: IsDefault;
};

export type Photo = {
  crop?: string;
  type?: "uri" | "jpeg" | "gif" | "png" | string;
  encoding?: "b" | string;
  value: string;
  isDefault?: IsDefault;
};

export type ArbitraryDate = {
  label?: string;
  value: string;
  isDefault?: IsDefault;
};

export type CreatedAtDedupe = {
  [id: string]: number;
};

export type UserLastUpdatedBy = "user" | "user-conf-res";

export enum IsIntegrationEnabled {
  NO,
  YES,
}

export enum IsRemoteApiActive {
  NO,
  YES,
}

export enum IsForcedSync {
  NO,
  YES,
}

export enum IsPendingContact {
  NO,
  YES,
}

export type VendorTable = "IcloudContact" | "GoogleContact";

export enum UserIsActive {
  NO,
  YES,
}

export enum UserIsConfirmed {
  NO,
  YES,
}
