import { ParsedUrlQuery } from "querystring";

/**
 * Helper function to parse ParsedUrlQuery and transform it to emit a dictionary of strings only.
 *
 * @param query ParsedUrlQuery from router object
 * @returns NodeJS.Dict of strings
 */
export function parseQuery(query: ParsedUrlQuery): NodeJS.Dict<string> {
  const result: NodeJS.Dict<string> = {};
  Object.keys(query).forEach((key) => {
    const value = query[key];
    if (value) {
      result[key] = Array.isArray(value) ? value[0] : value;
    }
  });
  return result;
}
