import { Menu, Switch, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/solid";
import { useGoogleLogin } from "@react-oauth/google";
import type { RemoteApiForDisplay } from "@shared/models/RemoteApi";
import { IsRemoteApiActive } from "@shared/models/types";
import { SecondaryBadge, SuccessBadge, WarningBadge } from "@web/components/core/Badge";
import Button, { ButtonVariant } from "@web/components/core/Button";
import { SecondaryHoverColor } from "@web/components/core/colorVariant";
import Header from "@web/components/core/Header";
import { CloudPlusIcon, EditSolidIcon, EllipsisVIcon, TrashIcon } from "@web/components/core/Icon";
import { Monochrome as MonochromeVendorIcon } from "@web/components/core/VendorIcon";
import LoadingSpinner from "@web/components/loading/LoadingSpinner";
import DeleteIntegrationModal from "@web/components/tools/syncManagement/DeleteIntegrationModal";
import SyncICloudModal from "@web/components/tools/syncManagement/IcloudSignInModal";
import ZapierIntegrationModal from "@web/components/tools/syncManagement/ZapierIntegrationModal";
import {
  useCreateIntegrationFromGoogleOauthMutation,
  useGetUserQuery,
  useUpdateIntegrationMutation,
} from "@web/integrations/user/api";
import classNames from "clsx";
import Link from "next/link";
import { FC, Fragment, useCallback, useState } from "react";

const SyncManagement: FC<{ isNewSync?: boolean }> = ({ isNewSync = false }) => {
  const { data, isLoading } = useGetUserQuery();
  const [updateIntegration] = useUpdateIntegrationMutation();

  const [showNewSync, setShowNewSync] = useState(isNewSync);
  const [showIcloudSignin, setShowIcloudSignin] = useState(false);
  const [showZapierSetup, setShowZapierSetup] = useState(false);
  const [remoteApiToDelete, setRemoteApiToDelete] = useState<RemoteApiForDisplay>();

  const closeDeleteModal = useCallback(() => {
    setRemoteApiToDelete(undefined);
  }, []);

  const [createIntegrationFromGoogleOauth] = useCreateIntegrationFromGoogleOauthMutation();

  const syncWorkflows = [
    {
      name: "iCloud",
      description: "Contacts",
      iconUrl: "/icloud-logo-icon.svg",
      handler: () => setShowIcloudSignin(true),
    },
    {
      name: "Google",
      description: "Contacts, Gmail metadata",
      iconUrl: "/google-logo-icon.svg",
      handler: useGoogleLogin({
        flow: "auth-code",
        scope:
          "https://www.googleapis.com/auth/contacts https://www.googleapis.com/auth/contacts.other.readonly https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/calendar.settings.readonly",
        onSuccess: (tokenResponse) => {
          createIntegrationFromGoogleOauth(tokenResponse.code);
        },
      }),
    },
    {
      name: "Zapier",
      description: "Connect with 4000+ apps",
      iconUrl: "/zapier-logo-icon.svg",
    },
  ];

  return (
    <div className="container mx-auto mt-8">
      {/* Header */}
      <Header title="Sync Management" className="p-container">
        <Button
          variant={!showNewSync ? ButtonVariant.Primary : ButtonVariant.Secondary}
          hotkey={["Alt", "KeyN"]}
          icon={!showNewSync ? <CloudPlusIcon size="lg" /> : <XIcon className="w-5 h-5" />}
          onClick={() => setShowNewSync((prev) => !prev)}
        >
          {!showNewSync ? "Sync" : "Close"}
        </Button>
      </Header>
      <Transition
        show={showNewSync}
        as={Fragment}
        leave="transform transition ease duration-200 sm:duration-300"
        leaveTo="-translate-x-full"
        leaveFrom="translate-x-0"
        enter="transform transition ease duration-200 sm:duration-300"
        enterTo="translate-x-0"
        enterFrom="-translate-x-full"
      >
        <div className="grid gap-5 pb-6 px-6 grid-cols-[repeat(auto-fill,minmax(175px,1fr))]">
          {syncWorkflows.map((workflow) => {
            return (
              <button
                key={workflow.name}
                className="px-5 py-6 bg-primary border border-indigo-700 rounded flex flex-col items-center text-sm text-primary hover:shadow-lg"
                onClick={workflow.handler}
              >
                <img className="mb-3 h-10 w-10" alt="" src={workflow.iconUrl} />
                <span className="flex items-center text-sm"> {workflow.name}</span>
                <span className="flex items-center text-xs"> {workflow.description}</span>
              </button>
            );
          })}
        </div>
      </Transition>
      {/* Divider */}

      <div className="divider" />
      <table className="min-w-full border-separate" style={{ borderSpacing: 0 }}>
        <thead className="bg-gray-50 dark:bg-gray-600 bg-opacity-75 text-gray-900 dark:text-gray-300">
          <tr>
            <th
              scope="col"
              className="sticky top-0 z-10 border-b border-gray-300 py-3.5 pl-4 pr-3 text-left text-sm font-semibold  backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
            >
              Account
            </th>
            <th
              scope="col"
              className="sticky top-0 z-10 border-b border-gray-300 px-3 py-3.5 text-left text-sm font-semibold backdrop-blur backdrop-filter lg:table-cell"
            >
              On / Off
            </th>
            <th
              scope="col"
              className="sticky top-0 z-10 border-b border-gray-300 px-3 py-3.5 text-left text-sm font-semibold backdrop-blur backdrop-filter"
            >
              Status
            </th>
            <th
              scope="col"
              className="sticky top-0 z-10 border-b border-gray-300 px-3 py-3.5 text-left text-sm font-semibold backdrop-blur backdrop-filter"
            >
              Last Synced
            </th>
            <th
              scope="col"
              className="sticky top-0 z-10 border-b border-gray-300 py-3.5 pr-4 pl-3 backdrop-blur backdrop-filter sm:pr-6 lg:pr-8"
            >
              <span className="sr-only">Options</span>
            </th>
          </tr>
        </thead>
        <tbody className="bg-primary text-gray-900 dark:text-gray-300">
          {data?.integrations &&
            data.integrations.map((integration, i) => {
              const rowHighlightClassname =
                i !== data.integrations.length - 1
                  ? "border-b border-gray-200 dark:border-gray-600"
                  : "";

              const onToggleIsActive = (isChecked: boolean) => {
                const isActive = isChecked ? IsRemoteApiActive.YES : IsRemoteApiActive.NO;
                updateIntegration({ id: integration.id, isActive });
              };

              const recurringTasks = data.recurringTasks?.filter((task) => {
                return task.triggerData?.remoteApiId === integration.id;
              });

              const [recurringTaskLastRun] = recurringTasks
                .map(({ lastSuccessRunAt }) => lastSuccessRunAt)
                .sort()
                .reverse();

              const initialDownload = Boolean(!recurringTaskLastRun);
              const isOnBoarded = Boolean(integration.userOnboardedAt);
              const isOperational = isOnBoarded && integration.isActive === IsRemoteApiActive.YES;
              const isPaused = integration.isActive === IsRemoteApiActive.NO;

              const editHref = isOnBoarded
                ? `/tools/sync/edit/${integration.id}`
                : `/tools/sync/onboard/${integration.id}`;
              const menuItems = [
                {
                  label: "Edit",
                  Icon: EditSolidIcon,
                  href: editHref,
                },
                {
                  label: "Delete",
                  Icon: TrashIcon,
                  onClick: () => {
                    setRemoteApiToDelete(integration);
                  },
                },
              ];

              return (
                <tr key={integration.id} className={classNames(SecondaryHoverColor)}>
                  <Link href={editHref} passHref>
                    <td
                      className={classNames(
                        rowHighlightClassname,
                        "whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 lg:pl-8 hover:underline hover:cursor-pointer"
                      )}
                    >
                      <span className="inline-flex items-center">
                        <MonochromeVendorIcon integration={integration} className="pr-2" />
                        {integration.auth.username || integration.auth.apiKey}
                      </span>
                    </td>
                  </Link>

                  <td
                    className={classNames(
                      rowHighlightClassname,
                      "whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell"
                    )}
                  >
                    <Switch
                      checked={integration.isActive === IsRemoteApiActive.YES}
                      onChange={onToggleIsActive}
                      className={`${
                        integration.isActive === IsRemoteApiActive.YES
                          ? "bg-purple-700"
                          : "bg-gray-200"
                      } relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
                    >
                      <span
                        className={`${
                          integration.isActive === IsRemoteApiActive.YES
                            ? "translate-x-6"
                            : "translate-x-1"
                        } inline-block h-4 w-4 transform rounded-full bg-white transition-transform`}
                      />
                    </Switch>
                  </td>
                  <td
                    className={classNames(
                      rowHighlightClassname,
                      "whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                    )}
                  >
                    {initialDownload ? (
                      <SecondaryBadge>Initial Download</SecondaryBadge>
                    ) : isPaused ? (
                      <SecondaryBadge>Paused</SecondaryBadge>
                    ) : isOperational ? (
                      <SuccessBadge>Operational</SuccessBadge>
                    ) : (
                      !isOnBoarded && <WarningBadge>Onboarding</WarningBadge>
                    )}
                  </td>
                  <td
                    className={classNames(
                      rowHighlightClassname,
                      "relative whitespace-nowrap py-4 pr-4 pl-3 text-sm font-medium sm:pr-6 lg:pr-8"
                    )}
                  >
                    {recurringTaskLastRun && new Date(recurringTaskLastRun).toLocaleString()}
                  </td>
                  <td
                    className={classNames(
                      rowHighlightClassname,
                      "relative whitespace-nowrap py-4 pr-4 pl-3 text-right text-sm font-medium sm:pr-6 lg:pr-8"
                    )}
                  >
                    <Menu as="div" className="relative inline-block text-left">
                      <div>
                        <Menu.Button className="inline-flex w-full justify-center rounded-full bg-primary bg-opacity-20 text-sm font-medium text-primary focus:outline-none focus-visible:ring-2 focus-visible:ring-purple-700 focus-visible:ring-opacity-75">
                          <EllipsisVIcon aria-hidden="true" className="h-5 w-5" size="lg" />
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="z-50 absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-primary shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:border">
                          {menuItems.map(({ label, Icon, onClick, href }) => {
                            const Item = (
                              <div className="px-1 py-1" key={label}>
                                <Menu.Item>
                                  <Button
                                    variant={ButtonVariant.Secondary}
                                    className="border-0 shadow-none w-full items-start justify-start"
                                    onClick={onClick}
                                  >
                                    <Icon
                                      className="mr-2 h-5 w-5 text-violet-400"
                                      aria-hidden="true"
                                    />
                                    {label}
                                  </Button>
                                </Menu.Item>
                              </div>
                            );
                            return href ? (
                              <Link href={href} passHref key={label}>
                                {Item}
                              </Link>
                            ) : (
                              Item
                            );
                          })}
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
      {isLoading && <LoadingSpinner />}
      <SyncICloudModal isModalOpen={showIcloudSignin} setIsClosed={setShowIcloudSignin} />
      <ZapierIntegrationModal isModalOpen={showZapierSetup} setIsClosed={setShowZapierSetup} />
      <DeleteIntegrationModal
        isOpen={!!remoteApiToDelete}
        onClose={closeDeleteModal}
        remoteApi={remoteApiToDelete}
      />
    </div>
  );
};

export default SyncManagement;
