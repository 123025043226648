import parsePhoneNumber from "libphonenumber-js/min";

const phoneNumberCache: { [phoneNumberString: string]: string } = {};

export function formatPhoneNumber(val: string = "") {
  const phoneNumberString = String(val);
  if (phoneNumberCache[phoneNumberString]) {
    return phoneNumberCache[phoneNumberString];
  }

  const parsedPhoneNumber = parsePhoneNumber(phoneNumberString, "US");
  if (parsedPhoneNumber?.country === "US") {
    const usResult = parsedPhoneNumber.formatNational();
    phoneNumberCache[phoneNumberString] = usResult;
    return usResult;
  }

  const internationalResult = parsedPhoneNumber?.formatInternational() || phoneNumberString;
  phoneNumberCache[phoneNumberString] = internationalResult;
  return internationalResult;
}
